@import "../../../styles/variables.scss";

#attendance-summary {
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
  font-weight: bold;
  font-size: 12px;
  margin: 20px 0;
  padding: 10px 0;
  overflow: visible;
  button {
    color: black;
    font-size: 22px;
    font-weight: bold;
  }

  li {
    display: flex;
    position: relative;
    align-items: center;
    cursor: pointer;

    strong {
      margin-left: 4px;
      font-size: 14px;
      font-weight: normal;
      text-transform: none;
    }
  }

  &.hoverable {
    li {
      strong {
        min-width: 55px;
        left: -22.5px;
        position: absolute;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.42);
        border-radius: 20px;
        text-align: center;
        display: block;
        opacity: 0;
        transition: 0.3s;
        margin-left: 0;
        top: -10px;
        padding: 5px 10px;
        background-color: white;
      }

      &:first-child strong {
        left: -10px;
      }

      &:hover {
        strong {
          opacity: 1;
          top: -22px;
        }
      }
    }
  }

  .icon {
    width: 30px;
    height: 30px;
    border-radius: 25px;
    margin-right: 5px;
    background-size: 15px;
    background-position: center;
    background-color: #cbcbcb;

    &.invited {
      background-image: url("../../../img/invitation.svg");
    }

    &.accept,
    &.attended {
      background-color: $green;
      background-image: url("../../../img/rsvp-new.svg");
    }

    &.attended {
      background-size: 20px;
      background-image: url("../../../img/i-check-black.svg");
    }

    &.tentative {
      background-image: url("../../../img/rsvp-tentative-new.svg");
    }

    &.decline {
      background-color: $red;
      background-image: url("../../../img/rsvp-decline-new.svg");
    }
  }
}
