@use "sass:math";
@import "variables";

.icon.loading {
  background-image: url("../img/loading.svg");
  width: 25px;
  height: 25px;
  display: block;
}

@keyframes rotateLoading {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes flipInY {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }

  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.loadingWrapper {
  &.fullPage {
    animation: flipInY 0.75s;
  }

  strong {
    padding-left: 10px;
    padding-bottom: 5px;
  }

  &.inline {
    padding-left: 0;
  }

  .ball-clip-rotate > div {
    animation: rotateLoading 0.6s infinite linear !important;
  }
}
