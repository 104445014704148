@import "variables.scss";

.page.entityList {
  .learningPathLimit {
    width: 370px;
    position: absolute;
    right: 40px;
    top: -10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
    padding: 10px;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 12px;
    opacity: 0;
    transition: 0.2s;
    transform: scale(0.15);
    transform-origin: right;
    z-index: 30;
    span {
      font-weight: normal;
      font-size: 12px;
    }
  }

  .createButtonContainer {
    &:hover {
      .learningPathLimit {
        opacity: 1;
        right: 55px;
        transform: scale(1);
      }
    }
  }
}

.page.create {
  margin: 0 auto;

  .flexRow {
    align-items: flex-start;
    justify-content: center;
  }

  &.badge,
  &.learningPath {
    .subGroupSelect {
      width: 100%;
      margin-bottom: 0 !important;
      .MuiAutocomplete-input {
        min-width: 120px;
      }
      .MuiInputBase-root {
        padding-right: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .MuiChip-root {
        width: 400px;
        max-width: 1000px;
      }
      svg {
        color: rgba(0, 0, 0, 0.96);
      }
    }
  }
}

.approver-instructions-quill {
  .ql-editor {
    min-height: 100px !important;
    max-height: 120px !important;
  }
}

.column {
  padding-top: 85px;
}

.column.left {
  position: sticky;
  top: -780px;
  max-width: 300px;

  .count {
    position: relative;
    top: -5px;
    margin-left: 5px;
  }

  .pace {
    position: relative;
    top: -6px;
    opacity: 0.7;
  }

  .MuiTextField-root {
    width: 100%;
  }
}

.column.right {
  margin-left: 35px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.column.right.groupFields {
  padding: 20px 0 0 !important;
  margin-left: 0 !important;

  .MuiTextField-root {
    width: 100% !important;
  }
}

.uploadImageWrapper {
  cursor: pointer;
  position: relative;
  transition: opacity 0.4s;
  opacity: 1;
  margin: 35px 0 0;

  &.disabled {
    pointer-events: none;

    .fileUpload {
      opacity: 0.2;
    }
  }

  strong {
    font-size: 14px;
    margin-top: 15px;
    display: block;
  }

  .helperText {
    font-style: italic;
    font-size: 14px;
  }

  @media (max-width: $screen-sm-max) {
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .uploadedImage {
    height: 300px;
    border-radius: 12px;
    &.circle {
      border-radius: 100%;
      height: 300px;
    }

    &.rectangle {
      height: 150px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    overflow: hidden;
    width: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
    background-color: white;

    img {
      display: block;
      max-height: 100%;
      height: 300px;
    }
  }

  .border {
    &.circle {
      width: 306px;
      height: 306px;
      position: absolute;
      left: -3px;
      top: -3px;
      background-image: url("../img/upload-circle.svg");
      background-size: 100%;
    }

    &.square {
      width: 306px;
      height: 306px;
      position: absolute;
      left: -3px;
      top: -3px;
      background-image: url("../img/upload-square.svg");
      background-size: 100%;
    }

    &.rectangle {
      width: 350px;
      height: 150px;
      position: absolute;
      background-repeat: no-repeat;
      background-image: url("../img/upload-rectangle.svg");
      background-size: contain;
    }
  }

  .tooltip {
    .button {
      margin-top: 0px;
    }

    text-align: center;
    font-weight: 600;
    margin-top: 15px;
    color: #686868;
    font-size: 12px;
    line-height: 14px;
  }

  .fileUpload {
    width: 300px;
    height: 300px;
    position: relative;
    transform: scale(1);
    transition: 0.4s;

    &.isRejected {
      animation-timing-function: ease-in-out;
      animation: headShake forwards 1.5s;
    }

    &.isDragging {
      transform: scale(1.05);

      .border.circle:not(.badge) {
        animation: rotate 22s linear infinite;
      }
    }

    &.isUploading {
      transform: scale(1.05);

      .border {
        animation: rotate 8s linear infinite;
      }
    }

    .tooltip {
      width: 300px;
      text-align: center;
      font-size: 18px;
      line-height: 20px;
      position: absolute;
      bottom: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      flex-direction: column;

      span {
        font-weight: 400;
        color: #686868;
        font-size: 12px;
        line-height: 12px;
        display: inline-block;
        margin-top: 10px;
      }
    }

    input {
      cursor: pointer;
      z-index: 5;
      width: 300px;
      height: 300px;
      opacity: 0;
      position: absolute;
    }
  }
}

.hamburger {
  width: 15px;
  height: 15px;
  padding: 0px;
  opacity: 0.35;
  background-size: contain;
  margin-right: 15px;
  background-image: url("../img/hamburger.svg");
}

.courseList {
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    display: flex;
    margin-left: -5px;
    line-height: 18px;
    padding: 8px 10px 8px 10px;
    border-radius: 6px;
    align-items: center;

    &.lastCourse {
      background-color: rgba(242, 98, 86, 0.4);
    }

    &.column {
      align-items: flex-start;
      flex-direction: column;
    }
    .flexRow {
      align-items: center !important;
      flex: 1;
    }
    .MuiFormControl-root {
      max-width: 50px;
      width: 50px !important;
      margin: 0 6px !important;
      input {
        font-size: 16px;
        font-weight: bold;
        padding: 10px;
      }
    }
    span {
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }

    .MuiChip-deleteIcon {
      margin-right: -5px;
      cursor: pointer;

      &:hover {
        color: rgba(0, 0, 0, 0.4);
      }
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    .name {
      flex: 1;
    }

    .icon.indicator {
      background-size: 20px;
      transform: scale(1);
      transition: 0.3s;
      padding: 20px;
      cursor: pointer;

      &:hover {
        transform: scale(1.5);
      }
    }
  }

  .category-icon {
    flex-shrink: 0;
    margin-right: 10px;
    width: 25px;
    height: 25px;
    border: 3px solid white;
  }
}

.page.create.quickLinks {
  padding-top: 150px;

  .flexRow {
    max-width: 1200px;
    margin: 0 auto;

    .column {
      max-width: none;
      flex: 1;
      position: relative;
      padding: 0;
      top: auto;
    }
  }

  .column.left {
    max-width: 410px;
  }

  .column.right {
    padding-top: 0px;
  }

  .switch {
    position: absolute;
    right: 0;
    cursor: pointer;
    align-items: center;
    margin: 0;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    flex: 1;
    justify-content: flex-end;
  }
}

.quill {
  .ql-editor {
    font-size: 16px;
    font-family: "Nunito";
    min-height: 210px;
    border-radius: 8px;
    background-color: white;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }

  .ql-container.ql-snow {
    border: none;
  }

  .ql-toolbar.ql-snow {
    border: none;
  }
}

.page.create.announcement,
.page.create.goal {
  .subGroupSelect {
    &.populated {
      .MuiAutocomplete-input {
        color: rgba(0, 0, 0, 0);
      }
    }

    label {
      transform: translate(0, 1.5px) scale(0.75);
      transform-origin: top left;
    }

    .MuiTextField-root {
      margin-top: 20px;
    }
  }
}

.page.create.announcement {
  .column.left {
    position: sticky;
    top: 0px;
  }
  .uploadedImage {
    background-size: contain !important;
  }
  .MuiFormControl-root {
    label {
      padding: 0 5px;
    }
  }

  .column.right {
    padding-top: 115px;
    margin-left: 35px;
    flex: 0;

    .MuiAutocomplete-root {
      margin-bottom: -40px;
    }

    .MuiFormControl-root {
      margin-bottom: 35px;
    }

    .MuiInput-multiline {
      width: 450px;
    }

    .MuiTextField-root {
      width: 450px;
    }
  }
}

.page.create.badge,
.page.create.learningPath {
  .mm-only-disclaimer {
    display: none;
  }
}

.page.create.mandatedTraining {
  .inputSelect.source {
    display: none;
  }
}

.page.create.learningPath {
  .column.left {
    position: relative;
    top: 0;
    width: 40%;
    max-width: 40%;

    iframe {
      margin-bottom: 20px;
      border: none;
      width: 100%;
      height: 285px;
      border-radius: 12px;
    }

    .MuiFormHelperText-root.custom {
      margin-top: -35px;
      margin-bottom: 20px;
    }
  }

  .column.right {
    margin-left: 75px;
  }

  .uploadImageWrapper {
    width: 100%;
    .rectangle {
      background-image: none;
      width: 100%;
      height: 175px;
      top: 0px;
      border-radius: 12px;
      height: 100%;
    }
    .tooltip {
      width: 100%;
    }
  }

  .uploadedImage {
    width: 350px;

    img {
      height: auto;
    }
  }

  .fileUpload {
    overflow: hidden;
    margin-top: -25px;
    height: 180px;
    width: 99%;
    background: white;
    transition: 0.3s;
    border-radius: 12px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    img {
      position: absolute;
      left: 0;
      right: 0;
      width: 350px;
      max-height: none;
    }

    input {
      width: 100%;
      height: 150px;
    }
  }

  .checkbox {
    margin-bottom: 20px;
    display: block;
  }

  .MuiFormControl-root {
    margin-bottom: 20px;
    width: 100%;
  }
}

#course-select {
  display: flex;
  align-items: center;

  .category-icon {
    margin-left: 5px;
  }
}

.category-icon {
  margin: 3px 10px 3px -5px;
  width: 25px !important;
  height: 25px !important;
}

.createCourseForm {
  padding-top: 50px;
  width: 650px;
  position: relative;
  &.disabled {
    .panel {
      padding-left: 0;
      padding-right: 0;
      background-color: rgba(0, 0, 0, 0);
      box-shadow: none;

      .MuiChip-deleteIcon {
        display: none;
      }

      pointer-events: none !important;

      footer {
        display: none;
      }
    }

    .MuiFormControl-root {
      pointer-events: none;
      margin-bottom: 20px;

      .MuiFormHelperText-root {
        display: none !important;
      }

      .MuiInput-underline:before {
        display: none;
      }

      .MuiSelect-icon {
        display: none;
      }
    }
  }

  h3 {
    margin-bottom: 10px;
  }

  iframe {
    margin-bottom: 25px;
  }

  .panel {
    padding: 20px;
    background-color: white;
    border-radius: 6px;
    box-shadow: 1px 4px 5px 0 rgba(0, 0, 0, 0.15);
    margin-bottom: 20px;

    .MuiInput-underline:before {
      opacity: 0.3;
    }

    &.createQuiz {
      width: 800px;

      header {
        font-size: 12px;
        opacity: 0.5;
        text-transform: uppercase;
      }

      .MuiTextField-root input {
        font-size: 18px;
        font-weight: bold;
      }

      .questionAnswers {
        animation: fadeIn 0.2s forwards;
      }

      .flexRow.questionOptions {
        margin-right: 10px;

        button {
          margin-bottom: 0;
          margin-top: -10px;
        }
      }

      footer.flexRow {
        margin: 0 -20px -30px;
        justify-content: center;
        align-items: center;

        button {
          flex: 1;
          padding: 20px;
          border-radius: 0px !important;

          &:focus {
            background: rgba(0, 0, 0, 0);
          }
        }
      }

      ol {
        list-style-type: none;
        padding: 0;
        margin: 0;
        position: relative;

        > li {
          padding: 20px;
          border-top: 5px solid darken($light-grey, 5%);
          width: 780px;
          margin-left: -10px;
          background-color: white;
          z-index: 3;
          margin-bottom: 10px;
          position: relative;
          border-radius: 14px;
          border-bottom: 5px solid darken($light-grey, 5%);
        }
      }

      .flexRow {
        justify-content: space-between;
        margin-right: 50px;
      }

      .close {
        margin-left: 10px;
      }

      ul {
        padding-bottom: 20px;

        li {
          margin-bottom: 8px;
          display: flex;

          input {
            font-size: 16px !important;
            font-weight: normal !important;
          }

          .label {
            flex: 1;
            margin-right: 10px;
          }

          .MuiFormControlLabel-root {
            margin-right: 0;
          }
        }
      }
    }

    .MuiFormGroup-root {
      flex-direction: row;
      align-items: center;
      margin-top: 10px;
    }

    h3 {
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .MuiChip-deleteIcon {
      background-size: 20px;
      transition: 0.3s;
      margin-right: 0;
      padding: 5px;
      margin-left: 10px;
      color: rgba(0, 0, 0, 1);
      cursor: pointer;

      &:hover {
        color: rgba(0, 0, 0, 0.4);
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .MuiFormControl-root {
          margin-bottom: 0px;
          flex: 1;
          margin-right: 35px;
        }
      }
    }
  }
}

.relatedCourses,
.subGroupSelect {
  .MuiInputBase-root {
    padding-right: 0 !important;
  }

  &.locked {
    pointer-events: none;

    svg {
      display: none;
    }

    .MuiInputBase-root {
      &:after,
      &:before {
        display: none;
      }

      input {
        display: none;
      }
    }
  }

  .MuiAutocomplete-tag {
    margin: 0;
    background-color: rgba(0, 0, 0, 0);
    text-align: left;
    margin-bottom: 5px;
    width: 100%;
    max-width: 100%;
    span {
      width: 215px;
      padding: 0 10px 0 0;
      flex: 1;
    }
  }

  .MuiAutocomplete-endAdornment {
    display: none;
  }
}

.MuiListItem-root {
  .categoryImage {
    margin-left: -5px;
  }
}

.checkbox-archived {
  margin-top: 5px !important;
  margin-bottom: -15px;
}

.categoryImage {
  width: 25px;
  height: 25px;
  display: block;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  border-radius: 100%;
  margin: 0 10px 0 10px;
  position: relative;
  top: -2px;
}

.sidebarCreateCourse {
  padding-top: 60px !important;
  width: 250px !important;

  #category-autocomplete {
    .category-icon-wrapper {
      position: absolute;
      width: 20px !important;
      height: 20px !important;
      top: -2px !important;

      .category-icon {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }

  .createdBy.flexRow {
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    margin-top: 10px;
  }

  h3 {
    svg {
      position: absolute;
      right: 10px;
      margin-top: -10px;
    }
  }

  .icon {
    margin-right: 8px;
    width: 25px;
    height: 25px;

    &.color {
      background-image: url("../img/community-share.svg");
    }

    &.mm-logo {
      background-image: url("../img/logo.png");
      width: 100%;
      height: 35px;
      margin-top: -5px;
      background-position: left center;
    }
  }

  &.disabled {
    pointer-events: none;

    .MuiFormControl-root {
      pointer-events: none;

      .MuiInput-underline:before {
        display: none;
      }

      .MuiSelect-icon {
        display: none;
      }
    }
  }

  h3 {
    font-size: 12px;
    margin-top: 35px;
  }

  .MuiFormControlLabel-root.checkbox {
    margin-top: 20px;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiSelect-selectMenu,
  .MuiListItem-root {
    display: flex;
    align-items: center;
  }

  .flexRow {
    margin-top: -10px;
  }

  .icon.time {
    position: relative;
    top: 15px;
    width: 30px;
    height: 30px;
  }

  .estimatedTime .MuiInputBase-root {
    width: 50px;
    margin-left: 10px;
  }

  .estimatedTime .MuiInputLabel-shrink {
    transform: translate(70px, 25px);
  }
}

.visibility-list {
  margin: 0 0 20px 0;
  padding: 0;
  list-style-type: none;

  li {
    margin: 0 0 5px;
    font-weight: bold;
    font-size: 14px;
    padding: 0;
    display: flex;
    align-items: center;

    .label {
      flex: 1;
    }

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      border: 2px solid white;
      box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
      border-radius: 100%;
      overflow: hidden;
      margin-left: 0;
      flex-shrink: 0;

      &.reviewer-group {
        background-image: url("../img/reviewer-group.svg");
        box-shadow: none;
        border-radius: 0;
        border-color: transparent;
      }

      &.group {
        background-image: url("../img/school.svg");
      }
    }
  }
}

.modal.deleteCategory {
  footer.flexRow {
    padding: 0 20px;

    &.multiple {
      justify-content: space-between;
    }

    button {
      margin: 0;
    }
  }
}

.page.create.goal {
  max-width: 950px;
  padding-top: 75px;

  .checkbox {
    margin-top: 15px;
  }

  .helperText {
    font-size: 1rem;
    font-size: 12px;
    margin-top: 0;
    color: rgba(0, 0, 0, 0.54);
  }

  .column.right {
    .MuiFormControl-root {
      margin-bottom: 15px;
    }

    .MuiAutocomplete-inputRoot {
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
    }

    .MuiChip-label {
      font-size: 16px;
    }

    .MuiAutocomplete-input {
      width: 100%;
    }

    .MuiAutocomplete-tag {
      width: 100%;
      max-width: 100%;
    }
  }

  .column.left {
    max-width: 495px;
  }
}

.goalCard {
  background-color: white;
  border-radius: 6px;
  box-shadow: 1px 4px 5px 0 rgba(0, 0, 0, 0.4);
  padding: 20px;
  width: 450px;

  h3 {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .MuiAutocomplete-root {
    flex: 1;
    width: 390px;
  }

  footer {
    margin-left: -20px;
    margin-bottom: -20px;
    margin-top: 20px;
    padding: 20px;
    width: 450px;
    border-top: 4px solid $light-grey;
    align-items: center;
    border-radius: 0 0 6px 6px;
  }

  .learningPathSelect {
    flex: 1;
  }

  .MuiTextField-root {
    margin-top: 0;
    margin-bottom: 10px;
  }

  header {
    input {
      font-size: 24px;
      font-weight: bold;
    }
  }

  .icon.goal {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }
}

.downloadLink {
  display: block;
  color: black;
  font-weight: bold;

  .button {
    border-radius: 50px;
    background-color: #dbdbdb !important;
    padding: 8px 20px;
    margin-right: 15px;
    margin-bottom: 15px;
    color: black;
    font-weight: bold;
    text-transform: none;

    .icon {
      background-image: url("../img/downloading.svg");
      width: 20px;
      height: 20px;
    }

    span {
      color: black;
      font-weight: bold;
      text-transform: none;
    }
  }
}

.studioButtonContainer {
  .button.primary {
    border-radius: 100px;
    padding: 8px 20px;
    color: black;
    background-color: #24dc91;
    font-weight: bold;
    text-transform: none;

    .icon {
      background-image: url("../img/director-chair.svg");
      width: 20px;
      height: 20px;
    }

    .MuiButton-label {
      text-transform: none;
      font-weight: 700;
    }

    &:hover {
      background-color: lighten(#24dc91, 10%);
    }
  }
}

.studioCircleContainer {
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 300px;
  width: 300px;
  border-radius: 300px !important;
  background-image: url("../img/dotted-circle.svg");
  background-size: 100% !important;
  background-color: white !important;
  transition: 0.8s !important;

  &:hover {
    background-color: #d5f9ea !important;
  }
}

.event-category-select {
  .MuiAutocomplete-root {
    width: 300px;
  }

  .MuiFormControl-root {
    margin: 0 20px 0 0 !important;
  }

  .inputSelect {
    width: auto;
  }
}

.event-category-select,
.modal.course-share,
.courseSelect,
.timeBlockList {
  #category-autocomplete {
    .category-icon-wrapper {
      position: absolute;
      margin-top: -5px;
      width: 20px !important;
      height: 20px !important;
      margin-left: 5px;

      .category-icon {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }
}

.modal.course-share,
.timeBlockSingle:first-child {
  .category-icon-wrapper {
    margin-top: 11px !important;
  }
}

.courseSelect {
  #category-autocomplete {
    .category-icon-wrapper {
      top: 20px;
    }
  }
}

.page.create.learningPath {
  .MuiFormHelperText-root {
    font-size: 12px;

    &.custom {
      margin-top: -30px !important;
      margin-bottom: 25px !important;
    }
  }
}

.page.create.course {
  .MuiFormControlLabel-root.checkbox {
    font-weight: bold;
  }
  .MuiAutocomplete-inputRoot {
    flex-direction: column;
  }
}

.quiz-draggable .isDragging {
  background-color: white;
  transition: 0.3s;
  border-radius: 12px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
  border-top: none;
  border-bottom: none;
  button {
    display: none;
  }
}
