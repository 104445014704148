@import "variables";
@import url("https://fonts.cdnfonts.com/css/segoe-ui-4");

.page.login {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $blue;
  text-align: center;
  padding: 0px;
  background-size: cover;
  max-width: 100%;

  .background {
    mix-blend-mode: hard-light;
    opacity: 0.85;
    position: fixed;
    top: 0;
    transition: 1s;
    right: 0;
    left: 0;
    bottom: 0;
  }

  img {
    display: block;
    height: 85px;
    width: auto;
    margin: 0 auto;

    @media (max-width: 667px) {
      height: 45px;
    }
  }

  .loginWrapper {
    position: relative;
    z-index: 1;
    animation: fadeInUp 1s;
    margin-top: -50px;
    background-color: white;
    border-radius: 14px;
    overflow: hidden;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  }

  .flexRow {
    justify-content: center;
    padding: 20px;

    &:first-child {
      padding: 40px;

      @media (max-width: 667px) {
        padding: 20px;
      }
    }
  }

  .button.login {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
    padding: 0 8px;
    border-radius: 4px;
    height: 40px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.74);
    background-color: white;

    span {
      font-family: "Roboto-Regular", sans-serif !important;
    }

    text-transform: none;
    font-weight: bold;

    &.microsoft {
      padding: 0 12px;
      height: 41px;
      font-weight: 600;
      margin-top: -20px;
      border-radius: 0;
      box-shadow: none;
      border: 1px solid #8c8c8c;

      .MuiButton-label {
        font-family: "Segoe UI Regular", sans-serif !important;
        font-size: 14px !important;
        color: #5e5e5e !important;
      }

      .icon.microsoft {
        margin-right: 12px;
      }
    }
  }

  .MuiButton-label {
    text-transform: none;
    font-size: 14px;
    color: #444;

    .icon {
      width: 18px;
      margin-right: 12px;
      height: 18px;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

.icon.google.official {
  background-image: url("../img/google-official.svg");
}

.icon.microsoft {
  background-image: url("../img/microsoft.svg");
}
