@import "variables.scss";

.badgeWrapper {
  min-width: 325px;
  margin: 0 auto;
  .badgeItem {
    position: relative;
    background: #ffffff;
    flex-direction: column;
    align-items: flex-start !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    padding: 0 !important;
    transition: 0.2s;
    width: 325px;
    img {
      width: 70px;
      height: 70px;
      border-radius: 100%;
      margin-right: 10px;
    }
    &.active {
      background-color: white;
      z-index: 10;
      .innerTop {
        z-index: 10;
        position: relative;
      }
    }

    .innerTop {
      cursor: pointer;
      padding: 15px 15px;
      display: flex;
      align-items: center;
      width: 295px;
      .statusText {
        margin: 0;
        font-size: 12px;
      }
      h2 {
        font-size: 18px;
        margin: 0 0 5px 0;
      }
      .details {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .badgeImage {
        margin-right: 10px;
        width: 80px;
        height: 80px;
      }
    }

    .innerBottom {
      border-top: 1px solid #f0f0f0;
      padding: 15px;
      position: relative;
      z-index: 10;
      background-color: white;
      height: 100%;
      width: 295px;
      border-radius: 0 0 10px 10px;
      header {
        text-transform: uppercase;
        color: #888888;
        font-size: 12px;
      }
      ul {
        list-style-type: none;
        margin: 10px 0 0;
        padding: 0px;
      }
      li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0px 20px;
        margin: 0 -15px;
        display: flex;
        align-items: center;
        font-size: 14px !important;
        height: 45px !important;
        color: black;
        cursor: pointer;
        &:hover {
          background-color: #f0f0f0;
        }

        .category-icon {
          width: 20px;
          height: 20px;
          display: block;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
          border-radius: 100%;
          margin-right: 10px;
        }
        .title {
          flex: 1;
          line-height: 16px;
          margin-right: 10px;
        }
      }
    }
  }
}

.modal.badge-preview {
  z-index: 100000 !important;
  .MuiDialog-paper {
    border-radius: 6px;
    background: $light-grey;
  }
  p {
    max-width: 325px;
    margin: 0 0 20px;
  }
}
