@import "variables";

.showMorePopover {
  .MuiPopover-paper {
    overflow: visible;
    margin-top: 6px;

    &:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid white;
      position: absolute;
      top: -8px;
      margin-right: -73px;
      right: 50%;
    }
  }
}

#current-time-indicator {
  background-color: $green;
  height: 2px;
  position: relative;

  // #current-time-circle {
  //   position: absolute;
  //   width: 13px;
  //   left: 0px;
  //   top: -5px;
  //   border-radius: 14px;
  //   height: 13px;
  //   background-color: $green;
  // }
}

.sameDayEvents {
  padding: 0;
  list-style-type: none;

  li {
    padding: 5px 20px;
    align-items: center;
    display: flex;

    .chip {
      width: 15px;
      height: 15px;
      background-color: $blue;
      border-radius: 100%;
      margin-right: 10px;

      &.conference {
        background-color: $dark-blue;
      }

      &.draft {
        background-color: #575757;
      }
    }

    strong {
      flex: 1;
      margin-right: 50px;
    }

    .time {
      width: 80px;
      margin-right: 10px;
    }
  }
}

.maxEventOverride {
  height: 30px;
  font-size: 0.875rem;
  text-align: left;
  font-family: Nunito, sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  border-right: 1px solid rgba(224, 224, 224, 1);
  vertical-align: inherit;
  position: relative;
}

.showMoreWrapper {
  z-index: 10;
  text-align: right;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s;
  width: 100%;
  display: flex;
  position: absolute;
  top: 5px;

  .Cell-text {
    color: black;
    flex: 1;
    text-align: center;
  }

  &:hover {
    opacity: 1;
  }

  .showMore {
    position: absolute;
    right: 5px;
    border-radius: 18px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;
    padding: 5px 5px;
    background-color: $light-grey;
  }
}

#view-select {
  strong {
    display: none;
  }
}

.MuiButtonBase-root {
  .label {
    width: 135px;
  }

  strong.key {
    background-color: $light-grey;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    box-shadow: 0 2px 1px 1px rgba(0, 0, 0, 0.17);
    border-radius: 5px;
    font-size: 12px;
  }
}

.createButton {
  transition: 0.3s;

  &.disabled {
    opacity: 0.35;
    pointer-events: none;
  }
}

.conference-event-edit {
  max-width: 800px;

  #contentLock {
    top: 0;
  }

  .roomSelect {
    .button {
      right: -10px !important;
    }

    .MuiAutocomplete-root .MuiFormControl-root {
      max-width: 215px !important;
    }
  }

  .button.back {
    left: 20px;
    position: absolute;
    top: -43px;
    color: white;
    z-index: 30;

    &:focus {
      background-color: $dark-blue;
    }

    .icon {
      background-image: url("../img/back-white.svg");
    }
  }
}

.conferenceScheduleWrapper {
  border-radius: 6px;
  padding: 10px;
  margin-left: -5px;
  margin-top: -5px;
  position: relative;
  padding: 0;
  min-width: 670px;

  .overlay {
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10;
    opacity: 0;
    transition: 0.6s;
    pointer-events: none;

    &.active {
      opacity: 0.8;
      pointer-events: all;
    }
  }
}

.calendarInner {
  .MuiPaper-root {
    > div,
    > div > div {
      pointer-events: all !important;
      background-color: transparent;

      &.appointmentInner {
        padding-top: 2px !important;
        padding-left: 5px !important;
      }
    }

    table td {
      background-color: white !important;

      &:hover {
        background-color: $light-grey !important;
      }
    }

    table + div {
      table + div {
        div {
          height: auto !important;
        }
      }
    }
  }

  [class*="brightHeaderBorder"] {
    border: 0px;
    border-bottom: none !important;
  }

  [class*="ordinaryHeaderBorder"] {
    border-bottom: 0px;
  }
}

.MuiTable-root {
  border: 0px;
  border-color: rgba(0, 0, 0, 0);
}

.calendarLayout {
  padding: 100px !important;
  position: relative;
  margin: 0 auto;
  z-index: 10;

  @media (max-width: $screen-md-max) {
    .main.Schedule {
      margin: 0 -65px;
    }
  }

  header {
    position: relative;

    .loadingWrapper {
      position: absolute;
      left: 0;
    }
  }

  .MuiPaper-rounded {
    border-radius: 6px;
    overflow: hidden;
    box-shadow: none;
  }

  .searchWrapper {
    margin-top: -16px;
    margin-right: 20px;
    width: 250px;
  }

  .viewSelect {
    margin-right: 10px;

    .label {
      margin-right: 30px;
    }
  }

  .calendarInner {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    padding: 20px;
    opacity: 1;
    transition: 0.5s;

    &.conference {
      border-radius: 12px;

      &.Day {
        &.isEditing {
          background-color: $light-grey;
        }

        [class*="ordinaryLeftPanelBorder"] {
          left: 5px;
        }
      }
    }

    &.isEditing {
      .toolbar {
        opacity: 0;
      }

      .adjustRange {
        opacity: 0;

        &.shift {
          top: -30px;
          opacity: 0;
        }
      }

      > .MuiPaper-root {
        background-color: $light-grey;

        &:before {
          content: "";
          display: block;
          position: absolute;
          background-color: white;
          top: 50px;
          left: 0;
          bottom: 0;
          width: 60px;
        }
      }
    }

    &.fade {
      opacity: 0;
    }

    tr {
      position: relative;

      td {
        text-align: center;

        &.isToday > div {
          background-color: $green;
          color: black;
        }

        &.highlighted {
          @keyframes colorPulse {
            0% {
              background-color: white;
            }

            50% {
              background-color: lighten($green, 35%);
            }

            100% {
              background-color: white;
            }
          }

          animation: colorPulse 1.5s infinite;
        }

        &[class*="groupedByDate"] {
          background-color: white;
        }

        &[class*="TickCell"] {
          pointer-events: none;
          min-width: none;
        }
      }
    }

    .MuiDrawer-paper {
      > div {
        width: 100%;
      }
    }

    &.main {
      &.Week,
      &.Day {
        .MuiDrawer-paper {
          max-height: 662px;
          margin: 30px auto 50px auto;
          position: relative !important;

          [class*="LayoutBase-root"] {
            width: 100%;

            h2 {
              padding: 20px 0 0;
            }
          }
        }
      }
    }

    &.conference {
      .scheduleWrapper {
        height: 63vh;
        position: relative;
        left: 0;
        margin-right: -20px;
        transition: 0.5s;
        opacity: 1;
        margin-top: 45px;
        > div,
        div > div {
          pointer-events: all !important;
        }
        .MuiTableCell-root {
          min-width: 80px;
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          right: -20px;
          width: 45px;
          bottom: 0;
          background-color: white;
        }

        &.shift {
          opacity: 0;
          pointer-events: none;
        }
      }

      .newEventWrapper {
        transform: translateX(100%);
        z-index: 15;
        background-color: $light-grey;
        position: absolute;
        opacity: 0;
        transition: 0.6s;
        pointer-events: none;
        z-index: 24;
        top: 50px;

        &.shift {
          right: 0;
          width: auto;
          padding-bottom: 50px;
          pointer-events: all;
          opacity: 1;
          left: 0;
          transform: translateX(0);
        }

        .subnav {
          position: absolute;
          top: -50px;
          border-radius: 6px 6px 0 0;

          &.no-publish {
            .publish-button {
              display: none;
            }
          }

          .title {
            opacity: 0;
          }
        }

        .event-content {
          margin-top: 40px;
          margin-left: 100px;

          .flexRow.columns {
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            .column {
              margin-left: 0;

              .panel {
                max-width: none !important;
              }
            }
          }

          &.two-column {
            padding-right: 40px;

            .column {
              padding-top: 0;
              width: 100%;
              max-width: none;
              flex: 1;
            }

            .roomSelect {
              width: 325px;
            }

            .event-select-item {
              border-left: none;

              .location.icon {
                flex-shrink: 0;
              }
            }
          }

          .inputSearch {
            position: relative;
            top: 0px;
            margin-bottom: -20px;

            .icon {
              bottom: 22px;
            }
          }

          .eventImageWrapper {
            width: auto !important;
            margin-left: 0;

            p {
              &:first-child {
                margin-top: 32px;
              }
            }
          }

          &.event-registration {
            .MuiFormControl-root {
              width: 100% !important;
              max-width: none !important;
            }

            .max-capacity {
              .MuiInput-root {
                width: 75px !important;
              }
            }
          }

          .list-header {
            justify-content: space-between;
            position: relative;
          }

          .pdCreditRow {
            .MuiFormControl-root {
              width: 40px !important;
            }
          }

          .joinCodeRange {
            > div:first-child {
              margin: 0 0 0 0px;
            }

            > div {
              margin-bottom: 20px !important;
            }

            .time {
              .MuiInputBase-root {
                margin-top: 0px;
              }
            }
          }

          &.event-personnel {
            .column.left {
              padding-top: 0;
              padding-right: 40px;
              max-width: 100%;
              flex: 1;
            }
          }
        }
      }

      // .MuiPaper-root {
      //   margin-top: 0 !important;
      //   max-height: none !important;

      //   .flexRow.meta .select:first-child {
      //     margin-right: 5px;

      //     .MuiInput-formControl {
      //       margin-right: 10px;
      //     }
      //   }

      //   .MuiFormControl-root {
      //     .MuiFormControl-root {
      //       width: 50%;
      //     }

      //     .MuiTextField-root {
      //       width: 100%;
      //     }
      //   }
      // }
    }
  }
}

.adjustRange {
  left: 0;
  right: 0;
  font-size: 18px;
  font-weight: bold;
  height: 42px;
  display: flex;
  align-items: center;
  flex: 1;
  transition: 0.3s;
  opacity: 1;
  position: relative;
  top: 0px;

  &.shift {
    top: -30px;
    opacity: 0;
  }

  &.center {
    justify-content: center;
  }

  &.hidden {
    pointer-events: none;
    opacity: 0;
  }

  .caret {
    transform: rotate(90deg) scale(0.8);
    background-image: url("../img/caret.svg");
    background-size: cover;
    opacity: 0;
    width: 25px;
    height: 25px;
    background-size: 25px;
    background-position: center top 7px;
    background-repeat: no-repeat;
    cursor: pointer;
    padding: 10px;
    border-radius: 100%;
    transition: 0.3s;
    pointer-events: none;
    transition: 0.3s;

    &.flipped {
      transform: rotate(-90deg) scale(0.8);
      margin-right: 10px;
    }

    &.active {
      opacity: 0.3;
      pointer-events: all;
    }

    &:hover {
      background-color: rgba(201, 201, 201, 0.7);
    }
  }
}

.toolbar {
  padding: 10px 0;
  margin-bottom: 10px;
  display: flex;

  .button.small.expand {
    img {
      width: 18px;
      margin-right: 0;
    }
  }

  .button.small {
    &.hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  .viewControl {
    position: relative;
    z-index: 15;
    display: flex;
    align-items: center;

    .searchWrapper {
      .button {
        border-radius: 100%;
        padding: 10px;
      }

      .icon {
        margin: 0;
        opacity: 0.5;
      }
    }

    .button.small {
      &:active,
      &:focus {
        background-color: white !important;
      }
    }
  }

  a {
    text-decoration: none !important;
  }

  .button.primary {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: normal;
    border-radius: 8px;
    padding: 8px 20px;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.14);

    &.dark {
      background-color: #17577d;
    }
  }

  .MuiRadio-root {
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }

  .MuiFormControlLabel-root {
    position: relative;
    z-index: 12;
    background-color: $light-grey;
    padding: 5px 10px;
    margin: 0;
    border-right: 1px solid white;
    transition: 0.3s;

    .MuiTypography-root {
      width: 65px;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
    }

    &:first-child {
      border-radius: 6px 0 0 6px;
    }

    &:last-child {
      border-right: none;
      border-radius: 0 6px 6px 0;
    }

    &.active {
      color: white;
      background-color: #575757;
    }
  }
}

[class*="AllDayContainer"] {
  height: 45px;

  [class*="AppointmentsContainer"] {
    div {
      height: 45px;
    }
  }
}

.appointmentInner {
  color: white;
  padding: 0;
  line-height: 11px;
  font-weight: bold;
  font-size: 10px;
  height: 100%;
  background-color: $blue;
  transition: 0.3s;
  cursor: pointer;
  height: 120%;

  &.accept,
  &.draft,
  &.tentative,
  &.decline {
    &:before {
      content: "";
      display: block;
      position: absolute;
      height: 15px;
      width: 15px;
      background-image: url("../img/rsvp.svg");
      background-repeat: no-repeat;
      background-size: 10px;
      background-position: center left 2px;
      right: 4px;
      top: 3px;
      background-color: white;
      box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.3);
      border-radius: 100%;
    }
  }

  &.draft {
    background-color: $dark-grey !important;

    &:before {
      background-position: top 54% left 2px;
      background-size: 75%;
      background-color: $dark-grey;
      box-shadow: none;
      background-image: url("../img/i-draft.svg");
    }
  }

  &.isAllDay {
    [class*="VerticalAppointment-textContainer"] {
      opacity: 0;
    }
  }

  &:hover {
    background-color: #50a8c6;
  }

  &.disabled {
    opacity: 0.5;
  }

  &.external {
    background-color: #178f5e;

    &:hover {
      background-color: lighten(#178f5e, 5%);
    }
  }

  &.conference {
    background-color: $dark-blue;

    &:hover {
      background-color: lighten($dark-blue, 10%);
    }
  }

  &.session {
    background-size: 75px;
    background-position: left -10px;
    background-color: darken(#d1b6c3, 10%) !important;

    &:hover {
      background-color: darken(#d1b6c3, 20%) !important;
    }
  }

  header {
    padding: 5px 0;
  }

  > div {
    margin-right: 15px;
  }
}

#arrange-rooms-modal {
  padding: 10px 20px;
  min-width: 560px;
  max-height: 70vh;
  display: flex;
  flex-direction: column;

  h2 {
    border-bottom: 1px solid $light-grey;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-bottom: 20px;
  }

  em {
    font-size: 14px;
  }

  .icon.room {
    background-image: url("../img/room-black.svg");
  }

  footer {
    justify-content: space-between;

    .button.cancel {
      color: #999 !important;
    }
  }

  ul {
    margin-top: 25px;
    list-style-type: none;
    flex: 1;
    overflow: auto;
    padding-right: 300px;
    width: 100%;
    margin-bottom: 25px;

    li {
      display: flex;
      align-items: center;
      padding: 10px;
      background-color: transparent;
      transition: background-color 0.3s;
      border-radius: 30px;

      &:hover {
        background-color: $light-grey;
      }
    }

    .icon.hamburger {
      margin-right: 10px;
      width: 20px;
      height: 20px;
      background-image: url("../img/room-black.svg");
      opacity: 0.3;
    }
  }
}

.sessionFilters {
  position: absolute !important;
  z-index: 3;
  top: 120px;
  right: 20px;
  left: 20px;
  justify-content: flex-end;
  transition: 0.4s;

  #arrange-rooms-button {
    position: absolute;
    left: 170px;
    top: -32px;

    .icon.cells {
      background-image: url("../img/cells.svg");
    }
  }

  .checkbox {
    position: absolute;
    left: 20px;
    top: -35px;
  }

  .searchWrapper {
    margin-right: 0;
    margin-top: -65px;
  }

  .icon {
    top: 21px;
  }

  .MuiFormControl-root.select {
    margin-top: 0;
    top: -32px;
    max-width: 150px !important;
    min-width: 100px !important;
    width: auto;
  }

  .inputSearch {
    max-width: 250px;
    position: relative !important;
    margin-left: 0 !important;
    top: 3px;

    .MuiFormControl-root {
      max-width: 100%;
    }
  }

  &.shift .searchWrapper {
    margin-top: -65px;
  }

  &.multiDay {
    top: 150px;

    .searchWrapper {
      margin-top: -50px;
    }

    &.shift {
      top: 170px;

      .searchWrapper {
        margin-top: -170px;
      }

      .MuiFormControl-root.select {
        top: -86px;
      }
    }
  }
}

a {
  text-decoration: none !important;
}
.icon.observation.blue {
  background-image: url("../img/observation-blue.svg");
}
.icon.edit {
  top: -1px;
  position: relative;
  background-image: url("../img/edit.svg");

  &.white {
    background-image: url("../img/edit-white.svg");
  }
}

.icon.view {
  background-size: 18px;
  position: relative;
  top: -2px;
  background-image: url("../img/cal-preview.svg");
}

.eventFormControl {
  width: 100%;
  padding: 0 20px;

  .button.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  h2 {
    display: flex;

    .title {
      flex: 1;
    }
  }

  .button.cancel {
    margin-right: 25px;
  }
}

.eventCreateFormWrapper {
  width: 100%;
  padding: 0px 20px;

  .loadingWrapper {
    margin-left: -20px;
  }
}

.eventConfirmationModal {
  .MuiPaper-root {
    border-radius: 12px;
  }

  .current-people-list {
    max-height: 250px;
    overflow: auto;

    li .icon {
      width: 30px;
      height: 30px;
    }
  }

  ul {
    margin: 0px -20px 10px -20px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  header {
    background-color: $light-grey;
    margin: -20px -20px 10px -20px;
    padding: 20px;
    color: black;

    .chip {
      background-color: $blue;
      width: 20px;
      height: 20px;
      margin-right: 15px;
      border-radius: 100%;

      &.conference {
        background-color: $dark-blue;
      }
    }
  }

  .event-select-item:hover {
    cursor: inherit;
    background-color: white;
  }

  .event-select-item {
    .icon {
      width: 25px;
      height: 25px;
    }
  }

  .event-meta {
    font-size: 14px;
    line-height: 16px;
    margin: 20px 0 10px;

    .icon {
      flex-shrink: 0;
      width: 25px !important;
      height: 25px !important;
      margin-right: 10px;
    }

    a {
      color: white;
    }
  }

  h2 {
    line-height: 24px;
    margin-top: 0;
  }
}

.eventTooltip {
  .colorPicker {
    position: absolute;
    top: 15px;
    right: 5px;
    box-sizing: content-box;
    border: 2px solid white;
    height: 25px !important;
    width: 25px !important;
    padding: 0 !important;
  }
  .icon.chair {
    background-image: url("../img/chair-white.svg");
    width: 15px;
    height: 15px;
    margin: 0 5px 0 15px;
  }
  .eventImage {
    height: 150px;
    background-size: cover;
    background-position: center;
    position: relative;

    &.noImage {
      background-image: url("../img/calendar-white.svg");
      background-position: center top 20px;
      background-repeat: no-repeat;
      background-size: 80px;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 150px;
      left: 0;
      top: 0;
      right: 0;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.75) 19%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }

  .capsuleContainer {
    position: absolute;
    bottom: -40px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;

    .category-image {
      width: 20px;
      height: 20px;
      border: 2px solid white;
      background-size: cover;
      display: block;
      border-radius: 20px;
      margin-right: 5px;
    }
  }

  .room-info {
    position: absolute;
    top: 15px;
    left: 15px;
    display: flex;
    align-items: center;
    color: white;
    z-index: 4;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.77);
    font-size: 13px;

    .icon {
      width: 15px;
      margin-right: 5px;
      height: 15px;
      background-image: url("../img/room-white.svg");
    }
  }

  header {
    position: absolute;
    bottom: 25px;
    left: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.77);
    z-index: 2;

    .title {
      font-weight: bold;
      font-size: 20px;
      text-align: center;
    }

    .dateTime {
      font-size: 14px;
      font-weight: normal;
    }
  }

  .conferenceIndicator {
    width: 35px;
    height: 35px;
    border-radius: 40px;
    background-image: url("../img/conference-white.svg");
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.42);
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .inner {
    padding: 20px;
  }

  .description {
    font-size: 14px;
    max-height: 35vh;
    overflow: auto;

    a {
      font-weight: bold;
      color: darken($blue, 15%);
    }
  }

  footer {
    justify-content: flex-end;
    display: flex;
    align-items: center;

    .button {
      margin-left: 10px;
    }
  }

  .flexRow.location {
    font-size: 14px;
    margin-top: 10px;
    margin-left: -10px;

    strong {
      display: block;
    }

    .address {
      opacity: 0.6;
    }

    .icon {
      width: 40px;
    }
  }
}

.eventCreateForm {
  &.isLoading {
    opacity: 0.15;
    pointer-events: none;
  }

  .MuiFormControl-root,
  .select {
    margin: 0 0 10px;
  }

  .flexRow.meta {
    .select {
      flex: 1;

      &:first-child {
        margin-right: 35px;
      }
    }
  }

  .MuiFormControl-root {
    display: flex;
  }

  footer {
    padding: 20px 0;
    display: flex;
    justify-content: flex-end;

    a {
      text-decoration: none;
    }
  }
}

.dateTimeSelect {
  .MuiInputBase-root:before {
    display: none;
  }

  .MuiFormControl-root {
    max-width: 160px;

    &.time {
      margin-left: 10px;
      width: 125px;

      .MuiIconButton-root {
        display: none;
      }

      &.hidden {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  .MuiFormControlLabel-root {
    margin-top: 3px;
    margin-left: 10px;
  }
}

.page.archived-events {
  max-width: 1160px;
  margin-left: 250px;
  padding-top: 85px;

  h2 {
    font-size: 22px;
    margin-top: 0;
    font-weight: bold;
  }
}

.calendarList {
  position: relative;

  .fetchButtonContainer {
    display: flex;
    justify-content: center;
    border: 0;
  }

  .loadingWrapper {
    position: absolute;
    top: -60px;
    right: 40px;
  }
  .attendanceLoading {
    padding: 10px;
    .loadingWrapper {
      position: static;
    }
  }
  &.archivedList {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    animation: fadeIn 0.3s forwards;
    z-index: 12;

    .title {
      margin-left: 30px;
    }

    .button.large {
      background-color: $red;

      &:hover {
        background-color: darken($red, 8%);
      }

      .MuiButton-label {
        color: white;
      }
    }

    .chip {
      background-color: $blue;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      margin-right: -20px;

      &.conference {
        background-color: $dark-blue;
      }

      &.draft {
        background-color: #575757;
      }
    }

    ul {
      border-radius: 8px;
      margin: 10px;
      box-shadow: 0 2px 4px 0 rgb(0 0 0 / 0.2);
      padding: 0;
      width: 100%;
      background-color: white;
      margin-left: 35px;
      max-height: none;
      margin-right: 35px;

      li {
        padding: 20px;

        .icon.archive {
          width: 25px;
          height: 25px;
          margin-right: -20px;
        }
      }
    }
  }

  ul {
    list-style-type: none;
    margin: 35px -20px 0 0;
    padding: 10px 20px 0;
    max-height: 50vh;
    overflow: auto;

    .month-header {
      padding: 8px;
      font-weight: bold;
      font-size: 14px;
      text-align: center;
      background-color: #f0f0f0;
      margin: 10px 0px;
      border-radius: 80px;
      cursor: auto;
    }

    @media (max-width: $screen-md-max) {
      margin: 0px;

      .flexRow.responsive {
        align-items: flex-start;
        flex-direction: column;
        padding-bottom: 15px;
      }

      .roleLabel {
        position: absolute;
        right: 10px;
        top: 10px;
        margin-right: 0px !important;
      }

      footer {
        position: absolute;
        right: 0px;
        bottom: 10px;
      }
    }

    li {
      border-bottom: 1px solid $light-grey;
      cursor: pointer;

      .flexRow {
        flex: 1;
      }

      .weekMonth {
        opacity: 0.5;
        font-weight: lighter;
        line-height: 18px;
        text-transform: uppercase;
        text-align: center;
        width: 50px;
      }

      .dateTime {
        display: flex;
        align-items: center;
      }

      .date {
        margin-right: 35px;
        width: 40px;
        display: block;
        text-align: center;
        font-size: 28px;
      }

      &:last-child {
        border-bottom: none;
      }

      .roleLabel {
        margin-right: 20px;
        padding: 6px 15px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 10px;
        border-radius: 20px;
        background-color: $light-grey;

        .icon {
          margin-right: 5px;
          width: 20px;
          height: 20px;
        }
      }

      .title {
        flex: 1;

        header {
          display: flex;
          align-items: baseline;
        }

        .chip {
          margin-right: 10px;
          flex-shrink: 0;
          width: 15px;
          height: 15px;
          border-radius: 100%;
          background-color: $blue;
          display: block;

          &.conference {
            background-color: $dark-blue;
          }

          &.draft {
            background-color: #575757;
          }
        }

        header {
          font-weight: bold;
          font-size: 20px;
        }
      }

      p {
        margin: 10px 0 0;
        font-size: 14px;
      }

      .MuiAccordionDetails-root {
        flex-direction: column;
      }

      .flexRow.meta {
        .icon {
          width: 20px;
          height: 20px;
        }
      }

      .time {
        display: flex;
        align-items: baseline;
        font-size: 12px;
        margin-top: 10px;

        .multiDay {
          div {
            display: flex;
            align-items: center;
          }

          position: relative;

          &:before {
            position: absolute;

            content: "";
            width: 3px;
            top: 7px;
            left: 2.5px;
            bottom: 6px;
            background-color: $blue;
            display: block;
          }

          div:before {
            margin-right: 5px;
            content: "";
            width: 8px;
            height: 8px;
            background-color: $blue;
            display: block;
            border-radius: 10px;
          }
        }

        .calendar {
          margin-right: 10px;
          width: 15px;
          opacity: 0.3;
          position: relative;
          top: 2px;
          height: 15px;
        }
      }
    }
  }
}

.conferenceCalendar {
  .calendarInner {
    .MuiPaper-root {
      border-radius: 0 0 6px 6px;
    }

    .scheduleWrapper > div > div > div > div:first-child tr:first-child {
      display: none;
    }
  }
}

.page.calendarLayout.eventsLayout {
  padding: 75px 25px 35px 275px !important;
  max-width: none;
  z-index: 1;

  .pageInner {
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    border-radius: 8px 8px 0 0;
  }

  .calendarInner {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    border-radius: 0 0 8px 8px;

    &.Month {
      .MuiPaper-root {
        background-color: transparent;
        border-radius: 0 0 6px 6px;

        td[tabindex="0"] {
          height: 11vh;
        }
      }

      [class*="ordinaryHeaderBorder"] {
        flex: 1;

        table tbody,
        table tr:hover {
          border-bottom: 1px solid $light-grey;
          text-transform: uppercase;

          div {
            font-size: 14px;
            padding-bottom: 5px;
            color: black;
          }
        }
      }
    }

    &.Schedule {
      background-color: white;
      .seatsRemaining {
        font-size: 12px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        .icon.chair {
          width: 15px;
          height: 15px;
          margin-right: 10px;
          background-image: url("../img/chair-black.svg");
          opacity: 0.3;
        }
      }
      .MuiPaper-root {
        border-top: 1px solid $light-grey;

        .calendarList {
          top: -35px;
          height: 75vh;

          ul {
            max-height: 75vh;
          }
        }
      }
    }

    &.Week,
    &.Day {
      [class*="ordinaryHeaderBorder"] {
        flex: 1;
        table tbody,
        table tr:hover {
          background-color: $green;

          div {
            p {
              font-size: 12px;
              text-transform: uppercase;
              padding-top: 10px;
            }

            div {
              padding-bottom: 10px;
            }

            p,
            div {
              color: black;
            }
          }
        }
      }
    }

    .MuiPaper-root {
      background-color: transparent;
      border-radius: 0 0 6px 6px;

      > div > div > div > div {
        background-color: transparent;
      }

      table {
        max-width: none;
      }
    }
  }

  header.toolbar {
    background-color: white;
    border-radius: 8px 8px 0 0;
    flex-direction: column;
    padding: 0;
    margin-bottom: 0;

    .flexRow {
      padding: 10px;
    }

    .loadingWrapper {
      position: relative;
      font-size: 12px;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      top: 2px;
    }
  }
}

.calendar-legend {
  margin-bottom: 0px;
  border-bottom: 1px solid #ebeb;
  padding: 5px 20px !important;
  height: 44px;

  .loadingWrapper {
    .sk-spinner > div {
      width: 20px;
      height: 20px;
    }
  }

  .item {
    font-size: 12px;
    margin-right: 30px;
    display: flex;
    align-items: center;
  }

  .circle {
    margin-right: 5px;
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 5px;
  }
}

#attendance-summary {
  flex: 1;
  li {
    border-bottom: none;
  }
}
#event-tooltip-container {
  #attendance-summary-container {
    display: flex;
    align-items: center;

    #attendance-summary {
      flex: 1;
    }
  }

  .MuiPaper-root {
    min-width: 500px;
    border-radius: 12px;
  }

  .MuiDialog-paper footer {
    justify-content: flex-end;
    padding: 10px 0 0 0 !important;
    margin-bottom: -5px;

    .button:last-child {
      margin-left: 20px;
    }

    div > button {
      margin-left: 0 !important;
    }
  }

  .MuiBackdrop-root {
    opacity: 0.25 !important;
  }
}

.createNewPopover {
  display: flex;
  justify-content: center;
  align-items: center;

  .MuiPaper-root {
    border-radius: 10px;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    margin-left: 200px;

    li {
      display: flex;
    }

    ul {
      margin: 5px 10px;

      li {
        padding: 5px 0;
      }
    }

    a,
    .button {
      width: 100%;
      flex: 1;
    }

    .icon.event {
      background-image: url("../img/event-blue.svg");
    }

    .icon.conference {
      background-image: url("../img/conference-blue.svg");
    }

    h2 {
      padding: 10px 30px;
      margin: 0;
      font-weight: bold;
      font-size: 18px;
      border-bottom: 1px solid $light-grey;
    }
  }
}

.MainLayout-leftPanel {
  table {
    margin-top: 35px;
    td {
      .Label-label {
        height: 50px;
      }
      .Label-emptyLabel {
        height: 0px;
      }
    }
  }
}
