@import "variables";

#orgSelectPopover {
  .MuiPaper-root {
    border-radius: 0 0 6px 6px;
    margin-top: 1px;
    margin-left: -50px;
    min-width: 350px !important;
    box-shadow: none;
  }

  z-index: 43 !important;

  ul {
    margin: 0;
    padding-top: 0;

    li {
      font-family: "Nunito", sans-serif !important;
    }
  }
}

#user-menu {
  z-index: 12000;
  inset: 0px;
  margin-top: -30px;
  right: 4px;
}

.MuiList-root {
  > div {
    display: flex;
    flex-direction: column;
  }
}
#tour-events {
  .popover {
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
    border-radius: 12px;
    padding: 10px;
    position: absolute;
    z-index: 4;
    top: -20px;
    left: 25px;
    width: 450px;
    font-weight: normal;
    line-height: 16px;
    text-align: left;
    display: flex;
    align-items: center;
    text-transform: none;
    color: black;
    margin-top: 50px;
    opacity: 0;
    transition: 0.4s;
    cursor: pointer;
    p {
      font-size: 12px !important;
    }
    a {
      padding: 0;
      color: $blue;
      margin: 0;
    }
  }
  &:hover .popover {
    opacity: 1;
  }
}
.exportPopup {
  position: absolute;
  right: 65px;
  top: 16px;
  transition: 0.4s;
  opacity: 0;
  font-size: 14px;
  font-weight: bold;

  &.active {
    opacity: 1;
    right: 130px;
  }
}

.navbar {
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  top: 0;
  background-color: $dark-grey;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);

  a {
    text-decoration: none;
  }

  h1 {
    padding: 0 10px;
    color: white;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 20px 0 -10px;
    padding-bottom: 2px;

    span {
      text-transform: uppercase;
      font-weight: 300;
      font-size: 10px;
      position: relative;
      left: 5px;
      letter-spacing: 1px;
      top: 2px;
    }

    img {
      position: relative;
      top: 2px;
      margin-right: 15px;
      width: 150px;
    }
  }

  .profile {
    display: flex;
    align-items: center;
  }

  div.upgrade {
    margin-right: 35px;
    height: 45px;
    color: white;
    font-weight: bold;
    font-size: 14px;
    opacity: 0.75;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    position: relative;

    &:hover {
      opacity: 1;
    }

    &:hover:after {
      opacity: 1;
      top: 40px;
    }

    .icon {
      width: 15px;
      height: 15px;
      position: relative;
      margin-right: 5px;
      top: -1px;
      background-image: url("../img/upgrade.svg");
      background-size: contain;
    }

    &:after {
      background-color: white;
      border-radius: 6px;
      display: block;
      content: "Contact your MobileMind Customer Success representative to learn how to upgrade to MobileMind HQ.";
      color: black;
      position: absolute;
      width: 250px;
      padding: 10px;
      top: 20px;
      font-weight: normal;
      right: 0;
      opacity: 0;
      pointer-events: none;
      transition: 0.3s;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
    }
  }

  .userInfo {
    text-align: right;
    line-height: 16px;
    color: white;

    [role="combobox"] {
      color: white;
      font-size: 12px;
    }

    .MuiSelect-icon {
      color: white;
      top: calc(50% - 14px);
    }

    .MuiSelect-root {
      font-size: 11px;
      color: rgba(255, 255, 255, 0.8);
    }

    .MuiInput-underline:before,
    .MuiInput-underline:after {
      display: none;
    }

    @media (max-width: $screen-md-min) {
      line-height: 12px;
    }

    strong.user-name {
      font-size: 14px;
      font-weight: 600;
      text-transform: capitalize;
      position: relative;
      top: 6px;

      @media (max-width: $screen-md-min) {
        font-size: 12px;
      }
    }

    span {
      display: block;
      text-transform: uppercase;
      font-size: 10px;
      color: rgba(255, 255, 255, 0.8);

      @media (max-width: $screen-md-min) {
        font-size: 8px;
        font-weight: 700;
      }
    }
  }
}

#publish-popover .MuiPopover-paper {
  padding: 20px;
  font-size: 15px;
  right: 30px;
  background-color: $dark-grey;
  color: white;
  max-width: 395px;
  width: 350px;

  p {
    margin: 10px;
    font-weight: 700;
  }

  ul {
    margin-left: 25px;

    li {
      padding: 3px;

      li {
        margin-left: -25px;
      }
    }
  }
}

.userPicture {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
}

.subnav {
  position: fixed;
  left: 0;
  right: 0;
  top: 50px;
  z-index: 15;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
  background-color: $blue;
  animation: slideDown 0.5s forwards;
  display: flex;
  align-items: center;
  color: white;
  height: 50px;

  .inner {
    height: 50px;
    padding: 10px 20px;

    .loading-small {
      height: 30px;
      width: 30px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url("../img/loading-circle.svg");
      animation: rotate 1s linear infinite;
      position: absolute;
      left: 10px;
    }
  }

  &.loading {
    .inner {
      background: linear-gradient(109deg, #59c3e5, #9ad86d);
      background-size: 400% 400%;
      animation: AnimationName 2.6s ease infinite;
      height: 30px;
      padding: 10px 20px;

      @-webkit-keyframes AnimationName {
        0% {
          background-position: 0% 50%;
        }

        50% {
          background-position: 100% 50%;
        }

        100% {
          background-position: 0% 50%;
        }
      }

      @-moz-keyframes AnimationName {
        0% {
          background-position: 0% 50%;
        }

        50% {
          background-position: 100% 50%;
        }

        100% {
          background-position: 0% 50%;
        }
      }

      @keyframes AnimationName {
        0% {
          background-position: 0% 50%;
        }

        50% {
          background-position: 100% 50%;
        }

        100% {
          background-position: 0% 50%;
        }
      }
    }
  }

  .eventTitle {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    .dateTime {
      display: inline-block;
      font-weight: normal;
      font-size: 12px;
      margin-top: -5px;
    }
  }

  &.template {
    background-color: $green;
  }

  &.conference {
    background-color: $dark-blue;
    z-index: 20;
  }

  button.noPointer {
    pointer-events: none;
  }

  button.disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  a {
    text-decoration: none;
    pointer-events: none;
    opacity: 0.4;
    transition: 0.5s;

    &.active {
      pointer-events: all;
      opacity: 1;
    }
  }

  .button {
    color: white !important;
    margin-left: 20px;
    text-transform: uppercase;
    &:focus {
      background-color: rgba(0, 0, 0, 0) !important;
    }
  }

  .title {
    display: flex;
    align-items: center;
    font-weight: 600;
    flex: 1;

    .icon {
      margin-right: 10px;
      width: 25px;
      height: 25px;
    }

    .icon.badge {
      background-image: url("../img/i-badge-white.svg");
    }

    .icon.announcement {
      background-image: url("../img/announcement-white.svg");
    }

    .icon.learningPath {
      background-image: url("../img/learning-path-white.svg");
    }

    .icon.laptop {
      background-image: url("../img/laptop-white.svg");
    }

    .icon.leaderboards {
      background-image: url("../img/i-leaderboards-white.svg");
    }

    .icon.key {
      background-image: url("../img/key-white.svg");
    }

    .icon.list-white {
      width: 20px;
      height: 20px;
      background-image: url("../img/list-white.svg");
    }

    .icon.location-white {
      width: 20px;
      height: 20px;
      background-image: url("../img/location-white.svg");
    }

    .icon.compliance {
      background-image: url("../img/compliance-white.svg");
      position: relative;
      top: -1px;
    }
  }
}

nav {
  display: flex;
  align-items: center;
  padding: 0px 20px;

  ul.menu {
    flex: 1;
    color: white;
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    height: 50px;

    .feature-popover {
      pointer-events: none;
    }
    .feature-addon-container:hover {
      .feature-popover {
        pointer-events: all;
      }
    }

    li {
      text-transform: uppercase;
      line-height: 38px;
      display: flex;
      align-items: center;
      position: relative;

      &:hover,
      &:focus {
        @media (min-width: $screen-sm-max) {
          background-color: rgba(0, 0, 0, 0.3) !important;
        }
      }

      button {
        height: 50px;
        border-radius: 0px;
        position: relative;

        a {
          padding: 14px 30px;
          margin: 0 -20px;
        }

        &.isLocation {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      a,
      button {
        color: white;
        text-decoration: none;
        padding: 0 20px;
        font-size: 12px;
        font-weight: 700;

        &:hover,
        &:focus {
          background-color: rgba(0, 0, 0, 0) !important;
        }
      }
    }
  }
}

.nestedItem {
  padding: 0 !important;
  border-radius: 0 !important;

  &:first-child {
    margin-top: -8px !important;
  }

  &:last-child {
    margin-bottom: -8px !important;
  }

  a {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    padding: 16px 20px;
    width: 100%;
    display: block;
    color: white;
    background-color: $dark-grey;

    &:hover {
      background-color: rgba(0, 0, 0, 0.72) !important;
    }
  }
}

.dropdown-menu,
#content-menu,
#account-menu {
  .MuiPopover-paper,
  .MuiMenu-paper {
    background-color: $dark-grey;
    border-radius: 0;

    .dropdown-menu {
      padding: 16px 20px !important;
    }

    li {
      min-width: 110px;
      padding: 0;
      height: 50px;
      display: flex;
      align-items: center;

      .MuiSvgIcon-root {
        display: none;
      }

      &:hover {
        @media (min-width: $screen-sm-max) {
          background-color: rgba(0, 0, 0, 0.2) !important;
        }
      }
    }

    .MuiList-root {
      padding: 0;
    }

    a {
      display: flex !important;
      align-items: center;
      padding: 16px 20px !important;
    }

    a,
    .MuiButtonBase-root {
      font-weight: 700;
      text-decoration: none;
      width: 100%;
      color: white;
      display: block;
      font-size: 12px;
      text-transform: uppercase;
      width: 100%;

      &.disabled {
        opacity: 0.35;
        pointer-events: none;
      }
    }
  }
}

.profilePicture {
  padding: 0px;
  width: 36px;
  height: 36px;
  margin: 0 15px 0 15px;
  cursor: pointer;
  border-radius: 100%;
  background-size: cover;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.12), 0 6px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  transform: scale(1);
  background-position: center;
  border: 3px solid white;
  box-sizing: content-box;

  &:hover {
    transform: scale(1.2);
  }
}

#user-menu {
  .MuiPopover-paper {
    top: 75px !important;
    right: 55px;
    left: auto !important;
    overflow: visible;
    background-color: rgba(0, 0, 0, 0);

    @media (max-width: $screen-sm-max) {
      right: 35px;
    }

    .MuiList-root {
      display: flex;
      flex-direction: column;
      background-color: white;
      border-radius: 6px !important;
      margin-top: -2px !important;
      padding: 0;

      a {
        font-weight: normal;
        font-size: 16px;
        text-transform: none !important;
      }
    }

    .MuiMenuItem-root {
      min-width: 150px;
      padding: 0px;
      background-color: white;
      overflow: hidden;

      &:first-child {
        border-radius: 4px 4px 0 0;
      }

      &:hover {
        background-color: #f0f0f0;
      }

      a {
        display: block;
        text-align: left;
        padding: 15px;
        width: 100%;
        text-decoration: none;
        color: black;

        &:focus {
          background-color: white;
        }
      }
    }

    &:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid white;
      position: absolute;
      top: -8px;
      right: 15px;
    }
  }
}

#create-menu {
  margin-top: 0px !important;

  .MuiPopover-paper {
    &:before {
      margin-right: -25px !important;
    }
  }
}

#preview-menu,
#export-menu,
#conference-export-menu {
  &.create-menu {
    max-width: 1765px;

    .MuiPopover-paper:before {
      right: 60px;
    }

    .MuiPaper-root {
      left: 0;
      margin-top: 5px;
      right: 40px;
    }

    li {
      padding: 0;
    }

    a {
      .icon {
        margin-right: 10px;
        width: 18px;
        height: 18px;
        background-size: contain;
        position: relative;
        top: 4px;

        &.copy {
          top: 2px;
          background-image: url("../img/copy-black.svg");
        }
      }

      padding: 10px 15px;
      flex: 1;
      text-decoration: none;
      color: black;
    }
  }

  .MuiListItem-root {
    padding: 10px 15px;
  }

  .MuiPopover-paper {
    overflow: visible;

    &:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid white;
      position: absolute;
      top: -8px;
      margin-right: -8px;
      right: 50%;
    }
  }
}

#export-menu {
  .MuiList-root {
    width: 196px;
  }
}

#create-menu {
  margin-top: 35px;
  margin-left: -20px;

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .icon.calendar {
    background-image: url("../img/calendar-black.svg");
  }

  .icon.conference {
    background-image: url("../img/conference.svg");
  }

  .MuiPopover-paper {
    overflow: visible;

    a {
      color: black;
      display: flex;
      align-items: center;
      margin: -6px;
      padding: 6px;
      flex: 1;
    }

    &:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid white;
      position: absolute;
      top: -8px;
      margin-right: -8px;
      right: 50%;
    }
  }
}

.conferenceNavbarWrapper {
  position: fixed;
  z-index: 11;

  &.locked {
    .button {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    }
  }
}
