@import "../../styles/variables.scss";

.videoContainer {
  position: relative;
  height: 0;
  padding-top: 56.25%;
  width: 100%;
}

.videoContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  width: 100%;
  height: 100%;
}

#new-features-modal {
  .MuiDialog-container {
    overflow: auto; //    height: auto;
  }
  .icon.lightbulb {
    width: 30px;
    height: 30px;
    background-image: url("../../img/new-product.svg");
  }

  footer {
    margin-top: -20px;
  }

  .feature-image {
    max-width: 80%;
    margin: 0 auto;
    display: block;
    border-radius: 12px;
  }

  .feature-link {
    transition: 0.5s;
    padding-bottom: 50px;
    margin-right: 25px;

    &:hover {
      opacity: 0.8;
    }
  }

  .button {
    .icon.pointer {
      background-image: url("../../img/pointer.svg");
    }

    .icon.phone {
      background-image: url("../../img/telephone-call.svg");
    }
  }

  .CarouselItem {
    button {
      height: auto !important;
      position: static !important;

      &:nth-child(2) {
        margin-left: 15px;
      }
    }
  }

  .carousel-item-container {
    display: flex;
    flex-direction: column;
    padding: 25px;
    padding-right: 25px;
    overflow: auto;
    height: 70vh;
    padding-bottom: 100px;
    margin-bottom: 100px;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .info {
      header {
        margin-top: 15px;
      }
    }

    @media (min-width: 1600px) {
      flex-direction: row-reverse;
      padding-top: 15px;

      .videoContainer {
        padding-top: 27.5%;
      }

      .info {
        margin-left: 20px;
      }
    }
  }

  .MuiPaper-root {
    border-radius: 14px;
    min-width: 90vw;
    position: relative;
    max-height: 100vh;
    .MuiIconButton-root {
      position: relative;
    }
  }

  .dialog {
    padding: 0;

    .inner {
      overflow: auto;
      padding-top: 10px;
      height: 75vh;

      &::-webkit-scrollbar {
        display: none;

        div {
          pointer-events: all;
        }
      }

      div {
        background-color: transparent !important;
        width: auto;
      }

      > div {
        position: static !important;
        display: flex;
        flex-direction: column-reverse;
        pointer-events: all !important;

        > div:last-child {
          position: absolute;
          bottom: 0px;
          right: 0;
          left: 0;
          z-index: 6;
          background-color: white !important;
          padding-bottom: 10px;
        }

        > div:first-child {
          position: static;
          bottom: 0px;
          z-index: 6;
        }
      }

      p {
        font-size: 18px;
        line-height: 22px;
        max-width: 1600px;

        a {
          color: darken($blue, 15%);
          font-weight: bold;
        }
      }

      header {
        font-weight: bold;
        font-size: 28px;

        @media (min-width: 1600px) {
          margin-top: 0;
        }
      }
    }
  }

  h2 {
    padding: 10px 20px;
    font-size: 20px !important;
    background-color: $green;
    justify-content: flex-start;
    margin-bottom: 0px;

    span {
      text-align: left;
    }

    .MuiButtonBase-root {
      margin-right: -10px;
      padding: 5px;
      position: relative;
      z-index: 10;

      .icon {
        width: 30px;
        height: 30px;
        background-image: url("../../img/x-large.svg");
        top: 0;
        margin-right: 0;
      }
    }
  }
}

.quick-action-panel {
  background-color: white;
  position: fixed;
  left: 0;
  transition: 0.5s;
  top: 140px;
  z-index: 2;
  overflow: auto;
  max-height: calc(100vh - 160px);
  border-radius: 0 12px 12px 0;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);

  &.active {
    left: 255px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  ul {
    margin: 10px 0;

    li {
      list-style-type: none;
      display: flex;
      padding: 10px 20px;
      border-bottom: 1px solid $light-grey;
      font-size: 14px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        &:not(.active) {
          background-color: $light-grey;
        }
      }

      &.active {
        border-bottom: 1px solid $green;
        background-color: $green;
      }

      &:last-child {
        border-bottom: none;
      }

      .icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
}

.action-overlay {
  transition: 0.5s;
  background-color: black;
  opacity: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 255px;
  pointer-events: none;
  cursor: pointer;
  z-index: 1;

  &.active {
    pointer-events: all;
    opacity: 0.3;
  }
}

.collection-org-list {
  li {
    transition: 0.3s;
    &:hover {
      background-color: rgba(255, 255, 255, 0.5) !important;
    }
  }
}

.quick-action-icon {
  &:after {
    display: block;
    width: 10px;
    height: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    position: relative;
    left: 15px;
    top: -2px;
  }

  &.icon.create-course,
  &.icon.courses {
    background-image: url("../../img/laptop-black.svg");
  }

  &.create-learning-path,
  &.learning-paths {
    background-image: url("../../img/learning-path-black.svg");
  }

  &[class*="create"],
  &[class*="post"] {
    &:after {
      background-image: url("../../img/sidebar/plus-black.svg");
    }
  }

  &.post-announcement,
  &.announcements {
    background-image: url("../../img/sidebar/announcement.svg");
  }

  &.manage-categories {
    background-image: url("../../img/tree-structure.svg");
  }

  &.schedule-event {
    background-image: url("../../img/event-sidebar.svg");
  }

  &.goals {
    background-image: url("../../img/goal-black.svg");
  }

  &.create-goal {
    background-image: url("../../img/goal-black.svg");
  }

  &.users {
    background-image: url("../../img/users-avatar.svg");
  }

  &.account-info {
    background-image: url("../../img/shield-black.svg");
  }

  &.icon.groups {
    background-image: url("../../img/school-black.svg");
  }

  &.events {
    background-image: url("../../img/calendar-sidebar.svg");
  }

  &.schedule-conference {
    background-image: url("../../img/conference.svg");
  }

  &.badges,
  &.create-badge {
    background-image: url("../../img/i-badge-black.svg");
  }

  &.icon.manage-locations {
    background-image: url("../../img/location.svg");
  }

  &.icon.review-challenges {
    background-image: url("../../img/sidebar/review.svg");
  }

  &.icon.progress-tracking {
    background-image: url("../../img/sidebar/compliance.svg");
  }

  &.icon.leaderboards {
    background-image: url("../../img/sidebar/leaderboards.svg");
  }

  &.icon.learner-access {
    background-image: url("../../img/key-black.svg");
  }
}

.page.dashboard-layout {
  margin-left: 255px;

  margin-top: 50px;
  padding: 0 20px 0 20px;
  position: relative;
  height: calc(100vh - 50px);

  &.hideLearnerSearch {
    #sidebar.sectioned {
      padding-top: 0 !important;
    }

    .quickActionEdit {
      top: 15px;
    }
  }

  &.action-panel-out {
    #sidebar {
      button {
        pointer-events: none;
      }
    }
  }

  .quickActionEdit {
    position: absolute;
    top: 94px;
    z-index: 5;
    left: -85px;

    .icon {
      width: 15px;
      height: 15px;
      margin-right: 5px;

      &.gear {
        background-image: url("../../img/gear.svg");
      }

      &.check {
        background-image: url("../../img/check-blue.svg");
      }
    }
  }

  &.search-active {
    .overlay {
      opacity: 0.35;
      pointer-events: all;
    }
  }

  .masonry-column {
    margin-left: -20px;
  }

  .dashboard-widget {
    margin: 20px;
    background-clip: padding-box;
    background-color: white;
    border-radius: 8px;

    .category-icon {
      border: 3px solid white;
      margin: 0;
    }

    .icon {
      &.spirit {
        background-image: url("../../img/spirit.svg");
        height: 50px;
        width: 100%;
        margin: 10px 0 0;
      }

      &.calendar {
        background-image: url("../../img/calendar-happy.svg");
        height: 50px;
        width: 100%;
        margin: 10px 0 0;
      }

      &.lightbulb {
        background-image: url("../../img/idea.svg");
      }
    }

    header {
      text-transform: uppercase;
      padding: 10px;
      font-size: 12px;
      text-align: center;
      font-weight: bold;
    }

    footer {
      border-top: 1px solid $light-grey;
      padding: 5px;
      display: flex;
      justify-content: center;
    }

    .inner {
      padding: 10px;

      > div {
        pointer-events: all !important;
      }
    }
  }

  .overlay {
    z-index: 6;
    opacity: 0;
    position: fixed;
    pointer-events: none;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: black;
  }

  #sidebar.sectioned {
    margin-top: 0px;
    padding-top: 80px !important;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 17%);

    .quick-action-icon:after {
      width: 15px;
      height: 15px;
      top: -3px;
      left: 15px;
    }
  }
}

#learner-search-popover {
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  display: inline-block;
  position: absolute;
  opacity: 0;
  transition: 0.4s;
  overflow: hidden;
  left: -250px;
  width: 245px;
  top: 50px;
  z-index: 10;

  &.active {
    top: 70px;
    opacity: 1;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  .userInfo {
    display: flex;
    flex-direction: column;

    span {
      opacity: 0.6;
      text-transform: uppercase;
      font-size: 12px;
    }
  }

  li {
    list-style-type: none;

    a {
      padding: 15px;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: 0.3s;
      background-color: white;
      color: black;

      > div {
        margin-right: 0 !important;
        width: 100%;
      }

      .button-subgroups {
        display: none;
      }

      span {
        font-size: 9px !important;
        flex: 1;
        display: block;
      }

      strong {
        font-size: 14px !important;
      }

      &:hover {
        background-color: darken($light-grey, 5%);
      }
    }
  }

  .userPicture {
    margin-left: 0;
    margin-right: 10px;
  }
}

.userPicture {
  display: block;
  height: 35px;
  width: 35px;
  margin-left: 0;
  margin-right: 10px;
}

#learnerSearch {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 50px;
  width: 235px;
  padding: 20px 10px;

  .loadingWrapper {
    position: absolute;
    right: 10px;
    top: 7px;

    .ball-clip-rotate > div {
      width: 20px;
      height: 20px;
    }
  }

  .MuiFormControl-root {
    background-color: $light-grey;
    width: 100%;
    border-radius: 50px;
    margin-bottom: 0;
    .icon.teacher {
      background-image: url("../../img/teacher.svg");
      width: 20px;
      left: 15px;
      position: absolute;
      z-index: 6;
      top: -2px;
      bottom: 0;
    }

    .MuiInputBase-root {
      padding: 5px 10px 5px 45px;
      font-style: italic;

      &:before,
      &:after {
        display: none;
      }
    }
  }
}

.widget-support {
  ul {
    margin-left: 10px;
    list-style-type: none;

    li {
      margin-right: 10px;

      .MuiButton-label {
        color: black;
      }

      .button {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        text-align: left;
        color: black;
        text-transform: none;
        font-size: 14px;
        color: black !important;

        &:hover {
          background-color: $light-grey;
        }

        a {
          color: black;
          text-transform: none;
          flex: 1;
          font-size: 14px;
          width: 100%;
        }
      }
    }
  }
}

.widget-challenges {
  .userPicture {
    flex-shrink: 0;
  }

  .challenge-widget-inner {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    cursor: pointer;
    color: black;

    &:hover {
      background-color: lighten($light-grey, 4%);
    }

    .icon {
      width: 20px;
      height: 20px;

      &.review {
        background-image: url("../../img/review.svg");
      }
    }
  }

  .loadingWrapper {
    color: black;
  }

  .userInfo {
    strong {
      font-size: 10px;
    }

    span {
      font-weight: bold;
      font-size: 10px;
      text-transform: capitalize;
    }
  }
}

.widget-tips {
  p {
    font-size: 12px;
  }

  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }
  .inner {
    div div {
      background: transparent;
    }
  }
  .image {
    display: block;
    width: 100%;
    background-size: cover;
    background-position: center;
    height: 150px;
    overflow: hidden;
    border-radius: 8px;
  }

  .icon.lightbulb {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }
}

.icon.lightbulb {
  background-image: url("../../img/idea.svg");
}

#new-idea-modal {
  .MuiPaper-root {
    min-width: 768px;
    border-radius: 12px;
    padding: 35px;

    h2 {
      justify-content: center;

      .icon.light-bulb {
        width: 20px;
        height: 20px;
      }
    }
  }
}

#edit-org-modal {
  .MuiPaper-root {
    max-width: 800px;
    overflow: auto;
  }
  .modal-container {
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .user-label-user-image-container {
    border: 4px solid white;
    margin-top: -10px;
  }
  .MuiFormControl-root {
    margin-bottom: 15px;
  }
}
