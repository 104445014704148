@import "variables";

.card-preview {
  position: relative;

  .star {
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    margin: 0px 1px;

    &:last-child {
      margin-right: 0;
    }
  }

  &:before,
  &:after {
    width: 60px;
    content: "";
    position: absolute;
    top: 10px;
    display: block;
    bottom: 9px;
  }

  &:before {
    left: 0px;
    z-index: 1;
    background: linear-gradient(
      90deg,
      rgba(240, 240, 240, 1) 0%,
      rgba(240, 240, 240, 0) 100%
    );
  }

  &:after {
    right: 0px;
    background: linear-gradient(
      -90deg,
      rgba(240, 240, 240, 1) 0%,
      rgba(240, 240, 240, 0) 100%
    );
  }
}

.course-level {
  display: flex;
  align-items: center;

  .level {
    margin-right: 5px;
    display: inline-block;
  }
}

@mixin collapsedCard {
  h2 {
    margin-top: 0px;
    transform: scale(0.8);
    height: 54px;
  }

  .course-details {
    padding: 15px;
    bottom: 0;
  }

  .course-label {
    padding-left: 20px;
  }

  .category-icon-wrapper {
    width: 20px !important;
    height: 20px !important;
    top: 12px;
    left: 10px;
    right: auto;

    .category-icon {
      height: 20px !important;
      width: 20px !important;
    }
  }
}

.card-assessment {
  position: relative;

  .inner {
    display: flex;
    align-items: center;

    .userPicture {
      margin-left: 0;
      margin-right: 10px;
      width: 30px;
      height: 30px;
      background-size: cover;
    }
  }

  &.grading-in-progress {
    .in-progress-indicator {
      position: absolute;

      right: 0;
      left: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;

      span {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        border-radius: 0 0 12px 12px;
        font-size: 12px;
        text-transform: uppercase;
        background: black;
        color: white;
        padding: 5px 0;
        font-weight: bold;
        text-align: center;
      }
    }

    .card-image,
    footer,
    .MuiCardContent-root {
      opacity: 0.35;
    }
  }
}

.card.small {
  position: relative;
  flex-shrink: 0;
  width: 250px;
  height: 250px;
  top: 0;
  transition: all 0.3s !important;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.15);
  margin: 0 10px;

  .course-level {
    position: absolute;
    top: 12px;
    right: 10px;

    .star {
      background-image: url("../img/star-white.svg") !important;
    }
  }

  .category-icon {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
    transition: 0.4s;
    margin: 0;
    width: 50px !important;
    height: 50px !important;
    display: block;
    border-radius: 100%;
  }

  &:not(.card-assessment) {
    &:hover {
      @include collapsedCard;
    }
  }

  .MuiCardContent-root {
    position: relative;
  }

  header {
    margin-bottom: 0;
  }

  .card-image {
    max-height: none;
    position: absolute;
  }

  h2 {
    margin-top: 90px;
    font-size: 18px;
    color: white;
    text-align: center;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.77);
    transition: 0.4s;
    line-height: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: scale(1);

    @media (max-width: $screen-md-max) {
      font-size: 16px;
      line-height: 18px;
      margin-top: 80px;
    }
  }

  .course-label {
    padding-left: 0;
    transition: 0.4s;
    font-size: 10px;
    color: white;
    font-weight: normal;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .category-icon-wrapper {
    top: 60px;
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    transition: 0.4s;

    @media (max-width: $screen-md-max) {
      transform: scale(0.8);
      margin-top: -10px;
    }
  }

  .course-details {
    display: block;
    background-color: white;
    height: 135px;
    position: absolute;
    top: auto;
    bottom: -150px;
    left: 0;
    right: 0;
    transition: 0.4s;
    padding: 0;

    p {
      margin: 0 0 10px;
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.card-image {
  background-size: cover;
  background-position: center center;
  transition: height 0.4s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $blue;

  // Microsoft
  &[data-bg="one note"] {
    background-color: #823264;
  }

  &[data-bg="sway"] {
    background-color: #18986e;
  }

  &[data-bg="teams"] {
    background-color: #302c89;
  }

  &[data-bg="gmail"] {
    background-color: #3fa5ff;
  }

  &[data-bg="digital citizenship"] {
    background-color: #00284b;
  }

  &[data-bg="powerpoint"] {
    background-color: #fa630b;
  }

  &[data-bg="21st century learning"] {
    background-color: #296dca;
  }

  &[data-bg="microsoft forms"] {
    background-color: #178174;
  }

  &[data-bg="accessibility"] {
    background-color: #6296cc;
  }

  &[data-bg="microsoft certification"] {
    background-color: #44cdff;
  }

  &[data-bg="flip"] {
    background-color: #ef17e7;
  }

  &[data-bg="chrome + extensions"] {
    background-color: #44a1be;
  }

  &[data-bg="digital learning"] {
    background-color: #44b391;
  }

  &[data-bg="more google"] {
    background-color: #b8b8b8;
  }

  &[data-bg="curriculum + instruction"] {
    background-color: #a79d5a;
  }

  &[data-bg="samr"] {
    background-color: #af6e7f;
  }

  &[data-bg="new teachers"] {
    background-color: #ecc32e;
  }

  &[data-bg="policies + procedures"] {
    background-color: #da525d;
  }

  &[data-bg="district"] {
    background-color: #6fbad1;
  }

  &[data-bg="technology"] {
    background-color: #393939;
  }

  &[data-bg="school"] {
    background-color: #6fb37f;
  }

  &[data-bg="general"] {
    background-color: #ed2061;
  }

  &[data-bg="zoom"] {
    background-color: #3071c9;
  }

  &[data-bg="seesaw"] {
    background-color: #8acfe1;
  }

  &[data-bg="sites"] {
    background-color: #3243a3;
  }

  &[data-bg="meet"] {
    background-color: #057166;
  }

  &[data-bg="google"] {
    background-color: #b8b8b8;
  }

  &[data-bg="mobilemind"] {
    background-color: #8acbe0;
  }

  &[data-bg="docs"] {
    background-color: #4486f4;
  }

  &[data-bg="classroom"] {
    background-color: #38761d;
  }

  &[data-bg="mobilemind hq"] {
    background-color: #6f7071;
  }

  &[data-bg="calendar"] {
    background-color: #2e5ecc;
  }

  &[data-bg="slides"] {
    background-color: #ffb942;
  }

  &[data-bg="drive"] {
    background-color: #555;
  }

  &[data-bg="youtube"] {
    background-color: #b00002;
  }

  &[data-bg="earth + beyond"] {
    background-color: #669ff7;
  }

  &[data-bg="certification info"] {
    background-color: #2f3b7e;
  }

  &[data-bg="sheets"] {
    background-color: #109d58;
  }

  &[data-bg="forms"] {
    background-color: #7248bc;
  }
}

.modal.course-card-preview {
  .MuiDialog-paper {
    border-radius: 6px;
    background: $light-grey;
  }
}
