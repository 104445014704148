@import "variables";

.page.profile {
  padding-top: 100px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  flex-direction: column;
  max-width: 600px;
  .bio {
    label {
      top: -5px;
    }
  }

  .profileContent {
    transition: opacity 0.4s;
    opacity: 1;
  }

  .profileWrapper {
    padding: 20px;
    transition: 0.4s;
    position: relative;

    @media (max-width: $screen-sm-max) {
      flex-direction: column;
    }

    &.locked {
      .profilePictureWrapper,
      .profileContent {
        opacity: 0.2;
        pointer-events: none;
      }
    }

    .loader {
      left: 0;
      right: 0;
      position: absolute;
      pointer-events: none;
    }
  }

  .button {
    animation: fadeInUp 0.4s;
  }

  .saved {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: fadeOutDown 1s;
    animation-delay: 1s;
    font-size: 14px;
    font-weight: 700;

    .check {
      display: block;
      background-image: url("../img/i-check.svg");
      margin: 10px;
      width: 50px;
      height: 50px;
      background-size: cover;
      border-radius: 100%;
    }
  }

  h2 {
    margin-bottom: 20px;
    margin-top: 10px;
    font-size: 24px;
    font-weight: 400;

    @media (max-width: $screen-sm-max) {
      text-align: center;
      margin-top: 40px;
    }
  }

  .MuiInputBase-root {
    margin: 10px 0 40px;
  }

  .MuiInputBase-input[type="text"] {
    border-bottom: 0px;
    margin-bottom: 0px;
    height: 35px;
    width: 250px;
  }

  .flexRow {
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;

    @media (max-width: $screen-sm-max) {
      align-items: center;
    }
  }

  form {
    @media (max-width: $screen-sm-max) {
      footer {
        display: flex;
        justify-content: center;
      }
    }
  }

  .profileForm {
    display: flex;
    flex-direction: column;

    footer {
      display: flex;
      justify-content: center;
    }
  }

  .profileCard {
    border-radius: 12px;
    background-color: white;
    box-shadow: 1px 4px 5px 0 rgba(0, 0, 0, 0.4);
    margin-top: -10px;
    width: 100%;
    .icon.school {
      width: 30px;
      height: 30px;
      background-image: url("../img/school.svg");
      box-shadow: 1px 4px 5px 0 rgba(0, 0, 0, 0.4);
      border: 3px solid white;
      border-radius: 30px;
    }
    ul {
      margin: 0;
      margin-bottom: 15px;
    }
    li {
      padding: 10px 20px;
      border-bottom: 1px solid #b3b3b3;
      &:first-child {
        border-top: 1px solid #b3b3b3;
      }
    }
    h3 {
      margin: 0;
      margin-bottom: 0;
      padding: 20px;
      color: black;
      text-transform: none;
    }
  }

  .profilePictureWrapper {
    cursor: pointer;
    position: relative;
    transition: opacity 0.4s;
    opacity: 1;

    @media (max-width: $screen-sm-max) {
      margin-right: 0px;
      margin-bottom: 20px;
    }

    img {
      opacity: 0.2;
    }

    .profilePicture {
      height: 200px;
      width: 200px;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 100%;
      margin: 0;
      background-position: center;

      &.userPicture {
        border: 6px solid white;
        box-shadow: 1px 4px 5px 0 rgba(0, 0, 0, 0.4);
      }
    }

    .border {
      width: 206px;
      height: 206px;
      position: absolute;
      left: 0px;
      top: 1px;
      background-image: url("../img/dotted-circle.svg");
      background-size: 100%;
    }

    .tooltip {
      .button {
        margin-top: 0px;
      }

      text-align: center;
      font-weight: 600;
      margin-top: 15px;
      right: 0;
      left: 0;
      position: absolute;
      color: #686868;
      font-size: 12px;
      line-height: 14px;
    }

    .fileUpload {
      position: relative;
      transform: scale(1);
      transition: 0.4s;

      &.isDragging {
        transform: scale(1.1);

        .border {
          animation: rotate 22s linear infinite;
        }
      }

      &.isUploading {
        transform: scale(1.1);

        .border {
          animation: rotate 8s linear infinite;
        }
      }

      .tooltip {
        width: 200px;
        text-align: center;
        font-size: 18px;
        line-height: 20px;
        position: absolute;
        bottom: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        flex-direction: column;

        span {
          font-weight: 400;
          color: #686868;
          font-size: 12px;
          line-height: 12px;
          display: inline-block;
          margin-top: 10px;
        }
      }

      input {
        cursor: pointer;
        z-index: 5;
        width: 200px;
        height: 200px;
        opacity: 0;
        position: absolute;
      }
    }
  }
}

.service {
  padding-bottom: 80px;
  .button {
    z-index: 2;
    pointer-events: all;
  }
  .date {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    border-radius: 12px;
    background-color: white;
    font-weight: bold;
    margin-left: 5px;
    border-left: 7px solid transparent;

    .red {
      color: #bf4040;
    }

    .green {
      color: #24dc91;
    }
  }

  .icon.generate-token {
    background-image: url("../img/token-hand.svg");
  }

  .instruction {
    max-width: 800px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin: 0 auto;

    .MuiPaper-root {
      border-radius: 12px;
      border: none;
    }

    .MuiTypography-root {
      color: black;
      font-family: "Nunito";
    }

    h6 {
      font-weight: bold;
    }

    .postman {
      width: 700px;
      background-size: contain;
      background-repeat: no-repeat;
      margin-bottom: 20px;

      &.request {
        height: 220px;
        background-image: url("../img/postman-request.png");
      }

      &.response {
        height: 100px;
        background-image: url("../img/postman-response.png");
      }
    }
  }
}
