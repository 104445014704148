.quote-bubble {
  position: relative;
  font-style: italic;

  .triangle {
    position: absolute;
    content: "";
    display: block;
    top: -8px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid;
  }
}
