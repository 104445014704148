@import "variables";

.icon.review.black {
  background-image: url("../img/review-black.svg");
}

#sidebar {
  position: fixed;
  bottom: 0;
  left: 0;
  top: 50px !important;
  background-color: white;
  width: 220px;
  padding: 25px 15px;
  overflow: auto;
  overflow-x: hidden;

  button.large {
    min-width: 175px;
    padding: 8px 10px;
    height: auto !important;
    top: auto;
    left: auto;
    right: auto;
    width: 220px;
  }

  .categorySelectFormControl {
    margin-bottom: 15px;

    .category-icon-wrapper.display {
      position: absolute;
      top: 15px;
      left: 0px;

      .category-icon {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }

  &.sessionFull {
    padding-top: 0 !important;
  }

  .MuiAutocomplete-root {
    .MuiAutocomplete-inputRoot {
      padding-right: 0px;

      input {
        padding-right: 20px;
      }
    }

    .MuiFormControl-root {
      margin-bottom: 0 !important;
    }
  }
}

.required {
  position: relative;

  h3 {
    position: relative;
  }

  h3,
  div {
    z-index: 3;
  }

  .inputSelect {
    width: 100%;
  }

  .MuiFormLabel-root,
  p {
    // This breaks the tooltip in the course title textfield. Seems like pointer-events should always be enabled
    // on elements in form controls that expect pointer events.
    // pointer-events: none;
    z-index: 4;
  }

  p {
    position: relative;
    z-index: 4;
  }

  &:after {
    pointer-events: none;
    opacity: 0;
    transition: 0.6s;
    border-radius: 6px;
    content: "";
    position: absolute;
    top: -10px;
    bottom: -10px;
    right: -10px;
    left: -10px;
    background: #e2e883;
  }

  &.active {
    &:after {
      opacity: 1;
    }
  }

  &.goal {
    &:after {
      bottom: 15px;
    }
  }
}
.MuiDateCalendar-root {
  height: auto;
}
.MuiPickersYear-root .Mui-disabled {
  display: none;
}

.page.analytics.reports {
  padding-top: 75px !important;
  .filters input {
    padding: 10px 0px;
    &.Mui-readOnly {
      border-bottom: none;
    }
  }
}
.filters {
  width: 220px;
  position: relative;
  opacity: 1;
  left: 0;
  transition: 0.6s;

  .icon {
    position: absolute;
    width: 12px;
    height: 12px;
    top: 27px;
    &.learningPath {
      background-image: url("../img/learning-path-black.svg");
    }
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 33px;

    strong {
      text-transform: uppercase;
      font-weight: 700;
      flex: 1;
      font-size: 12px;
    }
  }

  input {
    margin: 0;
    padding-left: 20px;
    border-bottom: 1px solid #9e9e9e;
  }

  .MuiTextField-root .MuiInput-underline:before {
    display: none;
  }

  .inputSearch .MuiFormLabel-root {
    transform: translate(20px, 25px);

    &.MuiInputLabel-shrink {
      transform: translate(0, 5px) scale(0.75);
    }
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .inputSearch,
  .inputSelect {
    margin-bottom: 25px;
  }
}

#menu- .MuiMenu-list {
  .loadingWrapper {
    top: 60px;
    margin-left: -30px;
    transform: scale(0.45);
  }
}

.inputSelect.attract {
  .MuiInputBase-root {
    position: relative;
    padding-left: 15px;

    label#learning-path-select-label {
      color: rgba(0, 0, 0, 0.6) !important;
    }

    label.MuiInputLabel-shrink,
    .Mui-focused {
      color: rgba(0, 0, 0, 0.6) !important;
    }

    &:after {
      content: "";
      z-index: -1;
      background-color: lighten($green, 25%);
      transform: none;
      position: absolute;
      left: -10px;
      right: -10px;
      top: -10px;
      bottom: -5px;
      border-radius: 12px;
      border-bottom: none;
      display: block;
    }
  }
}

#tabs-course-list,
#tabs-assessment-list {
  position: fixed;
  left: 0;
  z-index: 20;
  width: 285px;
  top: 50px;
  width: 285px;

  .MuiTab-root {
    text-transform: none;
    min-width: 50%;
    flex: 1;
    font-size: 14px;
    font-weight: normal;
    background-color: $light-grey;
  }

  .MuiTab-root[aria-selected="true"] {
    background-color: white;
  }

  .MuiTabs-indicator {
    display: none;
  }
}

#sidebar.sectioned {
  padding-top: 3px !important;

  .button.primary {
    background-color: $green;
  }

  span.count {
    background-color: #f0f0f0;
    color: black;
    border-radius: 7px;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    padding-top: 2px;
    font-weight: 700;
    transition: 0.15s;
  }

  .icon.event {
    background-image: url("../img/event-sidebar.svg");
  }

  .icon.pending {
    background-image: url("../img/external-pending.svg");
  }

  .icon.reviewed {
    background-image: url("../img/reviewed.svg");
  }

  .icon.feedback-form {
    background-image: url("../img/feedback-form.svg");
  }

  .icon.recommended {
    background-image: url("../img/recommended.svg");
  }

  .icon.archived {
    background-image: url("../img/i-archive-black.svg");
  }

  .icon.calendar {
    background-image: url("../img/calendar-sidebar.svg");
  }
}

.icon.pre-approval-form {
  background-image: url("../img/pre-approval-form.svg");
}

.view-in-learn .button.large {
  background-color: $blue !important;
  color: white !important;
  &:hover {
    background-color: darken($blue, 8%) !important;
  }
}
