@import "./variables.scss";

.notification-popup {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 60px;
  width: 350px;
  overflow: hidden;
  padding-bottom: 20px;
  padding-left: 20px;

  &.slideOut .inner {
    transition: 1.5s;
    opacity: 0;
  }

  .icon {
    width: 35px;
    height: 35px;
    flex-shrink: 0;
    margin-right: 15px;
    background-repeat: no-repeat;

    &.assessment_feedback {
      background-image: url("../img/feedback.svg");
    }

    &.assessment_complete {
      background-image: url("../img/i-check.svg");
    }

    &.reviewer {
      background-image: url("../img/review.svg");
    }
  }

  p {
    margin: 0;
    line-height: 16px;
    font-size: 12px;
  }

  .inner {
    color: black;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
    padding: 15px;
    opacity: 1;
    transition: 0.3s;
    animation: slideInDown forwards 0.5s;
    background-color: white;
    border-radius: 6px;
    border-left: 5px solid rgba(0, 0, 0, 0);

    &:hover {
      border-left: 5px solid $blue;
    }
  }
}

#notification-menu {
  .MuiPopover-paper {
    width: 350px;
    border-radius: 6px;
    overflow: visible;
    background-color: rgba(0, 0, 0, 0) !important;
    top: 40px !important;
    right: 8px;
    left: auto !important;

    &:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid white;
      position: absolute;
      top: -8px;
      right: 15px;
    }
  }

  .message-empty {
    background-color: white;
    padding: 15px;
    border-radius: 6px;
    font-size: 12px;
    text-align: center;
    font-weight: 700;
  }

  footer {
    padding: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -33px;
    background-color: white !important;
    border-radius: 0 0 6px 6px;
    border-top: 1px solid #f0f0f0;

    &:hover {
      background-color: white;
    }

    .button {
      text-transform: capitalize;
      width: 100%;
    }
  }

  .MuiMenu-list {
    padding: 0;
  }

  .notifications-list {
    border-radius: 6px 6px 0 0;
    overflow: hidden;
    padding: 0px;
    margin: 0;
    max-height: 250px;
    overflow-y: auto;
    position: relative;
    margin-bottom: 33px;
    background-color: white;

    &.empty {
      margin-bottom: 0px;
    }

    &:focus {
      outline: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      padding: 0px 10px 0 0;
      background-color: white;
      transition: 0.3s;
      min-height: 0px;
      cursor: pointer;
      position: relative;

      .notification-item {
        display: block;
        display: flex;
        align-items: center;
        color: black;
        padding: 5px 30px 5px 15px;
        transition: 0.3s;
        position: relative;
        right: 0;
        height: 55px;
        overflow: hidden;
        flex: 1;
        margin-right: -10px;
        border-bottom: 1px solid #f0f0f0;

        &.new {
          padding-left: 10px;
          border-left: 5px solid $green;
        }

        &.removed {
          right: 150px;
          padding: 0 !important;
          height: 0;
          border-bottom: none;

          .icon.remove {
            display: none !important;
          }
        }
      }

      .timestamp {
        color: #888;
        font-weight: 700;
        font-size: 10px;
        margin-top: 2px;
      }

      &:hover {
        background-color: #f0f0f0;
      }

      .content {
        white-space: pre-line;
        padding-top: 5px;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        margin-right: 5px;
        flex-direction: column;
      }

      .icon {
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        flex-shrink: 0;
        background-repeat: no-repeat;

        &.assessment_complete {
          background-image: url("../img/i-check.svg");
        }

        &.assessment_feedback {
          background-image: url("../img/feedback.svg");
        }

        &.ulp_complete {
          background-image: url("../img/learning-path.svg");
        }

        &.user_goal_complete {
          background-image: url("../img/goal.svg");
        }

        &.user_badge_created {
          background-image: url("../img/i-badge.svg");
        }

        &.event_personnel {
          background-image: url("../img/calendar-notification.svg");
        }

        &.check {
          background-image: url("../img/i-check.svg");
        }

        &.remove {
          background-image: url("../img/x.svg");
          transform: scale(0.8);
          transition: 0.3s;
          opacity: 0.5;
          display: block;
          position: absolute;
          right: 0px;
          width: 25px;
          height: 25px;
          background-size: contain;
          background-repeat: no-repeat;

          &:hover {
            opacity: 1;
            transform: scale(1);
          }
        }
      }
    }
  }
}

.notifications {
  background-image: url("../img/bell.svg");
  width: 25px;
  height: 25px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  transform: scale(1);
  transition: 0.3s;
  position: relative;

  &.swing {
    transform-origin: top center;
    animation: swing 1s;
  }

  &:hover {
    transform-origin: center;
    transform: scale(1.2);
  }

  .count {
    background-color: $green;
    box-sizing: border-box;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    color: black;
    font-size: 10px;
    padding-top: 1px;
    position: absolute;
    right: -3px;
    top: -2px;
    font-weight: 700;
    transition: 0.5s;
    transform: scale(1);
    opacity: 1;
    margin-right: 0 !important;

    &.fade {
      transform: scale(0.5);
      opacity: 0;
    }
  }
}
