@import "variables";

.page.hasSidebar {
  padding-left: 315px;
}

.page-review {
  max-width: 1340px;

  .MuiPaper-rounded {
    border-radius: 12px !important;
  }
}

#reviewer-instructions-modal {
  .MuiPaper-root {
    border-radius: 12px;
    padding: 20px;

    header {
      font-weight: bold;
      font-size: 18px;
    }
  }
}

#pdf-revision-container {
  height: 70vh;
}

#sidebar {
  padding-top: 65px;
  width: 255px;

  .filters {
    width: 245px;

    .MuiOutlinedInput-notchedOutline {
      display: none;
    }
  }
}

.assigned-course-modal {
  .MuiPaper-root {
    min-width: 90vw;
    border-radius: 12px;

    footer {
      padding: 0;
      margin-top: 20px !important;
    }
  }
}

.reviewer-popover {
  margin: 0 0 0 20px;

  .MuiPaper-root {
    border-radius: 12px;
  }
}

ul.popover-list {
  list-style-type: none;
  border-radius: 6px;
  padding: 8px 10px;
  margin: 0;

  li {
    margin: 0 !important;
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    font-weight: bold;
    border: 0px;
    padding: 8px 5px;
    font-size: 14px;

    .userPicture,
    .icon.reviewer-group {
      margin: 0;
      width: 25px;
      height: 25px;
      border: 3px solid white;
      border-radius: 25px;
      margin-right: 10px;
    }

    .icon.reviewer-group {
      border-radius: 0;
      overflow: visible;
      background-image: url("../img/reviewer-group.svg");
    }
  }
}

.assigned-course-list {
  list-style-type: none;
  margin: 20px 0 0;
  overflow: auto;
  padding: 25px 0;
  max-height: 50vh;

  li {
    padding: 0 15px 0 0;
    margin: 0;
    border-bottom: 1px solid $light-grey;
    position: relative;

    .categoryImage {
      width: 30px;
      height: 30px;
      border-radius: 30px;
      border: 3px solid white;
    }

    &.fade {
      .flexRow {
        opacity: 0.2;
      }
    }

    .flexRow {
      padding: 10px 20px;
      font-size: 14px;
      display: flex;
      margin: 0 -10px;
      align-items: center;
    }

    .prompt {
      padding: 10px 20px;
      position: absolute;
      top: 0;
      right: 5px;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      background-color: white;
      z-index: 2;
      animation: fadeIn 0.3s forwards;

      em {
        font-size: 14px;
        flex: 1;
      }
    }

    .title {
      font-weight: bold;
      flex: 1;
      display: flex;
      align-items: center;
    }

    .reviewerCount {
      width: 125px;
      position: relative;
      margin-left: 10px;
      text-align: center;

      button {
        display: inline-flex;
        align-items: center;
        font-weight: bold;
        background-color: white;
        box-shadow: none;
        padding: 8px !important;
        transition: 0.4s;
        min-width: 20px;
        border-radius: 5px;
        color: $blue !important;

        &:hover {
          background-color: $light-grey;
        }
      }

      .icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        background-image: url("../img/review.svg");
      }
    }

    .action {
      width: 115px;
      justify-content: flex-end;
      display: flex;
    }

    .created {
      width: 150px;
    }

    &:first-child {
      position: absolute;
      background: white;
      left: 20px;
      right: 20px;
      margin-top: -35px;
      z-index: 1;

      .flexRow {
        margin: 0 !important;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 10px !important;
      }

      &:hover {
        background-color: white;
        cursor: inherit !important;
      }
    }
  }
}

.counter {
  color: #333;
  border-bottom: 0px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;

  button {
    padding: 5px;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    width: 35px;
    height: 35px;
    min-width: 15px;
    display: flex;
    text-align: center;
    margin-right: 10px;

    .icon.sort {
      position: static;
      margin-right: 0;
      margin-left: 0;
    }
  }

  span {
    margin-right: 10px;
    font-size: 28px;
    text-transform: none;
    font-weight: 700;
    color: #59c3e5;
  }
}

.scrollContainer {
  display: flex;
  justify-content: center;
  width: 100%;

  p {
    background-color: lighten($green, 25%);
    padding: 15px;
    border-radius: 12px;
  }
}

.URL-help {
  display: flex;
  justify-content: center;
  position: absolute;
  align-items: center;
  right: 20px;
  top: 20px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px 0px;
  border-radius: 12px;
  padding: 5px 15px;
  font-size: 14px;
  transition: 0.3s;
  color: black;
  opacity: 0;
  animation: fadeInUp 0.4s forwards;
  animation-delay: 0.6s;

  &:hover {
    background-color: $light-grey;
  }

  .icon {
    background-image: url("../img/broken.svg");
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }
}

.card-grid {
  padding: 20px 0;

  .MuiGrid-item {
    max-width: 270px;
    position: relative;
    cursor: pointer;

    .button-subgroups,
    .button.small {
      display: none;
    }

    header .icon.status {
      width: 25px;
      height: 25px;

      &.laptop {
        background-image: url("../img/laptop.svg");
      }

      &.check {
        background-image: url("../img/i-check.svg");
      }
    }

    .category-icon {
      border-radius: 100%;
      border: 4px solid white;
    }

    .user-label-user-name {
      font-size: 14px !important;
    }

    .submitted-date {
      color: black;
      background-color: white;
      padding: 5px 10px;
      text-transform: none;
      font-size: 12px;
      border-radius: 20px;
      font-weight: bold;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px 0px;
      position: relative;
      right: -5px;
    }

    .card-assessment {
      .category-icon-wrapper {
        top: 50px;
      }

      h2 {
        margin-top: 85px;
        font-size: 16px;
        line-height: 18px;
      }
    }

    span,
    .user-label-user-name {
      text-wrap: nowrap;
    }

    footer {
      overflow: hidden;
      background-color: white;
      position: absolute;
      text-align: left;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 12px;
      font-size: 14px;
      font-weight: 700;
    }

    .userInitials-initials {
      font-size: 14px;
      position: relative;
      top: -2px;
    }

    .user-label-user-image-container {
      > div > div {
        border-width: 3.5px !important;
        width: 35px !important;
        height: 35px !important;
      }
    }

    .user-has-image {
      width: 30px !important;
      height: 30px !important;
      border: 3px solid white;
      margin-left: 0px;
    }

    footer .inner {
      position: relative;
      transition: 0.4s;
      top: 0;
      width: 100%;

      .icon.status {
        width: 20px;
        height: 20px;
        position: absolute;
        right: 0px;
      }

      .icon.check {
        background-image: url("../img/i-check.svg");
      }

      .icon.laptop {
        background-image: url("../img/laptop.svg");
      }

      .sub {
        white-space: nowrap;
        font-size: 10px;
        opacity: 0.65;
        text-transform: uppercase;
        width: 162px;
        font-weight: normal;
        overflow: hidden;
      }
    }

    &:hover {
      h2 {
        margin-top: 95px;
      }

      .category-icon-wrapper {
        margin-top: 0px;
        transform: scale(1.3);
        transform-origin: center;
      }
    }
  }
}

#reviewer-assessment-modal {
  list-style-type: none;
  padding: 0;
  margin: 15px 0 0 0;

  .close {
    cursor: pointer;
    width: 30px;
    height: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../img/x.svg");
  }

  .modal-header {
    .small.button {
      padding: 10px !important;
      margin-left: 20px;
    }

    .latest-button {
      margin-right: 15px;
      position: relative;
      top: 5px;
    }

    .multi-group {
      margin-bottom: -12px;
    }

    .MuiFormLabel-root {
      color: rgba(0, 0, 0, 0.6) !important;
    }

    .subgroups-popover {
      li {
        box-shadow: none;
      }
    }
  }

  .icon.close {
    margin-right: 0;
    width: 30px;
    height: 30px;
  }

  .navButton {
    opacity: 0.7 !important;
  }

  .user-label-user-image-container {
    > div > div {
      border-width: 3.5px !important;
      width: 55px !important;
      height: 55px !important;
    }
  }

  .reviewer-comments {
    display: flex;
    align-items: flex-start;

    .timestamp {
      margin-top: 16px;
    }

    .reviewer-info {
      display: flex;
    }

    p {
      background-color: lighten($green, 25%);
      padding: 15px;
      border-radius: 12px;
      display: inline-block;
      position: relative;
      max-width: 750px;
      margin-top: -2px;
      &:before {
        position: absolute;
        top: 10px;
        left: -12px;
        content: "";
        display: block;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-right: 15px solid lighten($green, 25%);
      }
    }
  }

  .courseInfo {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .category-icon {
    border: 2px solid white;
  }

  .timestamp {
    font-weight: bold;
    opacity: 0.6;
    text-wrap: nowrap;
    margin-left: 35px;
  }

  .user-has-image {
    width: 50px !important;
    height: 50px !important;
    border: 3.5px solid white;
    margin-left: 0px;
    margin-right: 0px;
  }

  &.fullsize {
    .CarouselItem {
      padding-bottom: 0px;
      height: 80vh;
    }

    .MuiPaper-root {
      margin: 0;
      width: 85vw;
      height: 80vh;
    }

    .scrollContainer {
      overflow: auto;
      flex: 1;
    }

    .img-link {
      opacity: 1;
      transition: 0.3s;
      max-width: 100%;
      display: flex;
      justify-content: center;
      &:hover {
        opacity: 0.8;
      }
    }

    img {
      max-height: 40vh;
      border-radius: 12px;
    }
  }

  .actions-footer {
    background-color: white;
    display: flex;
    align-items: flex-start;
    padding: 25px 30px;
    flex-direction: column;
    max-height: 300px;
    border-top: 1px solid #d5d5d5;
    margin-top: 20px;
    margin-left: -30px;
    margin-right: -30px;
    .instructions-container {
      animation: fadeInUp 0.35s forwards;
      width: 95%;
      max-height: 20vh;
      overflow: auto;
      background-color: lighten($green, 40%);
      padding: 10px;
      border-radius: 12px;
      &::-webkit-scrollbar {
        display: none;
      }
      a {
        font-weight: bold;
        color: darken($blue, 10%);
      }
    }

    .instructions {
      flex-shrink: 0;
      &.learner {
        left: auto;
        right: -10px;

        .icon {
          background-image: url("../img/chat.svg") !important;
        }
      }

      &.isOpen .button.small {
        background-color: $light-grey !important;
      }

      .button.small {
        color: black !important;
        background-color: transparent;

        &:hover {
          background-color: darken($light-grey, 5%) !important;
        }

        .icon {
          position: relative;
          top: -1px;
          background-image: url("../img/guidebook.svg");
        }
      }

      a {
        font-weight: bold;
        color: $blue;
      }
    }

    .button {
      margin-right: 15px;

      &:first-child {
        background-color: $light-grey;
      }

      &:last-child {
        margin-right: 30px;
      }
    }
  }

  .comments-container {
    padding: 10px;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: flex-start;
    width: 100%;
    width: 100%;

    .MuiButtonBase-root {
      position: relative;
      top: 12px;
    }
    textarea {
      max-height: 100px;
    }

    .MuiOutlinedInput-root {
      border-radius: 12px;
      width: 100%;
    }
  }

  footer {
    label {
      margin-left: 20px;
    }

    .MuiTypography-root {
      font-weight: bold;
      padding-left: 5px;
    }

    .button:first-child {
      margin-right: 20px;
      background-color: $light-grey;

      &:hover {
        background-color: darken($light-grey, 5%);
      }
    }
  }

  .MuiPaper-root {
    border-radius: 12px;
    padding: 30px;
    min-width: 85vw;
    max-width: 85vw;
  }

  iframe {
    flex: 1;
    border: none;
    width: 100%;
    border-radius: 12px;
  }

  .category-icon {
    margin-left: 0;
  }
}

.learner-questions-popover {
  position: relative;
  right: -5px;
  top: 5px;
  cursor: pointer;
  + .MuiPaper-root {
    opacity: 0;
    font-size: 14px;
    max-width: 350px;
    padding: 15px;
    font-weight: normal;
    pointer-events: none;
    transition: 0.4s;
    position: absolute;
    z-index: 5;
    left: 150px;
    top: 10px;
  }

  &:hover + .MuiPaper-root {
    opacity: 1;
    left: 165px;
  }
}

.feedback-forms-modal {
  .questionTypeSelect {
    .MuiInputBase-root {
      width: 175px;
    }
    legend {
      height: 20px;
      span {
        opacity: 1 !important;
      }
    }
    input {
      background-color: transparent;
    }
    &.disabled {
      legend {
        display: none;
      }
      fieldset {
        border: none;
      }
      .MuiInputBase-input {
        font-weight: bold;
      }
      .MuiSelect-iconOutlined {
        display: none;
      }
    }
  }
  .icon.info:hover {
    .popover-content {
      opacity: 1;
      margin-top: 5px;
    }
  }
  .popover-content {
    width: 300px;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px 0px;
    position: absolute;
    right: 30px;
    margin-top: 0px;
    border-radius: 12px;
    padding: 15px;
    opacity: 0;
    transition: 0.3s;
    pointer-events: none;
    z-index: 5;
    p {
      margin: 0;
    }
  }
  .icon.caret {
    background-image: url("../img/caret-blue.svg");
    transform: rotate(90deg);
    position: relative;
    left: 7px;
  }
}
.reviewer-groups-modal {
  .icon.reviewer-group {
    background-image: url("../img/reviewer-group.svg");
  }
  .MuiOutlinedInput-root {
    margin-top: 10px;
    border-radius: 12px;
  }

  h2 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 10px 20px 20px;

    .icon.reviewer-group {
      margin-left: 5px;
    }
  }

  .MuiDialog-paperScrollPaper {
    min-width: 65vw;
    border-radius: 12px;
    max-height: 75vh;
    min-height: 75vh;
    padding-top: 65px;
  }

  .MuiAccordionSummary-gutters,
  .MuiAccordionSummary-contentGutters {
    margin: 0;
    padding: 0;
  }

  .newGroupContainer {
    padding: 20px;
    border-radius: 12px;
    justify-content: space-between;

    .button {
      margin-left: 10px;
    }

    input {
      background-color: white;
    }
  }

  .group-members-list {
    list-style-type: none;
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
    margin: 15px -20px;

    .user-item {
      padding: 10px 20px;
      height: 62px;
      border-bottom: 1px solid $light-grey;
      display: flex;
      align-items: center;

      .userInitials-initials {
        font-size: 16px;
        position: relative;
        top: -1px;
      }

      &:first-child {
        border-top: 1px solid $light-grey;
      }
    }
  }

  .icon.reviewer-group {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }

  .icon.caret {
    background-image: url("../img/caret-blue.svg");
    transform: rotate(90deg);
    margin-right: 0px;
    position: relative;
    left: 3px;
  }

  .groups-list {
    list-style-type: none;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 70vh;
    padding-right: 20px;
    margin-right: -40px;
    margin-left: -40px;
    padding-left: 40px;

    li {
      padding: 0px;
      border-top: 1px solid $light-grey;
      margin: 0 -20px;

      &:last-child {
        border-bottom: 1px solid $light-grey;
      }

      button {
        border-radius: 0px;
        width: 100%;
        padding: 20px;
        text-align: left;
        text-transform: none;
        justify-content: flex-start;
        font-size: 18px;
      }
    }
  }
}
