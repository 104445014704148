@import "variables";

.widget {
  padding: 15px 20px 20px;
  background-color: white;
  border-radius: 12px;
  opacity: 0;
  animation: fadeInUp 0.3s forwards;
  width: 370px;
  .timestamp {
    position: absolute;
    right: 20px;
    left: 20px;
    top: 18px;
    font-size: 12px;
    text-align: right;
    opacity: 0.5;
    font-weight: 700;
  }
  h2 {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 700;
  }
  p {
    font-size: 14px;
    line-height: 18px;
  }

  footer {
    display: flex;
    justify-content: flex-end;
    .MuiButton-label {
      font-size: 10px;
    }
  }
}

.widgetLabel {
  background-color: $blue;
  color: white;
  font-weight: 700;
  padding: 3px 6px;
  border-radius: 25px;
  text-transform: uppercase;
  display: inline-block;
  font-size: 10px;
  line-height: 14px;
  position: relative !important;
  z-index: 2;
  &.important {
    background-color: #fd4755;
  }
  &.success {
    color: black;
    display: inline-block;
    background-color: $green;
  }
}

.widget.announcement {
  margin: 10px 0;
  .widgetIcon {
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    top: 20px;
    right: 15px;
  }
  .icon.tip {
    width: 100px;
    height: 100px;
    margin-right: 10px;
    background-size: contain;
    margin-top: 15px;
    background-image: url("../img/tip.svg");
    background-position: center;
    background-repeat: no-repeat;
  }
  .announcementImage {
    margin: 20px 0;
    img {
      width: 100%;
      border-radius: 4px;
      display: block;
    }
  }
  .card-image {
    position: relative;
    padding: 10px 20px;
    margin: 15px -20px;
    padding: 10px 20px 20px;
    .icon.star {
      width: 10px;
      height: 10px;
      margin-right: 3px;
      background-image: url("../img/star-white.svg");
    }
    header {
      margin-top: 10px;
      display: flex;
      align-items: center;
    }
    .course-label {
      font-size: 10px;
      color: white;
      display: flex;
      font-weight: normal;
      text-transform: uppercase;
      justify-content: space-between;
      flex: 1;
    }
    h2 {
      font-weight: 400;
      font-size: 18px;
      color: white;
      justify-content: center;
      margin: 20px 0;
      text-shadow: 0 1px 4px rgba(0, 0, 0, 0.77);
      line-height: 22px;
    }
  }
}

.modal.announcement-preview {
  .MuiDialog-paper {
    max-width: 410px !important;
    border-radius: 6px;
    background: $light-grey;
    p p {
      margin: 0;
    }
  }
}

.modal.learning-path-preview {
  .MuiDialog-paper {
    max-width: 350px !important;
    border-radius: 6px;
    background: $light-grey;
  }
  ul {
    padding: 0;
  }
}

.widget.announcement.featuredLearningPath {
  .card-image {
    height: 200px;
  }
}

.widget.learningPath {
  margin-top: 15px;
  width: 310px;
  .widgetLabel {
    background-color: black;
  }
  .pathStatus {
    font-size: 10px;
    text-transform: uppercase;
  }
  .topContent {
    position: relative;
    height: 125px;
    padding-bottom: 15px;
  }
  p {
    margin: 15px 0;
  }
  .pathImage {
    position: absolute;
    top: -20px;
    right: -20px;
    left: -20px;
    bottom: 0px;
    background-size: cover;
    background-position: center;
    z-index: -1;
  }
  h2 {
    margin: 10px 0 10px;
  }
  .categoryList {
    list-style-type: none;
    padding: 0 0 0 5px !important;
    margin: 15px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px !important;
    .category-icon-wrapper {
      margin: 0 5px 5px 0;
    }
  }
}
