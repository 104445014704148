@import "./variables";

ul {
  padding-left: 0;
}

.pending-list {
  footer {
    display: flex;
    justify-content: center;
    padding: 15px 0 0;
  }

  .reviewer-comments {
    border-top: 1px solid $light-grey;
    border-bottom: 1px solid $light-grey;
    margin-left: -15px;
    margin-right: -15px;
    padding: 10px 15px;

    .quote-bubble {
      display: inline-block;
    }
  }
}

.approval-stepper {
  .icon.check {
    background-image: url("../img/check-black.svg");
  }
  .icon.denied {
    background-image: url("../img/x-white.svg");
  }
  .MuiStepConnector-root {
    min-width: 75px;
  }
}

.read-more-description {
  -webkit-box-orient: vertical;
  &.read-more {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
}

.approval-notes {
  position: relative;
  .triangle {
    color: rgb(189, 245, 222);
    border-bottom-color: rgb(189, 245, 222);
    background-color: transparent;
    position: absolute;
    content: "";
    display: block;
    top: 10px;
    left: -9px;
    width: 0;
    height: 0;
    border-bottom: 10px solid transparent;
    border-top: 10px solid transparent;
    border-right: 10px solid;
  }
}

#xpd-attachment-modal {
  .MuiPaper-root {
    padding: 20px;
    max-width: 95vw;
    max-height: 95vh;

    img {
      max-height: 90vh;
      max-width: 100%;
    }
  }
}

.timeBlockList {
  margin-bottom: 30px;

  &.reviewed {
    pointer-events: none;

    .MuiInput-underline:before {
      display: none;
    }

    svg {
      display: none;
    }
  }

  .MuiFormControl-root {
    margin-right: 15px;
  }

  li {
    margin-bottom: 15px;
    align-items: flex-start;

    .MuiAutocomplete-inputRoot input {
      padding-bottom: 6px !important;
    }

    &:first-child {
      .MuiChip-deleteIcon {
        top: 15px;
      }
    }

    .MuiChip-deleteIcon {
      opacity: 0.3;
      transition: opacity 0.3s;
      position: relative;
      width: 30px;
      height: 25px;
      cursor: pointer;
      top: 2px;
      left: -5px;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.timeBlocks {
  max-width: 45px;
  margin-right: 20px;

  input {
    font-size: 18px;
    font-weight: bold;
  }
}

.pd-attachment-list {
  display: flex;
  flex-wrap: wrap;

  .MuiButtonBase-root {
    border-radius: 50px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: -10px;
    margin-right: 15px;
    text-transform: none;
    font-weight: bold;

    .image {
      border-radius: 6px;
    }

    a {
      color: black;
      display: flex;
      align-items: center;
      text-align: left;
    }
  }

  li {
    display: flex;
    align-items: center;
    font-weight: bold;
    padding: 15px 0;
    border-top: 1px solid $light-grey;

    &:last-child {
      border-bottom: 1px solid $light-grey;
    }

    .icon,
    img {
      margin-right: 10px;
      width: 30px;
      height: 30px;
    }
  }
}

#new-external-event-modal {
  .MuiDialog-paperWidthSm {
    max-height: 90vh;
    max-width: 650px;
    border-radius: 10px;
  }

  .progressBarInner {
    height: 12px;
    border-radius: 30px;
  }

  .MuiFormGroup-root {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    margin: 15px 0;
  }

  .buddy-message {
    margin: 0;

    p {
      margin: 0;
    }
  }

  .card.recommended-event {
    max-width: 260px;
    margin: 0 auto;
    border: none !important;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
      0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;

    .capsule-wrapper {
      bottom: 11px;

      .capsule {
        right: auto;
      }
    }

    header {
      text-transform: none;
      text-align: center;
      line-height: 18px;

      span {
        font-weight: normal;
        opacity: 0.8 !important;
      }

      strong {
        opacity: 1 !important;
        color: black !important;
        font-size: 18px;
        display: block;
        margin-bottom: 5px;
      }
    }
  }

  .uploadLabel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    pointer-events: none;
    left: 0px;
    bottom: 12px;
    right: 0px;
    text-shadow: none !important;

    .camera.white {
      margin: 0 5px 0 0;
      width: 15px;
      height: 15px;
      background-image: url("../img/camera-white.svg");
    }
  }

  .dateTimeSelect {
    align-items: flex-end;

    .MuiInputBase-root.Mui-error {
      margin-bottom: 10px;
    }

    .MuiFormHelperText-root.Mui-error {
      position: absolute;
      pointer-events: none;
      width: 110px;
      bottom: -10px;
    }

    .MuiFormControlLabel-root {
      position: relative;
      top: -11px;
    }

    .MuiFormControl-root {
      max-width: 175px;
      margin-right: 10px !important;

      &.time {
        .MuiIconButton-root {
          display: none;
        }
      }
    }

    &.registrationWindow {
      flex-wrap: wrap;
      margin-top: 10px;

      > div {
        margin-bottom: 10px;

        &:first-child {
          margin-right: 20px;
        }

        .MuiFormControl-root {
          margin: 0;

          &:last-child {
            margin-top: -10px;

            .MuiSvgIcon-root {
              display: none;
            }
          }
        }
      }
    }
  }

  .loadingWrapper {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 90px;
    display: flex;
    justify-content: center;
    z-index: 4;
  }

  &.preview-step {
    .MuiDialog-paperWidthSm {
      max-width: 800px;
    }
  }

  .overview-report {
    margin: 0 -25px;

    .capsule {
      .icon.clock {
        width: 15px;
        margin-left: 0;
        height: 15px;
      }
    }

    .user-list {
      margin-top: 140px;
      padding-bottom: 20px;

      ul {
        margin-top: 0px;
        max-height: 480px;
        overflow: auto;
        padding-bottom: 20px;
      }

      li {
        padding: 10px 25px;
        border-bottom: 1px solid $light-grey;

        &:first-child {
          border-top: 1px solid $light-grey;
        }

        .comment {
          margin-top: 15px;
          margin-left: 1px;
          background-color: rgb(189, 245, 222);
          padding: 10px 15px;
          border-radius: 8px;
          position: relative;
          display: inline-block;
          font-size: 14px;
          font-style: italic;

          p {
            margin: 0;
          }

          .triangle {
            color: rgb(189, 245, 222);
            border-bottom-color: rgb(189, 245, 222);
            background-color: transparent;
            position: absolute;
            content: "";
            display: block;
            top: -8px;
            left: 6px;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid;
          }
        }

        .icon {
          width: 20px;
          height: 20px;
          margin-left: 10px;

          &.check {
            background-image: url("../img/i-check.svg");
          }

          &.raised-hand {
            background-image: url("../img/raised-hand.svg");
          }
        }
      }

      .ratingRow {
        margin-right: 20px;
      }

      .userPicture {
        margin-left: 0;
      }
    }

    .ratingRow {
      align-items: center;
      height: 30px;

      svg {
        width: 12px;
        height: 12px;
        margin-right: 3px;
      }
    }

    .report-totals {
      position: absolute;
      top: 0;
      background-color: white;
      top: 80px;
      left: 0;
      right: 0;
      padding: 20px 25px 15px 25px;
      z-index: 3;

      header {
        opacity: 1;
        margin-right: 30px;
        text-transform: none;

        &:last-child {
          margin-right: 0;
        }

        .capsule {
          font-weight: normal;
        }

        .title {
          margin-bottom: 5px;
          display: block;
        }

        .icon {
          width: 20px;
          height: 20px;
          margin-right: 5px;

          &.check {
            background-image: url("../img/i-check.svg");
          }
        }

        strong {
          color: black;
          margin-right: 5px;
          font-size: 24px;
        }
      }
    }

    .icon.incorrect {
      background-image: url("../img/i-incorrect.svg");
    }
  }

  .modal-body {
    overflow-y: auto;
    padding-right: 20px;
    margin-right: -20px;
    max-height: 60vh;

    .preview {
      pointer-events: none;
      padding-right: 20px;

      .MuiChip-deleteIcon {
        display: none;
      }

      .participants {
        ul {
          margin-top: 15px;
          margin-right: 20px;
        }

        li:first-child {
          border-top: 1px solid rgba(0, 0, 0, 0.1);
        }

        li:last-child {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
      }

      .availableHours {
        .inputSelect {
          .MuiInputBase-root:before {
            display: none;
          }

          .MuiSelect-icon {
            display: none;
          }
        }

        .timeBlocks {
          .MuiInputBase-root:before {
            display: none;
          }
        }
      }

      .column {
        padding: 0;
      }

      .ratingContainer {
        display: none;
      }

      .capsule {
        position: absolute;
        right: 0;
      }

      .requested-time {
        display: none;
      }

      .request-list-item {
        h3 {
          text-transform: none;
          color: black;
        }
      }

      svg {
        width: 20px;
      }

      .MuiAccordionSummary-root {
        margin-bottom: -35px;
      }

      .MuiAccordionDetails-root {
        border-width: 0px;
      }

      .MuiPaper-root {
        border-radius: 10px;
        border: 2px solid $light-grey;
        box-shadow: none;
      }
    }
  }

  .dialog {
    padding: 25px;
    padding-bottom: 125px;
    min-width: 500px;
    max-width: 800px;
    opacity: 1;
    transition: 0.3s;

    &.isSaving {
      opacity: 0.2;
      pointer-events: none;
    }

    &.recommended {
      padding-top: 105px;

      .ql-container {
        .ql-tooltip {
          left: 0 !important;
        }

        .ql-editor {
          background-color: $light-grey;
        }
      }

      h2 {
        .icon.close {
          position: absolute;
          right: 15px;
          top: 15px;
          width: 25px;
          height: 25px;
          background-image: url("../img/close-white.svg");
        }
      }

      .date-time-select-container {
        display: flex;
        align-items: center;
      }

      .dateTime {
        font-size: 1rem;
        font-weight: normal;
        line-height: 16px;

        .flexRow {
          display: flex;
          align-items: center;
          flex: 1;
          margin-top: 10px;

          .MuiTextField-root {
            flex: 1;
            width: auto;
            max-width: none;
          }
        }

        .dateBlock {
          margin-top: 15px;

          &:last-child {
            margin-left: 35px;
            text-align: right;
          }

          &:first-child {
            margin-left: 0;
            text-align: left;
          }

          strong {
            display: block;
            margin-top: 3px;
          }
        }
      }

      h2 {
        text-shadow: 0 1px 4px rgba(0, 0, 0, 0.77);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 35px;
        color: white;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        justify-content: center;
      }
    }

    &.lastStep {
      padding-top: 0px;
    }

    .buddy-message {
      position: relative;
    }

    .helperText {
      opacity: 0.7;
      font-size: 12px;
      display: block;
      line-height: 16px;
    }

    .buddy-container {
      margin: 50px auto 20px auto;
      overflow: visible;

      .buddy-message {
        padding: 30px 20px 20px;
      }
    }

    .inputSelect {
      margin-top: 2px;

      label[data-shrink="true"] {
        top: 3px;
      }
    }

    .uploadImageWrapper {
      cursor: pointer;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      transition: 0.4s;
      opacity: 1;
      background-color: transparent;
      height: 100%;
      margin: 0;

      strong {
        position: absolute;
        bottom: 10px;
        font-size: 14px;
        font-weight: normal;
        left: 0;
        right: 0;
      }

      &.isDragging {
        transform: scale(1.05);
        opacity: 0.5;
      }

      .uploadedImage {
        display: none;
      }

      &.isUploading {
        transform: scale(1.05);
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }

      strong {
        display: block;
      }

      .tooltip {
        display: none;
      }

      .fileUpload {
        position: relative;
        transform: scale(1);
        transition: 0.4s;
        width: 100%;
        height: 100%;

        &.isRejected {
          animation-timing-function: ease-in-out;
          animation: headShake forwards 1.5s;
        }

        input {
          left: 0;
          right: 0;
          height: 102px;
          cursor: pointer;
          z-index: 5;
          opacity: 0;
          position: absolute;
          width: 100%;
        }
      }
    }

    .star-button {
      padding: 6px 6px;
      width: 20px;
      max-width: 20px;
      min-width: 45px;
      border-radius: 100px;
      margin-left: 2px;
    }

    section {
      margin: 20px 0;
    }

    label[data-shrink="true"] {
      top: 5px;
    }

    header {
      font-weight: bold;
      opacity: 0.75;
      text-transform: uppercase;
      font-size: 14px;
    }

    input {
      border: none;
    }

    textarea {
      max-height: 150px;
    }

    .MuiFormControl-root {
      width: 100%;
    }

    .MuiInputBase-root {
      width: 100%;
      flex: 1;

      input {
        margin: 0;
      }
    }

    footer {
      flex-direction: column;
      background-color: white;
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
      padding: 20px;

      .button.large {
        color: black;

        &.Mui-disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }

      .flexRow {
        margin-bottom: 15px;
        display: flex;
        width: 100%;
        justify-content: space-between;
      }

      .button {
        margin-left: 0px;

        &.secondary {
          background-color: $light-grey;
        }
      }
    }
  }

  h2 {
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 25px 0;

    .close {
      background-image: url("../img/x.svg");
      width: 25px;
      height: 25px;
      cursor: pointer;
      transition: 0.2s;
      opacity: 0.5;
      text-align: right;
      position: relative;
      top: 2px;
      margin-right: 0px;

      &:hover {
        transform: scale(1.2);
      }
    }
  }
}

.page.recommended-events {
  max-width: 1170px;
  margin-left: 250px;
  padding-top: 85px;

  h2 {
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 0;

    .loadingWrapper {
      margin-bottom: -10px;
    }
  }
}

.recommended-grid {
  margin: 12px;
}

.card.recommended-event {
  overflow: visible;
  margin: 10px 20px 20px 0;
  cursor: pointer;
  border-radius: 10px;
  position: relative;
  width: 270px;

  header {
    text-align: center;
  }

  &:hover {
    .image-wrapper {
      transform: scale(1.2);
    }
  }

  .MuiCardContent-root {
    padding: 0;
  }

  .image-wrapper {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transition: 0.5s;
    height: 90px;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
  }

  .external-backdrop {
    width: 100%;
    height: 90px;
    position: absolute;
    z-index: 1;
    background-position: center top -45px;
    background-repeat: no-repeat;
    background-size: 220px;
    opacity: 0.25;
    background-image: url("../img/external-pd-white.svg");
  }

  .external-pd {
    display: block;
    z-index: 2;
    position: absolute;
    left: 0;
    right: 0;
    top: 25px;
    height: 40px;
    background-image: url("../img/external-pd-color.svg");
  }

  .inner {
    padding: 20px;
  }

  .capsule-wrapper {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 76px;
    z-index: 5;
    transform: scale(0.8);
  }
}

.request-list-item {
  margin: 3px 0;
  border-radius: 8px;
  list-style-type: none;
  .user-label-user-subgroup > span {
    overflow: hidden;
    max-width: 220px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .timeBlockList {
    transition: 0.4s;
    padding: 1px 10px 10px;
    border-radius: 8px;
    cursor: pointer;
    background-color: transparent;

    .categorySelectFormControl {
      width: 100%;
    }

    .first {
      .inputSelect {
        .category-icon-wrapper.display {
          top: 18px;
        }
      }
    }

    &.isEditing {
      .MuiAutocomplete-root {
        background-color: $light-grey;
      }
    }

    .icon.edit,
    .MuiChip-deleteIcon {
      transition: 0.4s;
      opacity: 0;
    }

    &:hover {
      background-color: $light-grey;

      .icon.edit {
        opacity: 1;
      }
    }

    .button {
      display: none;
    }

    svg,
    .MuiInput-underline:before {
      opacity: 0;
    }

    li {
      pointer-events: none;

      .MuiInput-underline:before {
        transition: 0.4s;
        opacity: 0;
      }
    }

    &.isEditing {
      background-color: $light-grey;

      .MuiAutocomplete-root input {
        padding-bottom: 9px !important;
      }

      .button {
        display: flex;
      }

      svg,
      .MuiInput-underline:before {
        opacity: 1;
      }

      .MuiChip-deleteIcon {
        opacity: 1;
      }

      .icon.edit {
        display: none;
      }

      li {
        pointer-events: all;

        svg {
          opacity: 1;
        }

        .MuiInput-underline:before {
          opacity: 1;
        }
      }
    }
  }

  .MuiChip-deleteIcon {
    top: 0px !important;
  }

  li:first-child {
    .MuiChip-deleteIcon {
      top: 8px !important;
    }
  }

  .inputSelect {
    width: 195px;

    &:first-child .MuiInputBase-root {
      margin-top: 18px;
    }
  }

  .status-icon {
    width: 18px;
    height: 18px;
    margin-left: 15px;
    margin-right: 5px;
  }

  p {
    font-size: 14px;
  }

  .flexRow {
    width: 100%;
  }

  h3 {
    opacity: 1;
    text-transform: none;
    color: black;
  }

  .MuiPaper-root {
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 10%),
      0px 1px 1px 0px rgb(0 0 0 / 4%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  }

  .icon.declined {
    background-image: url("../img/declined-red.svg");
  }

  header {
    font-weight: bold;
    opacity: 0.75;
    text-transform: uppercase;
    font-size: 14px;
  }

  .MuiAccordionDetails-root {
    flex-direction: column;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    footer {
      margin: 25px 25px 0 0;
    }
  }

  .request-content {
    display: flex;
    flex-wrap: wrap;

    #category-autocomplete {
      .category-icon-wrapper {
        top: 18px;
      }
    }

    .column {
      flex: 1;
      padding-top: 0;

      .pd-attachment-list {
        li {
          padding: 8px 0;
          border: none !important;

          button {
            text-align: left;
          }
        }
      }

      &:first-child {
        flex: 0;
        min-width: 450px;
        margin-right: 25px;
      }

      section {
        margin-bottom: 20px;
      }

      .flexRow svg {
        margin-right: 10px;
        margin-top: 5px;
      }
    }

    p {
      margin: 5px 0 0;
    }
  }

  .prompt {
    animation: fadeInUp 1s;
  }

  footer {
    .button {
      margin-left: 25px;

      &.secondary {
        background-color: $light-grey;
      }
    }
  }

  .icon.resubmit {
    background-image: url("../img/reopen-pending.svg");
  }

  .icon.delete {
    background-image: url("../img/trash-black.svg");
  }

  .MuiAccordionSummary-root {
    padding-right: 20px;
    transition: 0.3s;
  }

  [aria-expanded="true"],
  .MuiAccordionSummary-root:focus,
  .MuiAccordionSummary-root:active {
    background-color: white;
    border-radius: 8px;
  }

  h3 {
    font-weight: bold;
    font-size: 18px;
    margin: 0 !important;
  }

  .flexRow {
    align-items: center;
  }

  li.flexRow {
    align-items: flex-start;
  }

  .icon.external-pd.color {
    background-image: url("../img/external-pd-color.svg");
    width: 40px;
    height: 40px;
  }

  .icon.recommended {
    background-image: url("../img/recommended.svg");
    width: 40px;
    height: 40px;
  }

  .timestamp {
    position: relative;
    top: -1px;
    font-size: 14px;
    opacity: 0.7;
  }
}

.icon.raised-hand {
  background-image: url("../img/raised-hand.svg");
}

.icon.pre-approval {
  background-image: url("../img/pre-approval-form.svg");
}

.events-layout.recommended-events {
  .request-filters {
    .category-breadcrumbs {
      display: none;
    }
    .categorySelectFormControl {
      margin-left: 20px;
      .MuiTextField-root {
        width: 250px;
      }
    }

    margin-bottom: 30px;
    .MuiFormControl-root {
      width: auto;
    }

    label {
      background-color: $light-grey;
    }
  }
}
