@import "./variables.scss";

#categorySelect-popup {
  li {
    padding: 0;

    > div {
      padding: 10px 15px;
      position: relative;

      .category-icon-wrapper {
        width: 20px;
        height: 20px;
        position: relative;
        top: -7px;
        margin-right: 5px;

        .category-icon {
          width: 20px !important;
          height: 20px !important;
        }
      }
    }
  }
}

.top-level {
  background-color: white;
  z-index: 5;
  width: 100%;

  &:hover {
    background-color: $light-grey;
  }
}

.second-level,
.third-level {
  position: relative;
}

.second-level {
  margin-left: 15px;
  padding-left: 15px;
}

.third-level {
  margin-left: 30px;
  padding-left: 5px;
}

.menu-category-icon {
  position: relative;
  top: 2px;

  .category-icon {
    border: 3px solid white;
  }
}

.parentCategory-select {
  margin-top: 25px;
}

[aria-labelledby="label-parent-category"] {
  .MuiListItem-button {
    height: 50px;
  }
}

.modal.manageCategories {
  margin: 50px auto;
  .categorySelectFormControl {
    width: 100%;
  }
  .category-preview {
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    right: 0;

    .MuiPaper-root {
      min-width: 250px;
      flex: 0;
    }

    .card.small {
      cursor: pointer;

      h2 {
        position: absolute;
        left: 0;
        right: 0;
        top: 15px;
        margin-top: 100px;
      }

      &:hover {
        h2 {
          top: -70px !important;
        }
      }
    }
  }

  .preview {
    position: relative;
  }

  .loadingWrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .image-grid {
    overflow-y: auto;
    max-height: 440px;

    &::-webkit-scrollbar {
      display: none;
    }

    ul {
      list-style-type: none;
      margin: 15px 0 0 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      li {
        width: 55px;
        min-width: 55px;
        max-width: 55px;
        margin: 5px;
        background-color: transparent;
        transition: 0.4s;
        border-radius: 12px;
        cursor: pointer;
        padding: 10px;
        flex: 1;
        flex-shrink: 0;

        &:hover {
          background-color: $light-grey;
        }

        &.active {
          background-color: #24dc91;
        }

        img {
          width: 100%;
        }
      }
    }
  }

  .inputSearch {
    .MuiInputBase-root {
      margin-top: 19px;
    }
  }

  &.isCreating {
    .MuiPaper-root {
      max-width: 95vw;
    }
  }

  .MuiPaper-root {
    border-radius: 20px;
    max-width: 800px;
    flex: 1;
    padding: 0;

    .MuiSelect-root {
      display: flex;
      align-items: center;
      padding: 8px 24px 8px 2px;

      .category-icon-wrapper,
      .category-icon {
        width: 20px !important;
        height: 20px !important;
      }

      .menu-category-icon {
        top: -8px;
        margin-right: 8px;
        margin-left: 5px;
      }
    }

    .pager {
      min-height: 70vh;
      align-items: flex-start;

      .page-2 {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 160px;
        opacity: 0.8;
        transform: translateX(100%);

        .react-colorful {
          width: 300px;
        }

        .react-colorful__saturation {
          border-radius: 10px;
        }

        .react-colorful__pointer {
          border: 4px solid #fff;
        }

        .react-colorful__last-control {
          top: -20px;
          height: 20px;
        }

        .card.small {
          .category-icon-wrapper {
            overflow: hidden;
            border-radius: 45px;
            left: calc(50% - 22.5px);
            width: 45px;
            height: 45px;
            box-shadow: 0 2px 4px 0 rgb(0 0 0 / 48%);
            border: 4px solid white;
            align-items: center;
          }

          &:hover {
            .category-icon-wrapper {
              left: 10px;
              width: 15px !important;
              height: 15px !important;
              border: 2px solid white;
            }
          }
        }

        .category-disabled {
          opacity: 0.5;
          pointer-events: none;
        }

        .image-upload {
          position: relative;
          margin: 15px 0;
          width: 300px;
          display: flex;
          justify-content: center;

          .icon {
            background-image: url("../img/camera-blue.svg");
          }

          input {
            opacity: 0;
            cursor: pointer;
            position: absolute;
            left: 0;
            right: 0;
            max-width: 100%;
          }
        }

        .newCategoryImage {
          width: 100px;
          height: 100px;
          border-radius: 100%;
          margin: 0 auto;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
          border: 6px solid white;
          flex-shrink: 0;
          position: relative;
          top: -2px;

          &.large {
            width: 200px;
            height: 200px;
            box-shadow: none;
            border: 0px;
          }
        }
      }

      &.active {
        .page-2 {
          transform: translateX(0);
        }

        .page-1 {
          transform: translateX(-100%);
        }
      }
    }

    h2 {
      margin-top: 0;

      .add-button {
        button {
          padding-left: 20px;
          padding-right: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &:hover {
          button {
            background-color: rgba(0, 0, 0, 0.2);
          }
        }
      }

      .icon.plus {
        background-image: url("../img/plus-black.svg");
      }

      .icon.eye {
        background-image: url("../img/eye.svg");
      }
    }
  }

  .inner {
    background-color: white;
    min-height: 300px;
    overflow: auto;
    max-height: 75vh;
    transition: 0.5s;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    .loadingWrapper {
      position: relative;
      justify-content: center;
      margin-bottom: -5px;
      margin-top: 35px;
    }
  }

  header {
    display: flex;
    color: #333;
    border-bottom: 0px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    padding: 20px 30px 20px 30px;
    border-bottom: 1px solid $light-grey;
  }

  footer {
    margin-top: 0px;
  }

  .MuiChip-deleteIcon {
    right: 0 !important;
  }

  .title {
    margin-left: -15px;
    display: flex;
    flex-direction: column;
  }
}

.category-tooltip-container {
  width: 150px;
  cursor: pointer;

  &:hover {
    .inner {
      opacity: 1;
    }
  }

  .inner {
    pointer-events: none;
    opacity: 0;
    transition: 0.4s;
    position: absolute;
    z-index: 8;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
    border-radius: 12px;
    padding: 20px;
    max-width: 490px;
    min-height: 100px !important;

    ul,
    li {
      margin-bottom: 0;
      font-weight: bold;
      padding: 4px 0;
      list-style-type: normal !important;
      background-color: white !important;
    }
  }
}

.modal.manageCategories {
  margin: 0 auto;

  .flexRow.pager.active {
    .categorySelectFormControl {
      .category-icon {
        width: 20px !important;
        height: 20px !important;
      }

      .menu-category-icon.display {
        top: 13px;
        margin-left: 4px;
        position: absolute;
      }
    }
  }

  .imagePreview {
    position: absolute;
    top: 200px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .preview-inner {
      animation: flipInY 1s forwards;
      border-radius: 12px;
      padding: 30px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
      background-color: white;
      display: flex;
      justify-content: center;
      flex-direction: column;

      strong {
        text-align: center;
        display: block;
        font-size: 14px;
      }

      .progress {
        height: px;
        background-color: $light-grey;
        border-radius: 15px;
        margin: 15px 0 10px 0;
        overflow: hidden;

        @keyframes slidein {
          from {
            transform: translateX(-100%);
          }

          to {
            transform: translateX(0%);
          }
        }

        div {
          border-radius: 15px;
          width: 100%;
          height: 6px;
          animation: slidein 2s forwards;
        }
      }
    }
  }

  &.embed {
    padding: 0;
    margin: 0;

    .MuiAccordionSummary-content {
      margin: 0;
    }
  }

  .loadingWrapper {
    z-index: 5;
    position: fixed;
  }

  .buttonContainer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    justify-content: center;

    .secondary {
      background-color: $light-grey;

      &:hover {
        background-color: darken($light-grey, 3%);
      }
    }
  }

  footer {
    padding: 15px 5px;
    justify-content: flex-start;
  }

  .MuiChip-deleteIcon {
    opacity: 0;
    transition: 0.3s;
    position: absolute;
    right: -10px;
    cursor: pointer;
  }

  header {
    .name {
      flex: 1;
      margin-left: 45px;
    }

    .grow {
      text-align: right;
    }
  }

  .MuiAccordionSummary-root {
    padding: 0;
    width: 100%;
  }

  .MuiAccordionSummary-content {
    margin: 0;
  }

  .MuiAccordion-root {
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid $light-grey;
    margin-bottom: 0;
  }

  .MuiAccordionDetails-root {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .inputSearch {
    top: -3px;
  }
  .topCategory {
    .flexRow {
      cursor: pointer;
      position: relative;
      flex: 1;

      img {
        margin-right: 30px;
      }
    }
  }

  .categoryList {
    margin: 0;
    padding-bottom: 100px;

    &::-webkit-scrollbar {
      display: none;
    }

    .MuiAccordionSummary-content.Mui-expanded {
      margin: 0;
    }
  }

  ol {
    padding: 0;
  }

  li {
    background-color: rgba(0, 0, 0, 0);
    flex-direction: column;
    display: flex;
    flex: 1;
    box-sizing: border-box;
    transition: 0.3s;
    background-color: $light-grey;

    .title {
      flex: 1;
    }

    .checkbox {
      margin-right: 0;
    }

    .flexRow {
      background-color: white;
      padding: 20px;

      &:hover {
        background-color: rgba(250, 250, 250, 0.7);

        &:after {
          opacity: 0.5;
        }
      }
    }

    .Mui-expanded .flexRow:after {
      transform: scaleY(-1);
    }

    .uploadImageWrapper,
    .categoryImage {
      width: 35px;
      height: 35px;
      border-radius: 100%;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
      border: 4px solid white;

      .tooltip {
        display: none;
      }
    }

    .uploadImageWrapper {
      &:after {
        opacity: 0;
        transition: 0.3s;
      }

      margin: 0 50px 0 10px;
    }
  }

  .categoryImage {
    margin-right: 50px;
  }

  .subCategoryList {
    background-color: $light-grey;

    li {
      position: relative;

      .subCat {
        display: flex;
        background-color: transparent;
        margin-left: 35px;
        padding: 5px 20px 5px 20px;
        align-items: center;
        position: relative;
      }

      .categoryImage {
        margin-left: 0px;
      }

      &:before {
        content: "";
        display: block;
        width: 1px;
        position: absolute;
        top: 25px;
        bottom: 19px;
        left: 66px;
        background-color: rgba(0, 0, 0, 0.2);
      }

      &:hover {
        .MuiChip-deleteIcon {
          opacity: 0.28;
        }
      }

      .border.circle {
        background-image: url("../img/upload-circle-small.svg");
        width: 41px;
        height: 41px;
      }

      .fileUpload,
      .uploadImageWrapper,
      .uploadedImage,
      input[type="file"],
      img {
        width: 20px;
        height: 20px;
        border: 3px solid white;
      }
    }

    .thirdLevelList {
      li {
        margin-left: 68px;
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-left: 60px;
        padding: 5px 20px 5px 20px;
        background-color: transparent;

        &:before {
          content: "";
          display: block;
          width: 30px !important;
          position: absolute;
          top: 17px;
          left: 6px !important;
          height: 1px;
          background-color: rgba(0, 0, 0, 0.2);
        }

        .title {
          margin-left: -32px;
        }

        &:before {
          width: 1px;
          left: -70px;
        }
      }
    }
  }
}
