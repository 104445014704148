@import "variables";

.panel {
  background: white;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 35px;
}

.conference-events-list {
  position: relative;
  z-index: 2;
  margin-top: 30px;
  .icon.archive {
    width: 15px;
    height: 15px;
    position: absolute;
    margin-left: 10px;
    top: 2px;
    opacity: 0.2;
    background-image: url("../img/i-archive-black.svg");
  }

  ul {
    list-style-type: none;
    padding: 0px;
    overflow: auto;

    li {
      position: relative;
      margin: 0;
      border-top: 1px solid $light-grey;
      padding: 15px 10px;
      transition: background-color 0.5s;
      background-color: white;
      cursor: pointer;
      &:last-child {
        border-bottom: 1px solid $light-grey;
      }
      .roomInfo {
        margin-top: 5px;
        font-weight: bold;
        margin-left: -3px;
      }

      .icon.room {
        display: block;
        width: 20px;
        height: 20px;
        margin-top: 1px;
        margin-right: 5px;
        background-image: url("../img/room.svg");
      }

      &:hover {
        background-color: $light-grey;
      }

      .title {
        flex: 1;
        position: relative;
        padding-left: 20px;

        &.draft:before {
          background-color: #575757;
        }
      }

      .dateTime {
        margin-top: 3px;
        font-size: 14px;

        .date {
          opacity: 0.6;
        }
      }
    }
  }
}

strong.warning {
  color: $red;
  opacity: 0.9;
  margin-top: -15px;
  font-size: 14px;
  margin-bottom: -10px;
  animation: fadeInUp 0.3s;
}

.capitalize {
  text-transform: capitalize;
}

.sidebar-event {
  background-color: white;
  margin-top: 0px;
  position: absolute;
  left: 0;
  height: 100%;
  padding: 20px;
  padding-top: 70px;
  z-index: 10;

  li:hover {
    .no-access {
      opacity: 1;
    }
  }

  .no-access {
    background-color: black;
    color: white;
    font-size: 14px;
    position: absolute;
    left: 250px;
    margin-top: 0px;
    border-radius: 8px;
    width: 300px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    z-index: 100;
    padding: 15px;
    opacity: 0;
    transition: 0.4s;
    pointer-events: none;
  }

  .infoContainer {
    display: flex;
    align-items: center;
    max-width: 190px;
    margin-top: 20px;
    animation: fadeInUp 1s forwards;

    p {
      font-style: italic;
      margin: 0;
      font-size: 12px;
      line-height: 13px;
    }

    .icon {
      margin-right: 10px;
      width: 35px;
      height: 25px;
      background-image: url("../img/tip-outline.svg");
    }
  }

  &.sidebar-conference-event {
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    bottom: 0;
    top: 0;
    background-color: white;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-left: 0;

    ul.sidebar-tabs {
      width: 60px;
    }

    li {
      margin: 0;
      padding: 0 !important;
      width: 60px;
      justify-content: center;
      border: none !important;

      &.divider {
        display: none !important;
        // height: 0px !important;
        // text-indent: 90000px;
      }

      .icon {
        margin-right: 0 !important;
        width: 60px;
      }
    }
  }

  li.highlight {
    background-color: $green !important;
  }

  &.conference {
    li.active {
      background-color: #bbced9 !important;
    }
  }

  .divider {
    text-transform: uppercase;
    opacity: 0.6;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: rgba(0, 0, 0, 0) !important;
    cursor: initial;
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    padding: 20px;
    margin: 0 -20px;
    list-style-type: none;
    width: 200px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: white;
    transition: 0.2s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }

    &.active {
      background-color: $green;

      &:hover {
        background-color: $green;
      }
    }

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
}

.MuiRadio-colorSecondary.Mui-checked {
  color: $blue !important;
}

.select {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
  max-width: 300px !important;
}

.selectOther {
  margin: 0px 0 -12px !important;
}

.event-registration {
  .select {
    width: 100%;
    margin-top: 10px !important;
    max-width: 480px !important;
  }

  .MuiFormHelperText-filled {
    width: 200px;
  }
}

.multiSelect.Mui-selected {
  background-color: rgba(145, 211, 108, 0.4) !important;
}

.event-mm-content {
  max-width: 650px;
}

#conference-tabs-container {
  position: absolute;
  z-index: 24;
  margin-bottom: 20px;
  top: 0;
  right: 0;
  left: 0px;
  border-radius: 12px 12px 0 0;
  border-bottom: 1px solid $light-grey;
  overflow: hidden;

  .Mui-selected {
    background-color: $green !important;
  }

  .MuiTabs-indicator {
    height: 100%;
    display: flex;
    justify-content: center;
    transition: none;
    z-index: -1;

    &:after {
      content: "";
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  button:focus,
  button:active {
    background-color: transparent;
  }

  button {
    text-transform: capitalize;
    font-weight: bold;
    font-size: 18px;
  }

  .MuiTabs-flexContainer {
    width: 100%;
    flex: 1;

    .MuiTab-root {
      flex: 1;
      max-width: none;
      border-right: 1px solid $light-grey;
    }
  }

  .MuiTabs-fixed {
    display: flex;
  }
}

.conferenceCalendar {
  padding: 0 !important;
  margin: 0 !important;

  .MuiPaper-rounded {
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .MuiTabs-root {
    top: 15px !important;
  }
}

.event-content {
  margin-top: 105px;
  margin-left: 250px;
  animation: fadeIn 0.3s forwards;

  .editRoom.button {
    position: absolute;
    right: 45px;
    margin-top: 7px;
  }

  .pdCreditToolTip {
    position: absolute;
    top: 2px;
    left: 95px;

    &:hover {
      .inner {
        opacity: 1;
      }
    }

    .inner {
      background-color: white;
      padding: 5px 15px;
      border-radius: 6px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
      z-index: 10;
      position: absolute;
      left: -100px;
      width: 350px;
      transition: 0.4s;
      opacity: 0;
      pointer-events: none;
      font-size: 14px;
      font-weight: normal;
      color: black;
      text-transform: none;
    }

    .icon {
      cursor: pointer;
      width: 15px;
      height: 15px;
    }
  }

  .roomItem {
    background-color: rgba(89, 195, 229, 0.15);
    margin: 20px -50px -10px;
    border-radius: 6px;
    padding: 20px;
    animation: fadeInUp 0.2s forwards;

    footer {
      justify-content: flex-end;
    }

    h3 {
      margin-top: 0px;

      .icon.room {
        margin-right: 10px;
        display: inline-block;
        width: 25px;
        height: 25px;
        position: relative;
        top: 5px;
      }
    }
  }

  .roomSelect {
    align-items: center;
    width: 100%;
    height: 80px;

    .MuiAutocomplete-root {
      flex: 1;
      max-width: 200px;
      width: 200px !important;
      margin-right: 15px;
      position: relative;

      &:before {
        background-image: url("../img/room.svg");
        width: 25px;
        height: 25px;
        content: "";
        display: block;
        position: absolute;
        left: -35px;
        top: 18px;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    .button {
      flex-shrink: 0;
      top: -10px;
    }
  }

  .loading-small {
    transform: scale(1);
    position: relative;
    width: 100%;
    top: 60px;
    left: 15px;

    span {
      position: absolute;
      width: 230px;
      right: -250px;
      margin-top: -55px;
    }

    strong {
      margin-left: 10px;
    }
  }

  .ql-container,
  .ql-editor {
    height: 125px;
    min-height: 0px;
  }

  &.event-details {
    max-width: 650px;

    .MuiFormControl-root.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    .eventImageWrapper {
      margin-bottom: 40px;

      p {
        max-width: 300px;
        font-size: 14px;

        &:first-child {
          margin-top: 55px;
        }
      }
    }

    .uploadImageWrapper {
      width: 250px;
      margin: 10px 25px 0 0;

      img {
        height: auto;
      }

      .uploadedImage {
        margin-bottom: 25px !important;
      }

      input,
      .uploadedImage,
      .fileUpload,
      .tooltip,
      .border {
        width: 250px;
        height: 110px;
      }

      .button {
        margin-top: 20px;
      }

      .tooltip {
        height: auto;
        margin-top: 0px;
      }
    }
  }

  .inputSearch {
    position: relative;
    margin-bottom: -10px;
    flex: 1;

    label {
      left: 25px;
    }

    input {
      padding-left: 30px;
    }

    .MuiTextField-root {
      flex: 1;
      margin-right: 25px;
    }

    .icon.search {
      position: absolute;
      bottom: 22px;
      left: 0px;
      width: 20px;
      height: 20px;
      opacity: 0.3;
    }
  }

  div[role="radiogroup"] {
    flex-direction: row;
  }

  .dateTimeSelect {
    align-items: flex-end;
    margin-top: 15px;
    .MuiFormControlLabel-root {
      position: relative;
      top: -22px;
    }

    &:last-child {
      margin-top: 10px;
    }

    .MuiInputBase-root {
      margin-right: 10px;
    }

    .MuiFormControl-root {
      margin-right: 10px !important;

      &.time {
        .MuiIconButton-root {
          display: none;
        }
      }
    }

    &.registrationWindow {
      flex-wrap: wrap;
      margin-top: 10px;

      > div {
        margin-bottom: 10px;

        &:first-child {
          margin-right: 20px;
        }

        .MuiFormControl-root {
          margin: 0;

          &:last-child {
            margin-top: 10px;
          }
        }
      }
    }

    &.joinCodeRange {
      margin-bottom: 20px;
      flex-wrap: wrap;

      > div {
        &:first-child {
          margin-right: 20px;
        }

        .MuiFormControl-root {
          margin: 0;

          &:first-child {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  .conference-event-edit {
    .subnav {
      animation: none;
    }

    .panel .roomItem {
      margin: 0 0 0 -35px;

      h3 {
        justify-content: flex-start;
        font-size: 14px;
        font-weight: 700;

        .icon {
          top: -4px;
        }
      }
    }

    .joinCodeRange {
      .MuiFormControl-root {
        margin: 0;

        &:first-child {
          margin-bottom: 5px !important;
        }
      }
    }
  }

  .MuiFormControl-root {
    max-width: 650px;
    display: flex;
    margin: 0 0 18px;

    input {
      width: 100%;
    }
  }
}

.icon.video-conference {
  background-image: url("../img/video-conference.svg");
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.icon.location-color {
  width: 35px;
  height: 35px;
  margin-right: 10px;
  position: relative;
  top: -5px;
  background-image: url("../img/location-color.svg");
}

.event-mm-content {
  .MuiAutocomplete-inputRoot {
    margin-top: 20px;
  }
}

.event-single {
  .event-badge-container {
    display: flex;
    align-items: center;

    strong {
      position: relative;
      top: -5px;
      margin-left: 5px;
    }
  }

  .badge {
    width: 75px;
    height: 75px;
    border-radius: 100%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    margin-bottom: 10px;
  }

  &.edit {
    .sidebar-event {
      position: fixed;
    }
  }

  .columns {
    display: flex;
  }

  .column.left {
    max-width: 500px;
  }
}

.two-column {
  margin-top: 90px;

  .locationSelectWrapper {
    .MuiFormControl-root {
      width: 100%;
    }
  }
}

.saving {
  transition: 0.3s;
  opacity: 0.5 !important;
  pointer-events: none !important;
}

.autocomplete-dropdown-container {
  background: white;
  margin-top: 10px;
  border-radius: 6px;
  overflow-y: hidden;
}

.suggestion-item,
.suggestion-item--active {
  padding: 10px !important;
  background-color: white;
  cursor: pointer;

  &:before {
    display: inline-block;
    content: "";
    background-image: url("../img/location.svg");
    width: 15px;
    height: 15px;
    background-size: contain;
    opacity: 0.35;
    margin-right: 10px;
    position: relative;
    top: 1px;
  }

  &:hover {
    padding: 10px !important;
    background-color: $green !important;
  }
}

.icon.location.color {
  background-image: url("../img/location-color.svg") !important;
  width: 25px;
  height: 25px;
}

.loading-small {
  display: flex;
  padding-left: 20px;

  .loadingWrapper {
    transform: scale(0.75);
    margin-left: -15px;
    position: relative;
  }

  strong {
    position: relative;
    top: 8px;
    font-size: 14px !important;
    padding-left: 25px;
  }
}

.learner-search {
  position: relative;
  width: 100%;
}

.event-select-list {
  list-style-type: none;
  padding: 0;

  .button.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  li {
    margin-bottom: 1px;
    padding: 18px;
    padding-left: 13px;

    .presenter-details {
      p {
        margin-top: 5px;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}

.learner-name {
  text-transform: capitalize;
}

.presenter-name {
  font-size: 20px;
}

.event-select-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
  border-left: 5px solid rgba(0, 0, 0, 0);
  opacity: 0;
  animation: fadeInUp 0.2s forwards;

  .MuiAutocomplete-endAdornment {
    position: absolute;
  }

  .MuiFormControl-root {
    max-width: 250px;
    width: auto;
  }

  &:hover {
    background-color: rgba(250, 250, 250, 0.8);
  }

  &.inactive {
    &:hover {
      background-color: white !important;
    }
  }

  button {
    margin-left: 15px;
  }

  &.active {
    border-left: 5px solid $green;
    background-color: white;
  }

  .location.icon {
    position: relative;
    top: -2px;
    margin-right: 10px;
  }

  .details {
    flex: 1;

    .icon {
      display: none;
    }

    header {
      font-weight: bold;
      font-size: 14px;
      margin: 15px 0 0;
    }

    p {
      margin: 0;
    }
  }

  strong {
    display: block;
  }
}

.allDayCheckbox {
  top: -11px;
  position: relative;
}

.page.event-single {
  &.conference-edit {
    .subnav {
      .icon.draft {
        background-image: url("../img/calendar-white.svg");
      }
    }
  }

  .columns {
    align-items: flex-start;
  }

  .column {
    padding: 0;
    flex: 1;

    .list-header {
      justify-content: space-between;
      position: relative;
      top: 0;
      opacity: 1;
      transition: 0.3s;
      transform: translate3d(0, 0, 0);
      align-items: flex-end;

      &.slideUp {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
      }
    }
  }
}

.panel.current-people {
  padding: 0px;

  .button {
    margin: 0px 20px 20px 0;
  }

  > p {
    padding: 20px;
  }
}

.current-selection {
  margin-bottom: 35px;

  .event-select-item:hover {
    cursor: initial !important;
    background-color: white !important;
  }

  .icon.location {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }
}

.virtual-location {
  .flexRow {
    align-items: flex-start;

    .icon.video-conference {
      margin-top: 20px;
    }
  }
}

.joinCode {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 30px;
  margin-bottom: 15px;
  flex: 1;
  top: -18px;
  position: relative;

  .button {
    margin-right: 20px;
  }

  span {
    font-weight: bold;
  }
}

.add-new {
  margin-top: -45px;
  animation: fadeIn 0.3s forwards;

  .button.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .MuiFormControlLabel-root {
    position: relative;
    top: -1px;
    margin-right: 0;
  }

  &.new-presenter {
    &.editing-presenter {
      margin-top: 10px;
      animation: none;
      margin-bottom: 50px;
    }

    .flexRow {
      align-items: flex-start;

      .uploadImageWrapper,
      .fileUpload,
      .tooltip,
      .border,
      .uploadedImage {
        margin: 0;
        width: 150px;
        height: 150px;
      }

      .uploadImageWrapper {
        margin-right: 20px;

        input {
          width: 150px;
          height: 150px;
        }
      }

      .tooltip {
        margin-top: 0px;
        transform: scale(0.7);
      }
    }

    footer {
      margin-top: 40px;
    }
  }

  footer {
    justify-content: flex-end;
    margin-top: 20px;
  }
}

.max-capacity {
  max-width: 165px !important;
}

.event-personnel {
  max-width: 700px;

  .eventRolesTooltip {
    display: flex;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
    margin-left: 15px;

    &:hover {
      .inner {
        opacity: 1;
      }
    }

    .inner {
      opacity: 0;
      transition: opacity 0.3s;
      pointer-events: none;
      position: absolute;
      background-color: white;
      padding: 25px;
      border-radius: 6px;
      box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
        0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
      z-index: 3;
      top: 165px;
      width: 425px;
      left: 320px;

      p {
        font-weight: normal;
        font-size: 14px;
        margin: 0 0 25px;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          padding: 0;
          margin: 5px 0 20px;
          font-size: 14px;

          p {
            margin-top: 5px;
          }

          &:last-child {
            margin: 5px 0 0;

            p {
              margin-bottom: 0px;
            }
          }

          strong {
            font-size: 16px;
          }
        }
      }
    }

    .icon {
      width: 35px;
      height: 35px;
      margin-right: 5px;
    }
  }

  .icon.info {
    opacity: 0.7;
    width: 15px;
    height: 15px;
  }
}

.icon.manager {
  background-image: url("../img/manager.svg");
}

.icon.presenter {
  background-image: url("../img/presenter-color.svg");
}

.icon.secretary {
  background-image: url("../img/receptionist.svg");
}

.event-single .registration-date {
  .MuiFormControl-root {
    margin-right: 35px !important;
  }
}

.add-presenter-form {
  margin-left: 25px;
  flex: 1;
}

.current-people-list {
  padding: 0;
  margin: 0;

  .eventRoles {
    margin-bottom: 0;
    max-width: 215px;
    .MuiSelect-select {
      display: flex;
      align-items: center;
    }

    .icon {
      width: 25px;
      height: 25px;
      background-size: contain;
      border-radius: 0;
      box-shadow: none;
      margin-right: 5px;
    }
  }

  li {
    border-left: 0px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px;

    &:last-child {
      border-bottom: none;
    }

    .bio {
      margin-bottom: 0;
      font-size: 14px;
    }

    strong {
      flex: 1;
    }

    strong {
      font-size: 20px;
      display: block;
    }
  }
}

.attachmentsWrapper {
  .rejection {
    margin-top: -5px;
    animation: fadeInDown 0.3s;
  }

  ul {
    margin: 15px 0;
    padding: 0;
    list-style-type: none;

    li {
      display: flex;
      align-items: center;
      font-weight: bold;
      margin-bottom: 15px;
      max-width: 500px;

      .filename {
        flex: 1;
        max-width: 400px;
        margin-right: 20px;
      }

      .icon {
        margin-right: 10px;
        width: 40px;
        height: 40px;

        &.pdf {
          background-image: url("../img/file-pdf.svg");
        }

        &.doc {
          background-image: url("../img/file-doc.svg");
        }

        &.docx {
          background-image: url("../img/file-docx.svg");
        }

        &.xls {
          background-image: url("../img/file-xls.svg");
        }

        &.xlsx {
          background-image: url("../img/file-xlsx.svg");
        }

        &.ppt {
          background-image: url("../img/file-ppt.svg");
        }

        &.csv {
          background-image: url("../img/file-csv.svg");
        }

        &.pptx {
          background-image: url("../img/file-pptx.svg");
        }

        &.txt {
          background-image: url("../img/file-txt.svg");
        }
      }

      .image {
        background-size: cover;
        border-radius: 6px;
      }
    }
  }

  .uploadFileWrapper {
    margin-bottom: 35px;

    .instructions {
      font-size: 14px !important;

      span:first-child {
        font-weight: bold;
      }

      span:last-child {
        opacity: 0.7;
      }
    }

    width: 500px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    transition: 0.3s;
    border-radius: 6px;
    padding: 10px;

    &:hover,
    &.isDragging {
      border: 1px solid rgba(0, 0, 0, 0);
      background-color: rgba(0, 0, 0, 0.15);
    }
  }

  .fileUpload {
    align-items: flex-start;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;

    &.isDragging {
      transform: none;
      background-color: rgba(0, 0, 0, 0.15);
    }

    input {
      height: 100%;
      width: 100%;
    }
  }

  .uploadedImage,
  .fileUpload,
  .tooltip,
  .border {
    width: 100% !important;
    height: auto !important;
  }
}

.MuiChip-deleteIcon {
  height: 22px;
  width: 22px;
  background-size: 22px;
  transition: 0.3s;
  margin-left: 10px;
  padding: 5px;
  opacity: 0.25;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.pdCreditRow {
  align-items: center;
  margin-top: -5px;
  margin-bottom: 20px;

  .MuiFormControl-root {
    max-width: 40px;
    margin: 0 5px 0 0;
  }

  .label {
    margin-top: 16px;
    margin-right: 20px;
    display: block;
  }
}

.pdCredit {
  margin: 0;
  max-width: 75px;
  margin-top: 16px;

  .MuiInputLabel-root {
    width: 100px;
  }

  .MuiInputBase-root {
    width: 35px;
  }

  .MuiFormControl-root {
    margin-top: 16px;
  }
}

.event-mm-content {
  .loadingWrapper {
    position: relative;
    left: 0;
    right: 0;
    justify-content: flex-start;

    span {
      top: 0;
      margin-left: 70px;
      position: absolute;
    }
  }

  .max-capacity {
    width: 165px;
    margin-bottom: 20px !important;

    .MuiFormLabel-root {
      width: 200px;
    }
  }

  #prereq-courses,
  #replacement-courses,
  #prereq-lp-select {
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
  }

  .relatedCourses {
    margin-bottom: 35px;

    .MuiAutocomplete-tag {
      max-width: 80%;
      width: 80%;

      .category-icon {
        flex: 0;
        width: 20px !important;
        height: 20px !important;
        padding: 0;
        margin-left: 0px;
        position: relative;
        top: 7px;
      }
    }
  }

  .MuiAutocomplete-root {
    margin-top: 15px;
  }
}

.borderBottom {
  border-bottom: 2px solid $light-grey !important;
}

.modal.create-badge {
  .uploadImageWrapper {
    margin: 0;
  }

  .button.small.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.attendee-select,
.current-people-list {
  strong {
    font-size: 16px !important;
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  .flexRow {
    width: 100%;

    .flexRow {
      flex-direction: column;
      align-items: flex-start;

      span {
        font-size: 12px;
        opacity: 0.6;
        text-transform: uppercase;
      }
    }
  }

  .icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
    border-radius: 100%;
    overflow: hidden;
    flex-shrink: 0;

    &.userPicture {
      &.blocked {
        background-image: url("../img/backslash.svg");
      }

      background-size: cover;
      margin-right: 10px !important;
      margin-left: 0px;
    }

    &.group {
      background-image: url("../img/school.svg");
    }

    &.role {
      background-color: #e2f5fb;
    }
  }

  .event-content.calendarLayout {
    padding: 0 !important;

    &:before {
      display: block;
      content: "";
      width: 100%;
      height: 50px;
      background: white;
      position: absolute;
      margin-top: 81px;
      z-index: 10;
      top: 15px;
    }

    .toolbar {
      justify-content: center;
      margin-bottom: -20px;
    }
  }

  .conferenceEventWrapper {
    .subnav {
      margin-top: 50px;
    }

    .sidebar-event {
      padding-top: 60px;
    }

    .event-content {
      margin-top: 85px;
    }
  }

  .icon.room {
    width: 25px;
    height: 25px;
    background-image: url("../img/room.svg");
    background-position: center;
  }

  .currentRoom {
    header {
      display: flex;
      align-items: center;
      font-weight: bold;
    }

    > p {
      margin: 0;

      p {
        font-size: 14px;
        font-weight: normal;
        margin-left: 35px;
        margin-top: 5px;
        margin-bottom: -5px;
      }
    }

    .icon {
      margin-right: 10px;
      margin-top: -2px;
      margin-left: -5px;
    }
  }

  .roomSelect {
    .icon {
      position: absolute;
      margin-left: -35px;
      margin-top: 12px;
    }
  }

  .lockedMessage {
    animation: bounceInDown 0.6s forwards;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    height: 950px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 250px;
    flex-direction: column;
    max-width: 1190px;

    .icon {
      width: 25px;
      height: 25px;
    }
  }

  .calendarLayout {
    opacity: 1;
    max-width: 1485px !important;
  }

  .calendarLayout.disabled {
    transition: 0.3s;

    &:before {
      opacity: 1;
    }

    opacity: 0.3 !important;
    pointer-events: none !important;
  }

  .conferenceCalendar {
    // width: 940px;
    .MuiDrawer-paper {
      max-height: 680px !important;
      margin-top: 20px !important;

      .eventFormControl {
        background: white;
      }
    }
  }

  .event-live-view {
    .conferenceCalendar {
      margin: 0;

      [class*="makeStyles-container"] {
        padding-right: 20px;
        margin-right: -20px;
        overflow: auto !important;
      }
    }

    .adjustRange {
      span {
        font-size: 18px;
      }
    }

    &.isSession {
      padding-top: 140px !important;
    }

    &.isConference {
      padding-top: 155px !important;
    }
  }

  .page.event-single {
    .event-content {
      h3 {
        position: relative;
      }
    }
  }
}

section.conferenceLabel {
  position: fixed;
  left: 0;
  right: 0;
  width: 100% !important;
  top: 50px;
  margin: 0 !important;
  z-index: 8;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
  background-color: $dark-blue;
  padding: 12px !important;

  a,
  p {
    margin: 0;
    color: white !important;
    display: flex;
    align-items: flex-start;
    font-size: 16px;
    font-weight: normal !important;

    .conference-white {
      background-image: url("../img/conference-white.svg");
      width: 25px;
      height: 25px;
      margin-right: 5px;
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .flexRow {
      color: white;
    }

    &:hover {
      .icon {
        margin-right: 20px !important;
      }
    }
  }

  .icon {
    transition: 0.3s;
    width: 25px;
    height: 25px;
    background-image: url("../img/back-white.svg");
    position: relative;
    top: -2px;
  }
}

.appointmentInner.conference-event {
  margin-top: -3px;

  div:nth-child(2) {
    font-size: 8px;
    font-weight: normal;
  }

  header {
    line-height: 18px;
  }

  &.draft {
    header {
      div {
        color: white !important;
      }
    }
  }

  &.isArchived {
    header > div > div > div:first-child {
      padding-left: 15px;
    }
  }

  .icon.archive {
    background-image: url("../img/i-archive-black.svg");
    width: 16px;
    height: 16px;
    position: absolute;
    bottom: 5px;
    left: 5px;
    background-size: 11px;
    border-radius: 16px;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  }
}

.RSVP-status {
  .MuiInputBase-root {
    font-weight: bold;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.7);

    &:before,
    &:after {
      display: none;
    }

    .MuiSelect-root {
      padding: 5px 10px 5px 15px;
      border-radius: 60px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }

      .label {
        position: relative;
        top: -5px;
      }
    }

    .MuiSvgIcon-root {
      display: none;
    }
  }
}
