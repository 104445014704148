.conference-attendance-summary {
  max-width: 1024px;
  position: relative;

  .inputSearch {
    margin: 0 25px 0 10px;
    .MuiInputBase-root {
      width: 100%;
    }
    label[data-shrink="false"] {
      transform: translate(20px, 24px) scale(1);
    }
    input {
      padding-left: 20px;
    }
    .icon.search {
      width: 15px;
      height: 15px;
      background-image: url("../../../img/search.svg");
      position: absolute;
      top: 27px;
    }
  }

  .session-filters {
    .MuiFormControl-root {
      margin-bottom: 0;
    }

    .inputSelect {
      width: 165px;
    }
    .sort-button {
      position: relative;
      top: 10px;
      width: 40px;
      height: 40px;
      padding: 0 !important;
      min-width: 0;
      display: flex;
      align-items: center;
      border-radius: 35px;
      justify-content: center;
    }
    .icon.sortOrder {
      width: 20px;
      height: 20px;
      background-image: url("../../../img/sort.svg");
      transform: scaleY(-1);
      transition: 0.3s;
      &.asc {
        transform: scaleY(1);
      }
    }
  }

  #summary-list {
    list-style-type: none;
    li.row {
      border-radius: 12px;
      margin-bottom: 15px;
      position: relative;
      .capsule {
        margin-right: 20px;
        height: 26px;
        font-weight: normal;
        margin-left: 20px;
        .icon.chair {
          margin-right: 10px;
          background-image: url("../../../img/chair-white.svg");
          width: 20px;
          height: 20px;
        }
      }

      a {
        border-radius: 12px;
        color: black;
        display: flex;
        flex-direction: column;
        transition: 0.3s;
        background-color: white;
        min-height: 80px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        border-left-width: 15px;
        border-left-style: solid;
        position: relative;
      }

      .title {
        font-weight: bold;
        font-size: 18px;
        padding: 20px;
        transition: 0.3s;
        flex-shrink: 0;
        display: flex;
        justify-content: center;

        .roomInfo {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: normal;
          margin-top: -20px;
          .icon {
            background-image: url("../../../img/room-black.svg");
            width: 15px;
            height: 15px;
            margin-right: 5px;
            &.laptop {
              background-image: url("../../../img/laptop-black.svg");
              height: 18px;
              width: 18px;
            }
          }
        }

        .datetime {
          font-weight: normal;
          font-size: 13px;
          opacity: 0.6;
          margin-top: 5px;
        }
        .icon.caret {
          position: relative;
          top: 0px;
          opacity: 0.6;
          transform: rotate(-90deg);
          margin-left: 0px;
          height: 12px;
          width: 12px;
          background-image: url("../../../img/caret.svg");
          opacity: 0;
          transition: 0.3s;
        }
      }

      #attendance-summary {
        padding: 0 20px 20px 20px;
        margin-bottom: -10px !important;
        justify-content: flex-start;
        li {
          margin-right: 50px;
          font-size: 18px;
          strong {
            font-size: 14px;
          }
        }
      }
    }
  }
}
