@import "./variables.scss";

.icon.calendar.white {
  background-image: url("../img/calendar-white.svg");
  background-color: rgba(0, 0, 0, 0) !important;
}

.icon.observation.white {
  background-image: url("../img/observation-white.svg");
  background-color: rgba(0, 0, 0, 0) !important;
}

.icon.calendar.blue {
  background-image: url("../img/calendar-blue.svg");
  background-color: rgba(0, 0, 0, 0) !important;
}

.icon.rsvp {
  background-image: url("../img/rsvp.svg");
}

.icon.rsvp-decline {
  background-image: url("../img/rsvp-decline.svg");
}

.icon.rsvp-tentative {
  background-image: url("../img/rsvp-tentative.svg");
}

.qr-modal {
  .MuiPaper-root {
    border-radius: 18px;
    max-width: 600px;
  }
}

#faq-link {
  .icon {
    width: 20px;
    height: 20px;
    background-image: url("../img/books.svg");
  }
}

#colors-popover {
  .MuiPaper-root {
    padding: 5px;
    width: 255px;
    margin-top: -20px;
    margin-left: 20px;
    border-radius: 15px;

    .row {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .color-button {
        padding: 8px !important;
        margin: 3px;
        min-width: 0px;
        background-color: white;
        border-radius: 18px;
        cursor: pointer;

        &:hover {
          background-color: $light-grey;
        }
      }

      .color {
        width: 20px;
        height: 20px;
        border-radius: 35px;
      }
    }
  }
}

.feedback-question-modal {
  .MuiPaper-root {
    min-width: 70vw;
  }
  .modal-content ul {
    margin-left: -20px;
    margin-right: -20px;
    padding: 0 20px;
    overflow: auto;
    max-height: 70vh;
  }
}
#creditPopoverContainer {
  .MuiPopover-paper {
    margin-top: 5px;
    overflow: visible !important;
  }
}

.feedback-form-popover {
  flex: 1;
  .popover-inner {
    font-size: 14px;
  }
}

.creditPopover {
  position: relative;

  footer {
    margin-top: 20px;
    justify-content: space-between;
  }

  .timeLabel {
    font-size: 14px;
  }

  .triangle {
    display: block;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid white;
    position: absolute;
    top: -8px;
    left: 15px;
  }
}

.editLabel {
  opacity: 0;
  position: absolute;
  transition: 0.3s;
  display: flex;
  align-items: center;
  color: $blue;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  right: 10px;
  background-color: white;
  padding: 2px 5px;
  border-radius: 5px;

  .icon {
    position: relative;
    top: 1px;
    width: 20px !important;
    height: 20px !important;
    margin-right: 2px !important;
  }
}

.participant-filters {
  display: flex;
  margin-bottom: -35px;
  margin-top: 50px;
  position: relative;

  .MuiFormControl-root {
    label {
      background-color: $light-grey;
    }
  }

  .button.small {
    height: 35px;
    margin-left: 10px;

    .icon.plus {
      background-image: url("../img/plus.svg");
      transform: rotate(45deg);
    }
  }

  .MuiFormControl-root .MuiInputBase-root {
    font-size: 14px;
  }

  .MuiTextField-root {
    width: 140px;

    label {
      font-size: 14px;
    }
  }

  .inputSearch {
    position: relative;
    flex: 1;

    .MuiFormControl-root {
      width: 100%;
    }

    label {
      text-transform: none;
    }
  }

  .filterRSVP,
  .filterCheckedIn,
  .filterGroup {
    min-width: 100px;
    margin-left: 20px;
  }
}

.editLink {
  display: block;
  color: black;

  &:hover .editLabel {
    opacity: 1;
  }

  &.disabled {
    pointer-events: none;
    cursor: normal;

    &:hover .editLabel {
      opacity: 0;
    }
  }
}

.statusLabel {
  text-transform: uppercase;
  font-size: 12px;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: $dark-grey;
  justify-content: flex-end;
  flex: 1;
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  bottom: 0;

  .icon {
    width: 20px;
    height: 20px;

    &.draft {
      background-image: url("../img/i-draft.svg");
      background-color: $dark-grey;
      background-size: 12px;
      margin-right: 5px;
      background-position: center top 52%;
      border-radius: 100%;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    }
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.conference-live-view-layout {
  .subnav.conference {
    .publish-button {
      display: none;
    }
  }
}

.page.event-single-view {
  max-width: 1024px;
  margin-left: 335px;
  padding-top: 95px;
  &.event-live-view {
    padding-top: 115px;
  }
  &.isConference {
    padding-top: 120px;
    max-width: 100%;

    &.isFullScreen {
      background-color: white;

      .conferenceScheduleWrapper {
        position: fixed;
        top: 100px;
        right: 0;
        left: 0;
        z-index: 10;
        background-color: white;

        .MuiPaper-root,
        .calendarInner {
          box-shadow: none;
        }

        &.multiDay {
          #conference-tabs-container {
            border-radius: 0;

            .MuiTabs-root {
              min-height: 55px;
            }
          }
        }
      }

      .conferenceCalendar {
        .calendarInner {
          &.isEditing > .MuiPaper-root {
            background-color: white;
          }

          .scheduleWrapper {
            height: calc(100vh - 250px);
            opacity: 1 !important;

            &.shift {
              &:before {
                content: "";
                display: block;
                position: fixed;
                background-color: rgba(0, 0, 0, 0.6);
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                z-index: 4;
              }
            }

            > div > div {
              overflow: auto;
            }
          }
        }
      }

      .newEventPosition {
        right: 100px;
        left: 100px;
        top: 80px;
        bottom: 80px;
        display: flex;
        justify-content: center;
        position: fixed;
        z-index: 15;
      }

      .newEventWrapper.shift {
        border-radius: 12px;
        overflow: hidden;
        max-width: 820px;
        min-width: 820px;
        position: relative;

        .button.small.back {
          top: 7px;
        }

        .subnav {
          top: 0;
        }

        .event-content {
          margin-top: 80px;
        }

        .sidebar-event {
          top: 51px;
        }

        .conference-event-edit {
          overflow: auto;
          max-height: 100%;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }

    table {
      max-width: 100%;
    }

    .scheduleWrapper {
      > div > div > div > div {
        width: 100%;
      }
    }
  }

  &.isSession {
    padding-top: 120px;
  }

  &:after {
    display: block;
    content: "";
    background-color: white;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 20px;
    width: 285px;
  }

  .attendee-list {
    padding: 0;
    margin-top: 50px !important;
  }

  h3 {
    color: #969696;
    font-size: 18px;
    font-weight: 600;
    margin: 20px 0 0 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 575px;
  }

  .sidebar-event-view {
    width: 285px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    position: absolute !important;
    bottom: auto !important;
    overflow-x: hidden !important;
    z-index: 10;
    padding-top: 50px !important;

    li {
      margin-left: -20px;
    }

    &.isConference,
    &.isSession {
      padding-top: 49px !important;
    }

    .icon.feedback {
      background-image: url("../img/feedback-black.svg");
    }

    li a {
      color: black;
      display: flex;
      align-items: center;
      height: 60px;
      width: 100%;
    }

    .entity-meta {
      margin-top: -5px;
    }

    .entity-meta .backButton {
      .icon {
        margin-right: 10px;
        width: 20px;
        height: 17px;
        background-image: url("../img/back.svg");
      }

      padding: 20px;
      margin-left: -20px;
      margin-top: -20px;
      border-radius: 0px;
      transition: 0.4s;
      width: 330px !important;

      .MuiButton-label {
        justify-content: flex-start;
        font-size: 18px;
        color: #59c3e5;
        text-transform: capitalize;
        font-weight: normal;
      }
    }

    .googleMapsWrapper {
      height: 250px;
      width: 100%;
      border-radius: 8px;
      overflow: hidden;
      margin-bottom: 20px;
    }

    section {
      font-size: 15px;
      width: 285px;
      line-height: 22px;
      margin: 0 0 0 -20px;
      padding: 20px;

      a {
        color: #039be5;
        font-weight: bold;
      }

      .icon {
        flex-shrink: 0;
        width: 25px;
        height: 25px;
        margin-right: 10px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        &.star {
          background-image: url("../img/star.svg");
        }

        &.room {
          background-image: url("../img/room.svg");
        }

        &.clock {
          background-image: url("../img/time.svg");
        }

        &.calendar {
          background-image: url("../img/event-calendar.svg");
        }

        &.location {
          position: relative;
          top: -2px;
          background-image: url("../img/location-color.svg");
        }
      }
    }

    .count {
      color: #59c3e5;
      font-size: 18px;
      font-weight: 700;
    }

    .dateTime {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding: 0;
      width: 325px;

      strong {
        text-transform: uppercase;
        font-size: 12px;
        opacity: 0.5;
        display: block;
      }

      .editLink {
        padding: 12px 20px;
        display: flex;
        align-items: flex-start;
        color: black;
      }
    }

    .locationWrapper {
      display: flex;
      align-items: flex-start;
      padding: 0;
      width: 325px;

      .editLink {
        padding: 20px;
        display: flex;
        align-items: flex-start;
        color: black;
      }

      strong {
        display: block;
      }

      .link {
        color: darken($blue, 15%);
      }

      .highlight {
        color: $blue;
      }
    }
  }

  section {
    margin: 40px 0;

    p {
      color: black !important;

      a {
        color: #039be5 !important;
        font-weight: bold;
      }
    }
  }

  .eventImageWrapper {
    height: 150px;
    width: 325px;
    margin-left: -20px;
    background-position: center;
    background-repeat: no-repeat;
  }

  h1 {
    font-size: 28px;
    text-align: left;
    font-weight: 600;
    display: flex;
    align-items: center;

    @media (max-width: $screen-sm-max) {
      font-size: 20px;
      line-height: 28px;
    }

    .actions {
      text-align: right;
      margin-left: 25px;
      position: relative;

      .register-button {
        position: absolute;
        right: 0;
        width: 390px;
        top: -28px;
      }

      p {
        font-size: 14px;
        margin: 5px 0 0;
      }

      .button.small {
        margin-right: 35px;
      }

      .isAttending {
        position: relative;
        top: 5px;
      }

      .rsvp-info {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        display: flex;
        align-items: center;

        .icon {
          width: 25px;
          height: 25px;
          background-repeat: no-repeat;
          margin-left: 10px;
        }
      }
    }

    .title {
      display: flex;
      align-items: center;
      position: relative;
      margin-right: 175px;

      .eventCategory {
        font-weight: normal;
        display: flex;
        text-transform: uppercase;
        align-items: center;
        font-size: 12px;

        .chip {
          border: 4px solid $blue;
          background-color: white;
          width: 8px !important;
          border-radius: 100%;
          height: 8px !important;
          margin-right: 5px;
          margin-top: 0;
          padding: 0;

          &.draft {
            background-color: $dark-grey;
          }
        }
      }

      .icon {
        margin-left: 20px;
        width: 20px;
        height: 20px;
        position: relative;
        top: -1px;
      }

      flex: 1;
    }
  }

  .badgeWrapper {
    font-size: 18px;
    font-weight: bold;

    .badge {
      width: 75px;
      height: 75px;
      border-radius: 100%;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
      margin-right: 15px;
    }
  }

  .event-details {
    animation: fadeIn 0.3s;

    .personnel-list {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: flex;
        align-items: center;
        position: relative;
        margin: 25px 0;

        .userImage {
          flex-shrink: 0;
        }

        .personnel-info {
          display: flex;
          flex-direction: column;
          margin-left: 15px;

          span {
            font-size: 12px;
            opacity: 0.5;
            font-weight: bold;
            text-transform: uppercase;
          }
        }

        .icon {
          width: 30px;
          height: 30px;
          position: absolute;
          margin-bottom: 0px;
          left: 30px;
          bottom: -5px;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
          background-size: 35px;
          background-position: top 5px left -3px;
          background-color: white;
          border-radius: 30px;

          &.manager {
            background-size: 30px;
            background-position: top 2px center;
          }

          &.secretary {
            background-position: top -3px center;
            background-size: 25px;
          }
        }
      }

      &.presenter {
        margin: 0;

        li {
          flex-direction: column;
          align-items: flex-start;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    p {
      font-size: 15px;
    }

    section a {
      color: black;
    }

    .eventCoursesWrapper {
      flex: 1;
      margin-right: 15px;

      .category-icon {
        width: 30px;
        height: 30px;
        display: block;
        border-radius: 100%;
        box-shadow: 1px 4px 5px 0 rgba(0, 0, 0, 0.15);
        margin-right: 10px;
        flex-shrink: 0;
      }

      .course {
        color: black;
        padding: 15px;
        font-size: 16px;
        font-weight: bold;
        display: flex;
        align-items: center;
        background-color: rgba(0, 0, 0, 0);
        border-radius: 10px;
        cursor: pointer;
        transition: 0.3s;

        .title {
          flex: 1;
        }

        .icon {
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-size: contain;
          position: relative;
          top: 2px;

          &.laptop {
            background-image: url("../img/laptop.svg");
          }

          &.inbox {
            background-image: url("../img/inbox.svg");
          }
        }
      }
    }

    .presenter {
      align-items: flex-start;
      margin-bottom: 25px;
      max-width: 678px;
      margin-top: 25px;
    }

    .presenterInfo {
      font-size: 15px;
      color: black !important;

      strong {
        display: block;
        font-size: 24px;
      }
    }

    .attachment {
      font-weight: bold;
      font-size: 16px;
      display: flex;
      align-items: center;
      padding: 15px 0;
      border-radius: 10px;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0);
      transition: 0.3s;

      .icon {
        margin-right: 10px;
        width: 30px;
        height: 30px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        &.image {
          background-size: cover;
          border-radius: 8px;
        }
      }
    }
  }

  .event-updates {
    animation: fadeIn 0.3s;

    ul {
      margin: 0;
      padding: 0;

      li {
        background-color: white;
        padding: 20px;
        box-shadow: 1px 4px 5px 0 rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        margin-bottom: 20px;

        header {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          strong {
            flex: 1;
          }

          .timestamp {
            font-size: 12px;
            opacity: 0.5;
            font-weight: 700;
            font-weight: bold;
          }
        }
      }
    }
  }

  .userPicture,
  .userPictureWrapper {
    width: 40px;
    height: 40px;
    background-size: cover;
    margin-right: 10px;
    background-image: url("../img/profile-pic.svg");
    border-radius: 100%;
  }

  .userPictureWrapper {
    box-shadow: 1px 4px 5px 0 rgba(0, 0, 0, 0.3);
  }

  .userPicture {
    margin: 0;
  }

  .newMessage {
    margin-top: -10px;
    width: 100%;

    label {
      background-color: $light-grey;
    }

    footer {
      .MuiFormControl-root {
        margin-top: -15px;
      }

      height: 55px;
      display: flex;
      justify-content: flex-end;
      padding: 25px 0;
    }

    .ql-container,
    .ql-editor {
      height: 150px;
      min-height: 0px;
    }
  }

  .event-updates {
    ul {
      margin-top: 20px;
      list-style-type: none;
    }

    footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      button {
        margin-left: 20px;
      }

      em {
        font-size: 14px;
      }
    }
  }

  .learner-name {
    text-transform: capitalize;
  }

  .event-attendees {
    animation: fadeIn 0.3s;
    max-width: 1024px;
    position: relative;
    .user-label-user-name.zero-group {
      position: relative;
      top: 10px;
    }
    .exportContainer {
      z-index: 3;
      top: -50px;
      right: 0;
      position: absolute;
    }

    .userInitials-initials {
      font-size: 12px;
      position: relative;
      top: -1px;
    }

    .MuiTextField-root [data-shrink="true"] {
      color: $blue;
    }

    .MuiTableHead-root {
      button {
        color: black;
      }
    }

    .page-widgets {
      margin-top: 35px;
      align-items: flex-start;
      flex-wrap: wrap;

      .widget {
        flex: 1;
        max-width: 50%;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
        border-radius: 12px;
        height: 181px;
        padding: 0;
        margin-left: 25px;

        .button.small span {
          font-size: 12px !important;
        }

        header {
          margin: 0;
          padding: 15px 20px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          display: flex;
          font-size: 18px;
          font-weight: bold;
          align-items: center;

          .icon {
            width: 30px;
            height: 30px;
            margin-right: 10px;
          }

          .icon.join-code {
            background-image: url("../img/join-code.svg");
          }

          .icon.plus-one {
            background-image: url("../img/plus-one.svg");
          }

          .icon.check-in {
            background-image: url("../img/check-in.svg");
          }
          .icon.invitees {
            background-image: url("../img/invitation-color.svg");
          }
        }

        .icon.qr-code {
          background-image: url("../img/qr-code.svg");
        }

        .icon.link {
          background-image: url("../img/link.svg");
        }

        .icon.magic-wand {
          background-image: url("../img/magic-wand.svg");
        }

        .inner {
          p {
            margin: 0;
          }

          padding: 20px;
        }

        .linkCopied {
          position: absolute;
          font-size: 14px;
          left: 20px;
          bottom: 20px;
          font-weight: bold;
          animation: fadeOut 2s;
          animation-delay: 2s;
          background-color: white;
          padding-right: 20px;
        }

        footer {
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          padding: 10px;

          .button .icon {
            width: 15px;
            height: 15px;
          }
        }

        &.check-in-widget {
          .inner {
            p {
              margin: 0;
            }

            padding: 20px;
            margin-bottom: -13px;
          }
        }

        &:first-child {
          margin-left: 0;
        }

        &.join-code-widget {
          width: 100%;
          .inner {
            margin-top: 20px;
            padding: 0 10px;
            margin-bottom: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
          }

          footer {
            justify-content: space-between;

            .button.small {
              margin-left: 0 !important;
            }
          }
        }
      }
    }

    .attendance-container {
      position: relative;

      .capsuleWrapper {
        position: absolute;
        left: 0;
        bottom: -14px;
        right: 0;
        display: flex;
        justify-content: center;
      }

      .icon.chair {
        background-image: url("../img/chair-white.svg");
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }

    #attendance-summary {
      animation: fadeInUp 0.3s;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
      background-color: white;
      padding: 20px;
      justify-content: space-around;
      border-radius: 12px;
      margin-bottom: 0;

      .loadingWrapper {
        position: relative;
        margin-top: 0;
      }

      button {
        padding: 0 20px;
        border-radius: 60px;
        &:hover {
          background-color: $light-grey;
        }
        &.active {
          background-color: #e7e7e7;
          background-color: lighten($green, 30%);
        }
      }

      li .MuiButton-label {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: bold;

        strong {
          font-size: 14px;
          text-transform: none;
          font-weight: normal;
        }
      }
    }

    @media (max-width: 1245px) {
      #attendance-summary {
        button {
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 5px;
          padding-bottom: 5px;

          strong,
          span {
            font-size: 12px !important;
          }
        }
      }

      .page-widgets {
        flex-direction: column;

        &.manual {
          padding-top: 0;
          margin-bottom: 20px;
        }

        .widget {
          top: 0;
          flex: 1;
          max-width: 100%;
          margin-left: 0;

          &.check-in-widget {
            flex: 0;
            width: 100%;
            margin-top: 20px;
            footer {
              position: relative;
            }
          }
        }
      }
    }

    footer.flexRow {
      justify-content: center;
      align-items: center;
      margin-bottom: 25px;
      position: relative;

      .button.primary {
        text-transform: capitalize;
        font-size: 16px;
        font-weight: normal;
        border-radius: 48px;
        background-color: $green;
        font-weight: bold;
        padding: 8px 20px;

        &:hover {
          background-color: lighten($green, 15%);
        }
      }
    }

    header {
      position: relative;
      margin-bottom: 40px;
      margin-top: -20px;
      display: flex;
    }

    .MuiTableContainer-root {
      background-color: rgba(0, 0, 0, 0) !important;
      overflow: visible;

      th {
        &:first-child {
          padding-left: 0;
        }

        button {
          border-radius: 40px;
          font-size: 12px;
          font-weight: bold;
          display: flex;
          align-items: center;

          .icon.sort {
            margin-left: 0;
            top: 0;
            width: 15px;
            height: 15px;
            background-image: url("../img/sort.svg");
          }
        }
      }
    }

    .pdCreditCell {
      display: inline-flex;
      margin-left: 15px;

      .edit {
        background-size: contain;
        width: 18px;
        height: 18px;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        top: 6px;
        background-image: url("../img/edit-black.svg");
        transition: 0.3s;
        opacity: 0;
        left: -10px;
      }

      &:hover {
        .edit {
          opacity: 0.7;
          left: 5px;
        }
      }

      .button.small {
        color: black;
        text-transform: none;
      }
    }

    .alignRight {
      text-align: right;
      padding-right: 0;

      &.checkedIn {
        width: 100px;
        position: relative;

        .blocked-user-wrapper {
          animation: fadeIn 0.4s forwards;
        }

        .blocked-user-warning {
          background-color: #e85664;
          color: white;
          padding: 10px;
          text-align: left;
          border-radius: 12px;
          position: absolute;
          z-index: 2;
          top: -2px;
          right: 65px;
          width: 300px;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
          animation: fadeOut 1s forwards;
          animation-delay: 3s;
        }

        .button {
          margin-right: 0 !important;
        }
      }
    }

    table {
      margin-top: 40px;

      tbody {
        background-color: rgba(0, 0, 0, 0) !important;
      }

      .loadingWrapper {
        strong {
          text-transform: none;
        }
      }

      tr {
        &:hover {
          background-color: rgba(0, 0, 0, 0) !important;
          cursor: inherit;
        }
      }

      .MuiTableCell-root {
        border-bottom: 0px !important;
      }

      td {
        padding: 10px;
        font-weight: bold;
        align-items: center;

        &:first-child {
          padding: 10px 0;
        }

        &:last-child {
          padding: 10px 0;
        }

        .flexRow {
          padding: 10px 0;

          &.switch {
            justify-content: flex-end;
          }
        }

        .name {
          flex: 1;
        }

        .status {
          display: flex;
          align-items: center;
          text-align: right;
          justify-content: flex-end;
          position: relative;
          font-size: 12px;
          text-transform: uppercase;
          #RSVP-status-select {
            padding-right: 0;
            border-radius: 8px;
            .icon.rsvp {
              position: relative;
              top: 3px;
            }
            .label {
              position: relative;
              top: -3px;
            }
          }
        }

        .icon {
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-size: contain;
          margin-left: 10px;
          opacity: 1 !important;

          &.none {
            background-image: url("../img/rsvp-tentative.svg");
          }
        }
      }
    }
  }

  .sidebar-tabs {
    width: 325px;
    padding: 0;
    margin: 0;
    line-height: 19px;

    li {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      font-weight: bold;
      font-size: 18px;
      padding: 0 20px;
      height: 59px;
      display: flex;
      align-items: center;
      transition: 0.3s;
      cursor: pointer;

      .icon {
        width: 23px;
        height: 23px;
        margin-right: 10px;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        top: -1px;
        background-position: center;

        &.message {
          background-image: url("../img/message.svg");
        }

        &.attendees {
          background-image: url("../img/attendees.svg");
        }

        &.feedback-form {
          background-image: url("../img/feedback-form.svg");
        }

        &.sessions {
          background-image: url("../img/calendar.svg");
        }

        &.details-info {
          background-image: url("../img/content-writing.svg");
        }

        &.conference-summary {
          background-image: url("../img/list.svg");
        }
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
      }

      &.active {
        border-top: 1px solid rgba(0, 0, 0, 0);
        background-color: $green;
      }
    }
  }
}
