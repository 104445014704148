@import "../../styles/variables.scss";

#sidebar.marketplace-sidebar {
  .filters {
    label {
      padding: 0px 6px;
      background: white;
    }
    fieldset {
      display: block;
    }
    input {
      border-bottom: none;
    }
  }
}

#partner-single-modal {
  .icon.caret {
    transform: rotate(90deg);
    position: relative;
    left: 3px;
    background-image: url("../../img/caret-blue.svg");
  }
  .partner-contact-info {
    margin-top: 25px;
    p {
      font-size: 12px;
    }
  }
  footer {
    margin-top: 0px;
  }
  p {
    line-height: 18px;
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 0;
  }
  p a {
    font-weight: 700;
    color: darken($blue, 15%);
  }
  [data-hs-forms-root="true"] {
    width: 575px;
  }
  .alice-carousel__wrapper li {
    overflow: hidden;
    border-radius: 12px;
  }

  .partner-carousel-item {
    transform: scale(1);
    transition: 0.4s;
    &:hover {
      transform: scale(1.02) !important;
    }
  }

  .MuiDialog-paper {
    max-width: 575px;
    overflow: auto !important;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  .alice-carousel__dots {
    text-align: left !important;
  }
  .icon.laptop {
    background-image: url("../../img/laptop.svg") !important;
  }
}

.partner-marketplace-preview {
  .preview-tooltip {
    margin-top: 10px;
    opacity: 0;
    position: relative;
    top: 10px;
    transition: 0.4s;
  }
  &:hover {
    .preview-tooltip {
      top: 0;
      opacity: 1;
    }
  }
}

.partner-settings {
  .uploadImageWrapper {
    margin-top: 0;
    max-width: 200px;
    .fileUpload {
      width: 125px;
      height: 125px;
      margin-bottom: 20px;
      .tooltip {
        width: 125px;
        height: 125px;
        background-color: $light-grey;
        margin-top: 0;
        border-radius: 12px;
        font-size: 14px;
        line-height: 16px;
        button {
          display: none;
        }
      }
    }
    .tooltip button {
      position: absolute;
      width: 25px;
      height: 25px;
      background-size: contain;
      background-position: center;
      background-image: url("../../img/close.svg");
      border-radius: 50px;
      right: 8px;
      top: 8px;
      padding: 0px !important;
      color: transparent;
      background-color: rgba(0, 0, 0, 0.4);
      transform: scale(1);
      transition: 0.3s;
      &:hover {
        transform: scale(1.1);
        background-color: rgba(0, 0, 0, 0.7);
      }
    }
    input {
      height: 125px;
      width: 125px;
    }
    .uploadedImage {
      width: 125px;
      height: 125px;
      border-radius: 12px;
      background-size: contain !important;
    }
  }

  .image-grid {
    .fileUpload,
    .tooltip,
    input,
    .uploadedImage {
      box-shadow: none;
      margin-right: 0 !important;
      width: 100% !important;
      margin-bottom: 0;
    }
  }

  .partner-overview {
    .quill .ql-toolbar {
      &:before {
        content: "Description";
        flex: 1;
        display: block;
        font-size: 12px;
        position: relative;
        top: 3px;
        left: 5px;
      }
    }
  }
  .quill .ql-toolbar {
    display: flex;
    justify-content: flex-end;
  }
  .quill .ql-editor {
    margin-bottom: 35px;
    border-radius: 8px;
    max-height: 150px;
    min-height: 150px;
    border: 1px solid #c7c7c7;
  }
}
.icon.diamond {
  background-image: url("../../img/diamond.svg");
}
.icon.free {
  background-image: url("../../img/free.svg");
}
.icon.gear-color {
  background-image: url("../../img/gear-color.svg");
}
.icon.check {
  background-image: url("../../img/check.svg");
}
.icon.puzzle-pieces {
  background-image: url("../../img/puzzle-pieces.svg");
  margin-left: 10px;
}
.store-item {
  transition: 0.3s;
  transform: scale(1);
  p {
    margin-top: 5px;
    margin-bottom: -5px;
    line-height: 16px;
  }
  p a {
    font-weight: 700;
    color: darken($blue, 15%);
  }
  &:hover {
    box-shadow: 0 0 15px rgba(3, 3, 3, 0.15) !important;
    transform: scale(1.02) !important;
  }
}
