@import "variables";

.page.unauthorized {
  position: fixed;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 80px;
  text-align: center;
  margin: 0 auto;
  max-width: 500px;
  .content {
    animation: fadeInUp 0.5s forwards;
  }
  h2 {
    justify-content: center;
    font-size: 32px;
  }
  p {
    font-size: 16px;
    line-height: 20px;
  }
  .icon.warning {
    animation: slideDown 0.5s;
    margin: 0 auto;
    width: 100px;
    height: 100px;
    background-image: url("../img/computer-warning.svg");
  }
}
