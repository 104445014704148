@import "./variables.scss";

.buddy-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  overflow: hidden;

  &.inline {
    margin-top: 0;
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    max-width: 100%;

    .buddy-message {
      padding: 20px 10px;
      position: relative;
    }

    .buddy-image {
      margin: 0;
      flex-shrink: 0;
      margin-right: 15px;
      top: 0;
      display: block;
      position: relative !important;
    }
  }
}

.buddy-select {
  flex-wrap: wrap;
  min-height: 365px;
  width: 480px;

  .grid-item {
    opacity: 0;
    width: 25%;
    height: 25%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    animation: fadeInUp 0.5s forwards;
  }

  .loadingWrapper {
    position: absolute;
    flex-direction: column;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .buddy-image {
    width: 45px;
    height: 45px;
    padding: 7px;
    transition: 0.3s;
  }

  .buddy-item {
    height: 100px;
    padding: 15px 15px 5px 15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.5s;
    width: 100%;
    margin: 0 5px;

    &:hover {
      background-color: $light-grey;
    }

    &.active {
      background-color: $green;

      .buddy-image {
        width: 60px;
        height: 60px;
      }
    }
  }
}

.buddy-message {
  background-color: $greenAccent;
  border-radius: 10px;
  padding: 20px 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  text-align: center;
  .buddy-image {
    position: absolute;
    margin-top: -40px;
  }

  y p {
    margin: 0;
    text-align: center;
  }

  &.inline {
    position: relative;
    padding: 10px 15px;

    svg {
      position: absolute;
      left: -12px;
      height: 20px;
    }

    .buddy-image {
      margin-top: -10px;
      left: -50px;
      width: 25px;
    }
  }
}

.buddy-label {
  text-transform: uppercase;
  font-size: 11px;
  margin-top: 5px;
  font-weight: bold;
}

.buddy-image {
  width: 40px;
  height: 40px;
  background-image: url("../img/buddies/computer-girl.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transform: scale(1);
  transition: 0.3s;

  &.darwin {
    background-image: url("../img/buddies/darwin.svg");
  }

  &.hooter {
    background-image: url("../img/buddies/hooter.svg");
  }

  &.marvin {
    background-image: url("../img/buddies/marvin.svg");
  }

  &.merle {
    background-image: url("../img/buddies/merle.svg");
  }

  &.mila {
    background-image: url("../img/buddies/mila.svg");
  }

  &.nita {
    background-image: url("../img/buddies/nita.svg");
  }

  &.ocho {
    background-image: url("../img/buddies/ocho.svg");
  }

  &.pinchy {
    background-image: url("../img/buddies/pinchy.svg");
  }

  &.ribbs {
    background-image: url("../img/buddies/ribbs.svg");
  }

  &.rikki {
    background-image: url("../img/buddies/rikki.svg");
  }

  &.samuel {
    background-image: url("../img/buddies/samuel.svg");
  }

  &.thor {
    background-image: url("../img/buddies/thor.svg");
  }

  &.wingman {
    background-image: url("../img/buddies/wingman.svg");
  }

  &.bindy {
    background-image: url("../img/buddies/bindy.svg");
  }

  &.xiong.mao,
  &.xiongmao {
    background-image: url("../img/buddies/xiongmao.svg");
  }

  &.yodie {
    background-image: url("../img/buddies/yodie.svg");
  }
}

.inline-buddy {
  animation-delay: 0.5s;
  animation: fadeIn 1s forwards;
  width: 35px;
  height: 35px;
  margin-right: 15px;
  z-index: 18;
}
