.tag-wrapper {
  text-transform: lowercase;
}
.tagSelectWrapper {
  .loadingWrapper {
    position: absolute;
    right: 10px;
    margin-top: -30px;

    .sk-spinner div {
      width: 15px;
      height: 15px;
    }
  }

  .selected {
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
  }

  .suggestions {
    position: absolute;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 17%);
    width: 235px;
    margin-top: 5px;
    border-radius: 10px;
    z-index: 5;
    opacity: 1;
    padding: 10px !important;

    ul {
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;

      li {
        cursor: pointer;
        padding: 5px 15px 5px 5px;
        background-color: #e6e6e6;
        border-left: 5px solid darken(#e6e6e6, 6%);
        font-weight: bold;
        transition: 0.3s;
        margin: 0 10px 0px 0 !important;
        border-radius: 30px;
        font-size: 12px;
        display: flex;
        align-items: center;
        &:before {
          margin-right: 5px;
          content: "";
          display: block;
          width: 10px;
          height: 10px;
          background-image: url("../img/tag.svg");
        }

        mark {
          background: white;
          border-radius: 5px;
          text-transform: lowercase;
        }

        &:hover {
          background-color: darken(#e6e6e6, 6%);
        }
      }
    }
  }

  .tagInput {
    input {
      min-width: 200px;
      height: 24px;
      font-size: 14px;
      font-family: "Nunito";
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.35);
      background-color: transparent;
      background: transparent;
      text-transform: lowercase;
      &::placeholder {
        text-transform: initial;
      }
    }

    input:focus {
      outline: none;
    }
  }
}

.tag-wrapper {
  cursor: pointer;
  padding: 5px 10px 5px 5px !important;
  background-color: #e6e6e6;
  border-left: 5px solid darken(#e6e6e6, 6%);
  font-weight: bold;
  margin: 5px 5px 2.5px 0 !important;
  border-radius: 30px;
  font-size: 12px;
  display: inline-flex;
  align-items: center;

  &:before {
    margin-right: 5px;
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    background-image: url("../img/tag.svg");
  }

  button {
    border: none;
    background-color: transparent;
    margin-left: 5px;
    color: transparent;
    border-radius: 100%;
    text-indent: -999px;
    height: 20px;
    width: 20px;
    position: relative;
    cursor: pointer;
    opacity: 0.8;
    transition: 0.2s;
    margin-top: 0;
    &:hover {
      opacity: 1;
    }

    &:before {
      content: "";
      display: block;
      opacity: 0.3;
      background-image: url("../img/x-circle.svg");
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}
