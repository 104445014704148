@import "./variables.scss";

.page.sign-up {
  .buddy-container {
    max-width: 540px;
  }
}

.MuiBox-root.learning-buddy-animation {
  animation: fadeInUp 1s;
}

.MuiBox-root.fade-in-delay {
  opacity: 0;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}
.sign-up-user-form.domain-error {
  .buddy-message {
    p {
      margin: 0;
      padding-top: 2.5px;
      padding-bottom: 2.5px;
      a {
        font-weight: bold;
        color: black;
      }
    }
  }
}
