@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,600;0,700;0,800;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Edu+QLD+Beginner:wght@400..700&family=Grape+Nuts&family=Italianno&family=Just+Another+Hand&family=Mrs+Saint+Delafield&family=Ms+Madi&family=Over+the+Rainbow&family=Playwrite+AR:wght@100..400&family=Qwitcher+Grypen:wght@400;700&family=Vibur&display=swap");

@import "variables";

body {
  background-color: $light-grey;
  padding: 0 !important;
  margin: 0;

  > iframe {
    display: none !important;
  }

  div,
  p,
  span,
  strong,
  button,
  a,
  li,
  label,
  th,
  td,
  em {
    font-family: "Nunito", sans-serif !important;
  }

  .active-signature {
    position: relative;
    top: 8px;
    display: flex;
    flex-direction: column;
  }

  .signatureSelect {
    .signature-select-option {
      height: 100px;
      border: 3px solid #eaeaea;
      border-radius: 12px;
      cursor: pointer;
      &:hover,
      &.active {
        border: 3px solid $green;
      }
    }
  }

  .signature-text {
    font-size: 28px;
    font-optical-sizing: auto;
    font-style: normal;

    &.Edu-QLD-Beginner {
      font-family: "Edu QLD Beginner", serif !important;
    }
    &.Playwrite-AR {
      font-size: 24px;
      font-family: "Playwrite AR", serif !important;
      line-height: 1.1;
    }
    &.Mrs-Saint-Delafield {
      font-size: 34px;
      font-family: "Mrs Saint Delafield", serif !important;
    }
    &.Italianno {
      font-size: 34px;
      font-family: "Italianno", serif !important;
    }
    &.Just-Another-Hand {
      font-size: 34px;
      font-family: "Just Another Hand", serif !important;
    }
    &.Ms-Madi {
      font-size: 32px;
      font-family: "Ms Madi", serif !important;
    }
    &.Over-The-Rainbow {
      font-family: "Over the Rainbow", serif !important;
      line-height: 1.1;
    }
    &.Vibur {
      font-size: 34px;
      font-family: "Vibur", serif !important;
    }
    &.Qwitcher-Grypen {
      font-size: 38px;
      font-family: "Qwitcher Grypen", serif !important;
    }
    &.Grape-Nuts {
      font-size: 34px;
      font-family: "Grape Nuts", serif !important;
    }
  }

  .MuiTextField-root label[data-shrink="true"] {
    color: #444;
  }

  .inputSelect label.Mui-focused {
    color: $blue !important;
  }

  .inputSelect.attract label.Mui-focused {
    color: rgba(0, 0, 0, 0.8) !important;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $blue !important;
  }

  .MuiInput-underline:after {
    border-bottom: 2px solid $blue !important;
  }

  .Mui-checked {
    color: $green !important;
  }

  .inputSelect {
    .MuiSelect-select {
      padding-left: 0;
    }
  }
}
.icon.reviewer-group {
  border-radius: 0;
  overflow: visible;
  background-image: url("../img/reviewer-group.svg");
}
.icon.add-friend {
  background-image: url("../img/add-friend.svg");
}
.icon.pre-approval-blue {
  background-image: url("../img/pre-approval-blue.svg");
}
.userPicture {
  background-size: cover !important;
}

.datepicker-form-control {
  label {
    margin-left: -15px;
  }

  .MuiOutlinedInput-root {
    fieldset {
      border: none;
      border-bottom: 1px solid #888;
      border-radius: 0;
    }

    input {
      padding-left: 0;
    }
  }
}

.subscription-warning {
  position: fixed;
  bottom: 35px;
  z-index: 90;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  animation: fadeInUp 1.5s forwards;
  padding-left: 25px;
  padding-right: 25px;

  .inner {
    background-color: #d9ed58;
    padding: 25px 40px;
    border-radius: 100px;
    font-size: 18px;
    cursor: pointer;
    opacity: 1;
    transition: 0.3s;
    position: relative;

    &.danger {
      background-color: #ea5757;

      p,
      strong,
      a {
        color: white;
      }
    }

    .dismissWrapper {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -30px;
      display: flex;
      justify-content: center;
    }

    .dismiss {
      text-transform: uppercase;
      font-size: 12px;
      opacity: 0;
      transition: 0.3s;
      font-weight: bold;
      background-color: darken($light-grey, 5%);
      padding: 5px 15px;
      border-radius: 20px;

      &:hover {
        background-color: darken($light-grey, 15%);
      }
    }

    &:hover {
      .dismiss {
        opacity: 1;
      }
    }

    a {
      color: black;
      font-weight: bold;
      text-decoration: underline !important;
    }

    strong {
      font-size: 24px;
    }

    p {
      margin: 0;
      text-align: center;
    }

    .icon.renew {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      background-image: url("../img/renew.svg");
    }
  }
}

.learnMore {
  display: inline-flex;
  align-items: center;
  margin-left: 20px;
  position: relative;
  top: -1px;

  &:hover {
    .icon {
      margin-right: 10px;
    }

    a {
      color: rgba(0, 0, 0, 1);
    }
  }

  .icon {
    transition: 0.3s;
    width: 18px;
    height: 18px;
    position: relative;
    margin-right: 5px;
    top: 4px;
  }

  a {
    transition: 0.3s;
    color: rgba(0, 0, 0, 0);
    font-size: 14px;
  }
}

.react-colorful__saturation {
  border-radius: 10px;
}

.MuiTabs-flexContainer {
  button {
    color: black !important;
  }

  .MuiButtonBase-root {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.react-colorful__pointer {
  border: 4px solid #fff;
}

.react-colorful__last-control {
  border-radius: 40px;
}

.moreIndicator {
  position: relative;
  top: -1px;
  font-size: 10px;
}

.MuiSwitch-track {
  background-color: $green !important;
}

.MuiChip-deleteIcon {
  transition: 0.3s !important;
  color: rgba(0, 0, 0, 0.7);
}

h2 {
  font-size: 22px;
  display: flex;
  align-items: center;
  font-weight: 600;
  height: 33px;
  margin: 15px 0;

  .title {
    flex: 1;
  }

  .icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    position: relative;
    top: -1px;

    &.templates {
      background-size: 24px;
    }
  }
}

h3 {
  text-transform: uppercase;
  color: $dark-grey;
  font-size: 16px;
}

.flexRow {
  display: flex;
  align-items: center;
}

.loadingMoreContainer {
  position: fixed;
  bottom: 80px;
  display: flex;
  justify-content: center;
  z-index: 30;
  left: 315px;
  right: 0;
  max-width: 1440px;
}

.page {
  padding: 35px;
  max-width: 1440px;

  &.entityList {
    padding-bottom: 200px;

    .MuiTableRow-head th {
      line-height: 14px;
    }

    .icon.mandated.training {
      background-image: url("../img/mandatory.svg");
    }
    td.selectable {
      position: relative;
      padding-left: 0 !important;
      transition: padding-left 0.3s;
    }

    td.selectable:hover,
    td.selectable.hasSelection {
      padding-left: 35px !important;

      .entitySelect {
        opacity: 1;
        left: 0;
      }
    }

    .entitySelect {
      position: absolute;
      opacity: 0;
      width: 40px;
      left: -10px;
      height: 40px;
      margin-right: -20px;
      transition: 0.3s;
    }
  }

  &.hasSidebar {
    padding-left: 315px;
    padding-top: 70px;

    .loadingWrapper.fullPage {
      margin-left: 285px;
    }
  }
}

.loadingWrapper {
  z-index: 100;
}

.icon {
  display: inline-block;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  flex-shrink: 0;
}

.icon.paper-plane {
  background-image: url("../img/paper-plane.svg");
}

.icon.account {
  background-image: url("../img/account.svg");
}

.icon.search {
  background-image: url("../img/search.svg");
}

.icon.details {
  background-image: url("../img/details.svg");
}

.icon.location {
  background-image: url("../img/location.svg");
}

.icon.mandated.black {
  background-image: url("../img/mandatory-black.svg");
}
.icon.mandated.white {
  background-image: url("../img/mandatory-white.svg");
}

.icon.registration {
  background-image: url("../img/registration.svg");
}
.icon.diploma {
  background-image: url("../img/diploma.svg");
}

.icon.personnel {
  background-image: url("../img/presenter.svg");

  &.blue {
    background-image: url("../img/presenter-blue.svg");
  }
}

.icon.smartphone {
  background-image: url("../img/smartphone.svg");
}

.icon.reviewed {
  background-image: url("../img/reviewed.svg");
}

.icon.presenter-white {
  background-image: url("../img/presenter-white.svg");
}

.icon.attendees {
  background-image: url("../img/registration.svg");
}

.icon.attendees {
  background-image: url("../img/attendees.svg");
}

.icon.info {
  background-image: url("../img/info.svg");
}

.icon {
  &.doc {
    background-image: url("../img/file-doc.svg");
  }

  &.docx {
    background-image: url("../img/file-docx.svg");
  }

  &.pdf {
    background-image: url("../img/file-pdf.svg");
  }

  &.ppt {
    background-image: url("../img/file-ppt.svg");
  }

  &.pptx {
    background-image: url("../img/file-pptx.svg");
  }

  &.txt {
    background-image: url("../img/file-txt.svg");
  }

  &.xls {
    background-image: url("../img/file-xls.svg");
  }

  &.csv {
    background-image: url("../img/file-csv.svg");
  }

  &.xlsx {
    background-image: url("../img/file-xlsx.svg");
  }
}

.icon.goal {
  background-image: url("../img/goal.svg");

  &.white {
    background-image: url("../img/goal-white.svg");
  }

  &.black {
    background-image: url("../img/goal-black.svg");
  }
}

.icon.calendar {
  background-image: url("../img/calendar.svg");

  &.white {
    background-image: url("../img/calendar-white.svg");
  }
}

.icon.event.color {
  background-image: url("../img/event.svg");
}

.icon.external-pd {
  background-image: url("../img/external-pd.svg");
}

.icon.plus-black {
  background-image: url("../img/plus-outline.svg");
}

.icon.conference {
  background-image: url("../img/conference.svg");

  &.white {
    background-image: url("../img/conference-white.svg");
  }

  &.color {
    background-image: url("../img/presentation.svg");
  }
}

.icon.draft {
  background-image: url("../img/i-draft.svg");
}

.icon.camera {
  background-image: url("../img/camera.svg");
}

.icon.question {
  background-image: url("../img/question.svg");
}

.icon.preview {
  background-image: url("../img/preview.svg");

  &.blue {
    background-image: url("../img/preview-blue.svg");
  }
}

.icon.share {
  background-image: url("../img/share.svg");
}

.icon.trash-white {
  background-image: url("../img/i-trash-white.svg");
}

.icon.trash {
  background-image: url("../img/i-trash.svg");
}

.icon.search-white {
  background-image: url("../img/i-search-white.svg");
}

.icon.copy {
  background-image: url("../img/copy.svg");
}

.icon.copy.blue {
  background-image: url("../img/copy-blue.svg");
}

.icon.rocket {
  background-image: url("../img/i-rocket.svg");

  &.blue {
    background-image: url("../img/rocket-blue.svg");
  }
}

.icon.save {
  background-image: url("../img/save.svg");
}
.icon.attach-file {
  background-image: url("../img/attach.svg");
}
.icon.cancel {
  background-image: url("../img/cancel.svg");
}

.icon.archive {
  background-image: url("../img/i-archive.svg");

  &.blue {
    background-image: url("../img/i-archive-blue.svg");
  }
}

.icon.unarchive {
  position: relative;
  top: -2px;
  background-image: url("../img/i-unarchive.svg");

  &.white {
    background-image: url("../img/i-unarchive-white.svg");
  }
}

.icon.export {
  background-image: url("../img/export.svg");

  &.blue {
    background-image: url("../img/export-blue.svg");
  }
}

.icon.laptop {
  background-image: url("../img/laptop-black.svg");
}

.icon.templates {
  background-image: url("../img/community.svg");

  &.white {
    background-image: url("../img/community-white.svg");
  }
}

.icon.community {
  background-image: url("../img/community.svg");
}

.icon.badge {
  background-image: url("../img/i-badge-black.svg");

  &.color {
    background-image: url("../img/i-badge.svg");
  }
}

.icon.learningPath,
.icon.learning.path {
  background-image: url("../img/learning-path-black.svg");

  &.color {
    background-image: url("../img/learning-path.svg");
  }
}

.icon.announcement {
  background-image: url("../img/sidebar/announcement.svg");
  position: relative;
  top: 2px;
}

.icon.puzzle-piece {
  background-image: url("../img/puzzle-piece.svg");
}

.icon.list-view {
  background-image: url("../img/i-list.svg");
  background-size: 80%;
}

.icon.add {
  background-image: url("../img/plus.svg");
}
.icon.quill-pen {
  background-image: url("../img/quill-pen.svg");
}

.icon.check {
  background-image: url("../img/check.svg");
}

.icon.check.black {
  background-image: url("../img/i-check-black.svg");
}

.icon.close {
  background-image: url("../img/x.svg");
}

.icon.star {
  background-image: url("../img/star.svg");
}

.icon.books {
  background-image: url("../img/books.svg");
}

.icon.support {
  background-image: url("../img/support.svg");
}

.icon.video-call {
  background-image: url("../img/video-call.svg");
}

.icon.groups {
  background-image: url("../img/group.svg");
}

.icon.school-building {
  background-image: url("../img/school-building.svg");
}

.icon.quick-links {
  background-image: url("../img/quick-links.svg");

  &.white {
    background-image: url("../img/quick-links-white.svg");
  }
}

.icon.hide {
  background-image: url("../img/hide.svg");
}

.icon.time {
  width: 30px;
  height: 30px;
  background-image: url("../img/time.svg");
}

.count {
  color: $blue;
  font-size: 24px;
  font-weight: 700;
  display: inline-block;
  margin-right: 5px;
}

.MuiTableRow-head {
  background-color: $light-grey;

  th {
    color: #333;
    border-bottom: 0px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
  }
}

.masonry-grid {
  display: flex;
  width: auto;
}

table {
  td {
    padding: 0 !important;

    &.template {
      border-left: 5px solid $green;

      .flexRow.name {
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        .orgName {
          opacity: 0.6;
          text-transform: uppercase;
          font-size: 12px;
        }
      }
    }

    &.largeText {
      .capsule {
        .icon.clock {
          opacity: 1;
        }
      }
    }

    a {
      padding: 20px;
      display: block;
      color: black;
      text-decoration: none;
    }
  }

  max-width: 1440px;
}

tbody {
  background-color: white;

  tr:hover {
    cursor: pointer;
    background-color: $lightest-grey;
  }

  tr:last-child {
    td {
      border-bottom: 0px;
    }
  }

  .icon {
    width: 35px;
    height: 35px;
    margin-right: 10px;
    opacity: 0.35;

    &.badge {
      box-shadow: 0 2px 4px 0 rgb(0 0 0 / 28%);
      border-radius: 100%;
      width: 45px;
      height: 45px;
    }

    &.check.color {
      width: 20px;
      height: 20px;
      opacity: 1;
      background-image: url("../img/i-check.svg");
    }
    &.announcement {
      background-size: 20px;
    }

    &.time {
      margin-right: 0px;
      position: relative;
      top: -1px;
      background-size: 20px;
    }
  }

  .entityImage {
    width: 35px;
    height: 35px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 10px 0 0 !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
    flex-shrink: 0;
    &.announcement {
      border-radius: 8px;
    }

    &.learning {
      width: 55px;
      background-position: center center;
      background-repeat: no-repeat;
      border-radius: 3px;
    }

    &.course {
      border-radius: 100%;
      border: 3px solid white;
    }

    &.badge {
      width: 45px;
      height: 45px;
      border-radius: 100%;
    }
  }

  .category-icon {
    border-radius: 100%;
    width: 35px !important;
    height: 35px !important;
    margin: 0 10px 0 0 !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
  }
}

.category-icon {
  flex-shrink: 0;
  display: inline-block;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
  background-image: url("../img/mobilemind.png");
  border: 3px solid white;

  &.digital.learning {
    background-image: url("../img/digital-learning.svg");
  }

  &.google {
    background-image: url("../img/i-google.svg");
  }

  &.sites {
    background-image: url("../img/i-google-sites.svg");
  }

  &.school {
    background-image: url("../img/school.svg");
  }

  &.curriculum {
    background-image: url("../img/curriculum.svg");
  }

  &.district {
    background-image: url("../img/district.svg");
  }

  &.policies {
    background-image: url("../img/policies.svg");
  }

  &.teachers {
    background-image: url("../img/teachers.svg");
  }

  &.general {
    background-image: url("../img/general.svg");
  }

  &.technology {
    background-image: url("../img/technology.svg");
  }

  &.meet {
    background-image: url("../img/i-meet.svg");
  }

  &.docs {
    background-image: url("../img/i-docs.svg");
  }

  &.drive {
    background-image: url("../img/i-drive.svg");
  }

  &.earth {
    background-image: url("../img/i-earth.svg");
  }

  &.forms {
    background-image: url("../img/i-forms.svg");
  }

  &.slides {
    background-image: url("../img/i-slides.svg");
  }

  // Microsoft
  &.accessibility {
    background-image: url("../img/ms-accessibility.svg");
  }

  &.flip {
    background-image: url("../img/ms-flip.svg");
  }

  &.teams {
    background-image: url("../img/ms-teams.svg");
  }

  &.gmail {
    background-image: url("../img/gmail.svg");
  }

  &.digital.citizenship {
    background-image: url("../img/digital-citizenship.svg");
  }

  &.sway {
    background-image: url("../img/ms-sway.svg");
  }

  &.microsoft.forms {
    background-image: url("../img/ms-forms.svg");
  }

  &.powerpoint {
    background-image: url("../img/ms-powerpoint.svg");
  }

  &.onenote {
    background-image: url("../img/ms-one-note.svg");
  }

  &.century {
    background-image: url("../img/ms-21st.svg");
  }

  &.microsoft.certification {
    background-image: url("../img/ms-cert.png");
  }

  &.youtube {
    background-image: url("../img/i-youtube.svg");
  }

  &.classroom {
    background-image: url("../img/i-classroom.svg");
  }

  &.calendar {
    background-image: url("../img/i-calendar.svg");
  }

  &.zoom {
    background-image: url("../img/zoom.svg");
  }

  &.seesaw {
    background-image: url("../img/seesaw.svg");
  }

  &.other {
    background-image: url("../img/other.svg");
  }

  &.hq {
    background-image: url("../img/hq.svg");
  }

  &.samr {
    background-image: url("../img/samr.svg");
  }

  &.chrome.extensions {
    background-image: url("../img/i-chrome-extensions.svg");
  }

  &.certification {
    background-image: url("../img/i-certification-info.svg");
  }

  &.sheets {
    background-image: url("../img/i-sheets.svg");
  }
}

#sidebar .button.large {
  background-color: $green;
}

.button {
  &.large {
    font-size: 16px;
    font-weight: bold;
    min-width: 175px;
    padding: 8px 30px;
    border-radius: 100px;
    text-transform: none;
    color: black !important;

    .icon {
      margin-right: 10px;
    }
  }

  &.small {
    font-size: 12px;
    padding: 8px;
    min-width: 0;
    font-weight: 700;
    color: $blue;

    &.delete {
      color: $red;
    }

    &.cancel {
      color: #999;
    }

    a {
      display: flex;
      align-items: center;
      color: $blue;
      text-decoration: none;
    }

    img {
      position: relative;
      top: -1px;
      margin-right: 5px;
      width: 20px;
      height: 20px;
    }

    &:focus {
      background: $light-grey;
    }
  }

  &.secondary {
    background-color: white;
    color: $blue;

    &.Mui-disabled {
      color: $blue;
      background-color: rgba(0, 0, 0, 0);
      box-shadow: none;
    }

    &:hover {
      background-color: #e3e3e3;
    }
  }

  &.delete {
    background-color: #ff676f !important;
    color: white !important;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .icon {
    width: 20px;
    height: 20px;
    display: block;
    background-repeat: no-repeat;
    margin-right: 10px;
  }
}

.button.small {
  border-radius: 100px;
  padding-left: 15px;
  padding-right: 15px;
  text-transform: uppercase;
  .MuiButton-label {
    text-align: left;
  }
}

.MuiSnackbar-root {
  right: 24px !important;
  display: flex;
  justify-content: center !important;
}

.snackbarContainer {
  display: flex;
  justify-content: center;
  width: 100%;

  .MuiPaper-root {
    border-radius: 100px;
    padding-left: 25px;
    padding-right: 25px;
  }

  .button.small {
    color: $green;

    &:focus {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}

.button.small.attention {
  animation: zoomInOut 1.5s forwards;
}

.icon.sort {
  background-image: url("../img/sort.svg");
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 5px;
  margin-left: -10px;
  position: relative;
  top: 5px;
  cursor: pointer;
  transition: 0.6s;

  &.flipped {
    transform: scaleY(-1);
  }
}

.MuiPickersModal-dialog {
  .MuiPickersDay-daySelected {
    &:hover {
      background-color: $green;
    }

    .MuiTypography-root {
      font-weight: 700 !important;
    }

    background-color: $green;
  }
}

.MuiPickersCalendarHeader-transitionContainer p {
  font-weight: 700;
}

.page.unauthorized {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 100px;
  text-align: center;
  margin: 0 auto;
  max-width: 500px;

  .content {
    animation: fadeInUp 0.5s forwards;
  }

  h2 {
    justify-content: center;
    font-size: 32px;
  }

  p {
    font-size: 18px;
  }

  .icon.warning {
    animation: bounceInDown 0.75s forwards;
    margin: 0 auto;
    width: 100px;
    height: 100px;
    background-image: url("../img/computer-warning.svg");
  }
}

.progressBarWrapper {
  margin-top: 2px;
  height: 6px;
  background-color: #f0f0f0;
  border-radius: 5px;
  width: 100%;
  overflow: hidden;

  .progressBar {
    .progressBarInner {
      height: 15px;
      background-color: $green;
      animation: grow forwards 1.5s;
    }
  }
}

.userImage {
  display: block;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
  border-radius: 100%;
}

.entityCount {
  .icon.sort {
    top: 0;
  }

  .count {
    top: 0;
  }
}

.icon.map {
  background-image: url("../img/map.svg");
}

#tour-learner {
  background-color: $blue;
  border-radius: 25px;
  transition: 0.3s;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
  margin-right: 20px;
  height: 32px;
  display: flex;
  align-items: center;
  &.collection-link {
    cursor: pointer;
    text-align: center;
    display: block;
    padding: 0px 15px;
    font-weight: bold;
    color: white;
    display: flex;
    align-items: center;
    .swap-icon {
      top: 0;
    }
  }
  .swap.icon {
    background-image: url("../img/swap.svg");
    width: 15px;
    height: 15px;
    margin-right: 5px;
    position: relative;
    top: 2px;
  }

  a {
    text-align: center;
    display: block;
    padding: 0px 15px;
    font-weight: bold;
    color: white;
  }

  &:hover {
    background-color: darken($blue, 10%);
  }
}

.tour-mask {
  color: rgba(0, 0, 0, 0.3);
}

#tour-sidebar-filters {
  margin: 0 -20px;
  padding: 0 20px 5px;
}

#___reactour {
  .reactour__helper {
    border-radius: 6px;
    overflow: hidden;
    padding: 20px 25px;
  }

  .MuiTouchRipple-root {
    display: none;
  }

  footer {
    margin-top: 10px;
    justify-content: space-between;
    display: flex;
    align-items: center;

    .step {
      flex: 1;
      justify-content: center;
      display: flex;
      font-size: 12px;
    }

    .button.disabled {
      opacity: 0;
      pointer-events: none;
    }

    &.flexEnd {
      justify-content: flex-end;
    }
  }

  .reactour__close {
    top: 7px;
    padding: 5px;
    right: 7px;
    width: 20px;
    height: 20px;
    transition: 0.3s;
    transform: scale(1);

    &:hover {
      transform: scale(1.2);
    }

    &:focus {
      background-color: white;
    }
  }

  [data-tour-elem="right-arrow"],
  [data-tour-elem="left-arrow"] {
    background-color: white;
  }

  [data-tour-elem="controls"] {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
}

.MuiDialog-paper {
  border-radius: 12px;
  overflow: hidden !important;
  animation: fadeInUp 0.6s;
}

.dialog {
  padding: 40px;

  @media (max-width: $screen-sm-max) {
    max-width: 90vw;

    .flexRow {
      flex-direction: column;

      .thumbnail {
        margin: 0 0 20px 0;
      }
    }

    footer {
      margin-top: 10px !important;
    }
  }

  .thumbnail {
    margin-right: 20px;

    .icon {
      width: 100px;
      height: 100px;
      display: block;
    }
  }

  .icon.new-features {
    background-image: url("../img/parachute.svg");
  }

  h2 {
    font-size: 36px;
    margin: 0 0 20px;
    text-align: center;
    justify-content: center;
    font-weight: 700;
  }

  p {
    line-height: 18px;
  }

  footer {
    justify-content: center;
    margin-top: 20px;
    display: flex;
    align-items: center;

    .button {
      margin-left: 25px;
    }
  }
}

.product-tour-modal {
  .MuiPaper-root {
    width: 500px;
    border-radius: 14px;
  }

  .dialog {
    padding: 30px;
  }
}

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0) scaleX(3);
    transform: translate3d(-3000px, 0, 0) scaleX(3);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0) scaleX(1);
    transform: translate3d(25px, 0, 0) scaleX(1);
  }

  75% {
    -webkit-transform: translate3d(-10px, 0, 0) scaleX(0.98);
    transform: translate3d(-10px, 0, 0) scaleX(0.98);
  }

  90% {
    -webkit-transform: translate3d(5px, 0, 0) scaleX(0.995);
    transform: translate3d(5px, 0, 0) scaleX(0.995);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.MuiAutocomplete-clearIndicator {
  display: none !important;
}

.inputSearch {
  position: relative;
}

.carouselWrapper {
  max-width: 820px;
  margin: 20px auto 0px;

  .Carousel-root-1 {
    overflow: visible !important;
  }

  .MuiPaper-root {
    padding: 20px;

    .flexRow {
      align-items: flex-start;
    }

    .media {
      flex-shrink: 0;
      margin-right: 20px;
    }

    img {
      max-width: 150px;
      border-radius: 6px;
      overflow: hidden;
    }

    p {
      margin-bottom: 0;
      font-size: 12px;
    }
  }

  header {
    padding-top: 5px;
    font-weight: bold;
  }
}

.manageLocations {
  padding: 50px 20px;
  margin: 0 0 0 255px;
  max-width: 1080px;

  .icon.location.blue {
    background-image: url("../img/location-blue.svg");
  }

  .addNew {
    max-width: 698px;
    margin: 20px auto;
    animation: fadeInUp 0.3s forwards;

    footer {
      margin-top: 0 !important;
    }

    .MuiFormControl-root {
      display: block;
      margin: 0 0 20px 0;
      width: 100%;
    }

    .MuiInputBase-root {
      width: 100%;
    }
  }

  .icon.overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0.8;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    height: auto;
    width: 80px;
    border-radius: 100%;
    background-size: 20px;
  }

  header {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    [data-shrink="true"] {
      top: 5px;
    }

    label {
      left: 30px;
    }

    input {
      padding-left: 30px;
    }

    .MuiTextField-root {
      width: 250px;
    }

    .icon.search {
      position: absolute;
      bottom: 7px;
      left: 0px;
      width: 20px;
      height: 20px;
      opacity: 0.3;
    }
  }

  ul {
    border-radius: 6px;
    list-style-type: none;
    padding: 0;

    li {
      background: white;
      display: flex;
      margin-bottom: 1px;
      padding: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: 0.2s;
      opacity: 0;
      animation: fadeInUp 0.2s forwards;
      border-left: 5px solid rgba(0, 0, 0, 0);

      footer {
        margin-top: 20px;

        .icon {
          width: 20px;
          height: 20px;
        }
      }

      .uploadImageWrapper,
      .uploadedImage,
      .fileUpload,
      .border {
        width: 100px !important;
        height: 100px !important;

        input {
          width: 100px !important;
          height: 100px !important;
        }

        .tooltip {
          transform: scale(0.5);
          margin-left: -100px;
          margin-top: 0px;
        }
      }

      .tooltip {
        display: none;
      }

      .editing-presenter {
        margin-top: 10px;
        animation: none;

        footer {
          justify-content: flex-end;

          .button {
            margin-left: 0;
          }
        }

        .uploadImageWrapper {
          margin-top: 0;
          margin-right: 20px;
        }

        .MuiFormControl-root {
          max-width: 650px;
          display: flex;
          margin: 0 0 15px;
        }
      }

      .flexRow {
        flex: 1;
      }

      .editing {
        display: flex;
        flex-direction: column;

        .MuiFormControl-root {
          margin: 10px 0 0;
          width: 100%;
        }

        .title {
          margin-bottom: 10px;
          width: 50%;

          input {
            font-size: 18px;
            font-weight: bold;
          }
        }
      }

      &:hover {
        border-left: 5px solid $green;
      }

      .button.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      .icon {
        position: relative;
        top: -2px;
        margin: 0 5px;
        width: 30px;
        height: 30px;
      }

      strong {
        display: block;
      }

      .details {
        flex: 1;

        .icon.location-color {
          display: none;
        }
      }
    }
  }
}

.roomEdit {
  padding: 35px 20px 20px;
  border-radius: 14px;
  background-color: lighten($light-grey, 3%);
  margin-top: 35px;
  margin-bottom: 20px;

  h3 {
    margin-top: 0;
    margin-bottom: 5px;
    align-items: center;
    display: flex;
    justify-content: space-between;

    header {
      padding: 0;
      margin: 0;
      margin-top: -20px;
    }

    .inputSearch {
      label {
        text-transform: none;
      }

      .icon {
        top: 22px;
      }
    }
  }

  .roomName textarea {
    font-weight: bold;
  }

  .roomItem {
    margin-top: 20px;
    border-radius: 6px;
    position: relative;

    .MuiAccordionSummary-root {
      padding: 0;
    }

    .MuiAccordion-root:before {
      display: none;
    }

    .archiveConfirm {
      position: absolute;
      background-color: rgba(250, 250, 250, 0.85);
      padding: 10px 20px;
      z-index: 1;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;

      .flexRow {
        p {
          font-size: 14px;
          margin-right: 20px;
          font-style: italic;
        }

        justify-content: flex-end;
        animation: fadeInUp 0.4s forwards;
      }
    }

    .MuiAccordion-root,
    .Mui-focusVisible {
      background-color: rgba(0, 0, 0, 0);
      box-shadow: none;
    }

    .flexRow {
      .MuiFormControl-root {
        margin-top: -25px;
      }

      .icon.room {
        position: relative;
        top: 5px;
        background-image: url("../img/room.svg");
      }
    }

    label {
      transition: 0.3s;
      opacity: 0;
    }

    .MuiPaper-root {
      transition: 0.6s;
    }

    .MuiPaper-root.Mui-expanded {
      background-color: rgba(89, 195, 229, 0.15);
    }

    .Mui-expanded {
      label {
        opacity: 1;
      }

      .MuiFormControl-root {
        margin-top: -15px;
      }

      .icon.room {
        top: 16px;
      }
    }
  }

  .icon.archive {
    width: 20px;
    height: 20px;
  }
}

.modal-join-code {
  .MuiPaper-root {
    width: 90vw;
    max-width: 100%;

    footer {
      margin: 0;
      padding-bottom: 0;
    }

    p {
      background-color: $green;
      border-radius: 14vw;
      font-size: 14vw;
      text-align: center;
      font-weight: bold;
      margin: 0;
      padding: 20px;
    }

    button {
      position: relative;
      z-index: 2;
    }
  }
}

.loadingWrapper {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 14px;

  &.fullPage {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    strong {
      padding: 0;
    }
  }
}

.icon {
  &[class*="classroom"] {
    background-image: url("../img/onboarding-teacher.svg");
  }

  &[class*="instructional"] {
    background-image: url("../img/onboarding-instructional.svg");
  }

  &[class*="substitute"] {
    background-image: url("../img/onboarding-substitute.svg");
    background-size: 90%;
  }

  &[class*="partner"] {
    background-image: url("../img/deal.svg");
    background-size: 90%;
  }

  &[class*="special education"] {
    background-image: url("../img/special-ed.svg");
    background-size: 85%;
    background-position: left 55% center;
  }

  &[class*="instructional/tech coach"] {
    background-image: url("../img/onboarding-tech.svg");
  }

  &[class*="leadership"] {
    background-image: url("../img/onboarding-leadership.svg");
  }

  &[class*="media"] {
    background-image: url("../img/onboarding-media.svg");
  }

  &[class*="school - other"] {
    background-image: url("../img/onboarding-school.svg");
  }

  &[class*="district - other"] {
    background-image: url("../img/onboarding-district.svg");
  }
}

.error-boundary {
  h2 {
    font-weight: bold;
    text-align: center;
    font-size: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  footer {
    margin-top: 0 !important;

    .button {
      margin: 0 !important;
    }
  }

  p {
    max-width: 500px;
    text-align: center;
  }

  a {
    font-weight: bold;
    color: darken($blue, 10%);
  }

  .MuiInputBase-root:before,
  .MuiInputBase-root:after {
    display: none !important;
  }

  .MuiDialog-paper {
    width: 500px;
  }

  textarea {
    border: 1px solid darken($light-grey, 10%);
    border-radius: 10px;
    padding: 5px;
    font-size: 12px;
  }

  .icon.profanity {
    width: 150px;
    height: 150px;
    margin: 0 auto;
    animation-delay: 5s;
    animation: headShake 2s forwards;
    background-image: url("../img/profanity.svg");
  }
}

.capsule {
  background-color: black;
  display: inline-block;
  color: white;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 15px;
  font-size: 14px;

  .icon.clock {
    margin-right: 5px;
    width: 18px;
    height: 18px;
    background-color: transparent !important;
    background-image: url("../img/clock-white.svg");
  }
}

#contentLock {
  position: fixed;
  z-index: 12;
  top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  animation: slideInDown 1s;

  button {
    color: black !important;
  }

  &:hover {
    .inner {
      padding: 10px 20px;
    }
  }

  .inner {
    transition: 0.3s;
    padding: 5px 20px;
    border-radius: 0 0 12px 12px;
    font-size: 14px;

    .button.small {
      margin-left: 25px;

      span {
        color: black;
      }
    }

    .icon.open-lock {
      background-image: url("../img/open-padlock.svg");
    }
  }
}

.MuiAutocomplete-inputRoot {
  input {
    border-bottom: none;
  }
}

#category-autocomplete {
  label {
    padding: 0;
  }
}

.inner.page-2 {
  #category-autocomplete {
    width: 100%;
  }
}

.backpack-layout {
  .user-rubric-item {
    width: calc(20% - 25px) !important;
  }
}

.page-hubspot {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  max-width: 100vw;

  .buddy-message p {
    text-align: center;
  }

  footer {
    display: flex;
    justify-content: center;
    margin-top: 35px;
  }
}

.MuiPickersPopper-paper {
  .Mui-selected,
  .Mui-selected:focus,
  .Mui-selected:active {
    background-color: $green;
    font-weight: bold;
    color: black;

    &:hover {
      background-color: lighten($green, 5%);
    }
  }
}

.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

#extension-preview-image {
  .uploadImageWrapper {
    padding: 15px;
    margin-top: -20px;
    overflow: hidden;
    .tooltip {
      left: 0;
      right: 0;
      width: auto;
      button {
        display: none;
      }
    }

    .uploadedImage {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      width: auto;

      height: 230px;
    }
    .fileUpload {
      height: 200px;
      border-radius: 12px;
      background-color: rgba(255, 255, 255, 0.7);
      padding: 15px;
      &:hover {
        background-color: rgba(255, 255, 255, 1);
      }
      input {
        border: 1px solid green;
      }
    }
  }
}

.user-label-user-subgroup {
  .multi-group-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 175px;
    display: block;
    overflow: hidden;
    pointer-events: none;
  }
}

.dropdown-menu .MuiMenu-paper {
  .feature-popover-link {
    display: inline;
    padding: 0px !important;
    color: $blue;
  }
}

.feature-addon-container {
  cursor: pointer;
  .feature-popover {
    transition: 0.3s;
    opacity: 0;
    transform: scale(0.2);
    transform-origin: center;
  }
  .icon.calendar.color {
    margin-right: 10px;
    background-image: url("../img/calendar-color.svg");
    width: 30px;
    height: 30px;
  }
  &:hover {
    .feature-popover {
      opacity: 1;
      transform: scale(1);
    }
  }
}
.MuiList-root,
.MuiMenu-paper {
  &::-webkit-scrollbar {
    display: none;
  }
}

.MuiList-root {
  padding: 0;
  margin-top: 8px;
}
.navbar-feature-item .feature-addon-container {
  height: 50px;
}
