@import "variables.scss";

#extensionPreview {
  animation: fadeInUp 0.6s forwards;
  .MuiChip-deleteIcon {
    fill: white;
    height: 35px;
    width: 35px;
  }
  p {
    color: white;
    font-weight: bold;
  }
  .MuiBox-root::-webkit-scrollbar {
    display: none;
  }
}
