@import "../../../styles/variables.scss";

.MuiPickersBasePicker-pickerView {
  font-family: "Nunito" !important;

  .MuiPickersCalendarHeader-switchHeader p {
    font-weight: bold;
  }

  p,
  span,
  button {
    font-family: "Nunito" !important;
  }
}

#menu- {
  .MuiList-root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .Mui-selected {
      background-color: lighten($green, 35%);
    }

    li {
      font-family: "Nunito", sans-serif;
      padding: 10px;
      flex: 1;
      width: 100%;
      justify-content: flex-start;
    }
  }
}

.icon.spreadsheet {
  background-color: transparent !important;
  background-image: url("../../../img/table.svg");
}

.icon.rubric {
  background-color: transparent !important;
  background-image: url("../../../img/check-list.svg");
}

.icon.document {
  background-image: url("../../../img/document.svg");
}

.icon.clear-filter {
  background-image: url("../../../img/clear.svg");
}

.rubricSelect {
  .MuiFormControl-root {
    max-width: 100%;

    .MuiSelect-select {
      min-width: 0px;
      width: 200px;
      padding: 5px;
      font-family: "Nunito", sans-serif;
    }

    .MuiSvgIcon-root {
      top: 3px;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
}

#backpack-sidebar {
  background-color: white;
  position: fixed;
  left: 0;
  top: 50px;
  bottom: 0;
  width: 250px;
  overflow: auto;
  padding-bottom: 60px;

  .buddy-message {
    padding-left: 10px;
    padding-right: 10px;

    p {
      margin: 0;
      width: 100%;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
    }
  }

  .backButton {
    width: 280px;
    padding: 20px;
    border-radius: 0px;
    transition: 0.4s;
    border-bottom: 1px solid $light-grey;
    background-color: transparent;

    &:hover {
      background-color: lighten($light-grey, 2%);
    }

    @media (max-width: $screen-sm-max) {
      margin-top: -50px;
    }

    &:focus {
      background-color: white;
    }

    .MuiButton-label {
      font-family: "Nunito";
      justify-content: flex-start;
      font-size: 18px;
      color: $blue;
      text-transform: capitalize;
      font-weight: normal;
    }

    .icon {
      margin-right: 10px;
      width: 20px;
      height: 17px;
    }
  }

  .filters {
    padding: 10px;
    margin: 0px;
    width: 100%;

    .inputSelect .icon {
      display: none;
    }

    header {
      margin-bottom: -20px;

      .button {
        font-size: 12px;
        width: auto;
      }

      strong {
        flex: 1;
      }
    }

    label.shrink {
      transform: translate(0, 0px) scale(0.75);
      margin-bottom: 5px;
    }
  }

  .MuiSelect-root {
    display: flex;
    align-items: center;
    padding-left: 0;
    white-space: normal;

    .category-icon-wrapper {
      width: 20px;
      height: 20px;
      display: inline-block;
      position: relative;
      margin-right: 8px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
      border-radius: 30px;
      overflow: hidden;
      padding: 0;
      border: 2px solid white;

      .category-icon {
        width: 20px;
        height: 20px;
        display: block;
        border: none;
      }
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .secondary .button {
    background-color: $light-grey;
  }

  .button {
    width: 100%;
    font-size: 14px;

    a {
      display: flex;
      align-items: center;
      color: black;
    }
  }

  .MuiFormControl-root {
    margin: 10px;

    input {
      margin: 0;
    }

    .MuiInputBase-root:before {
      display: none;
    }
  }

  section {
    padding: 15px;
    border-bottom: 1px solid $light-grey;

    &:last-child {
      border-bottom: none;
    }

    ul {
      margin: 10px 0 0;

      &.shrink {
        height: 0px !important;
        overflow: hidden;
        margin: 0;
      }

      li {
        transition: 0.3s;
        background-color: transparent;
        border-radius: 35px;
        padding: 10px;
        margin: 4px 0;
        height: 42px;
        overflow: hidden;
        font-size: 14px;

        &:not(.active) {
          cursor: pointer;

          &:hover {
            background-color: $light-grey !important;
          }
        }
      }
    }
  }
}

.page.backpack-layout {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 250px;
  max-width: 1600px;
  padding-bottom: 50px;

  header {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
  }

  &.print {
    max-width: calc(100% + 220px);
    margin: 0 0 0 -220px;
    padding-top: 0;
    margin-top: -55px;

    .icon.rubric-black {
      background-image: url("../../../img/pencil-ruler-black.svg") !important;
    }

    .total-score-print {
      background-color: $green;
      font-weight: bold;
      padding: 5px 10px;
      font-size: 12px;
      min-width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 25px;
    }
  }
}

.saveDateSetting .MuiFormControlLabel-label {
  font-family: "Nunito";
  font-size: 12px;
  font-weight: bold;
}

.icon.filter {
  background-image: url("../../../img/filter.svg");
}

#overview-wrapper {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
  position: relative;
  display: flex;
  transition: 1s;
  max-width: 600px;

  &.flipped {
    .card-back {
      display: block;
    }

    .allCategories {
      overflow: hidden;
      border-radius: 8px;
      display: flex;
      flex-wrap: wrap;

      li {
        border-bottom: 1px solid $light-grey;
        width: 100%;
      }
    }

    #backpack-overview-card {
      opacity: 0;
      display: none;
    }
  }

  &:hover {
    .popover {
      opacity: 1;
    }
  }

  .popover {
    position: absolute;
    bottom: -40px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    opacity: 0;
    transition: 0.5s;

    .icon.pointer {
      background-image: url("../../../img/point.svg");
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }

    p {
      display: inline-flex;
      align-items: center;
      margin: 0;
      padding: 5px 10px;
      border-radius: 20px;
      font-size: 12px;
      font-weight: bold;
    }
  }

  .userPicture {
    width: 80px;
    height: 80px;
    background-size: cover;
    display: block;
    border-radius: 100%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.32);
  }
}

#backpack-overview-card {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  transition: 0.1s;
  transition-timing-function: cubic-bezier(0.03, 0.98, 0.52, 0.99);
  flex: 1;

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }

  .skeleton-box {
    display: inline-block;
    z-index: 3;
    height: 20px;
    position: relative;
    overflow: hidden;
    background-color: #dddbdd;
    right: 15px;
    position: absolute;
    left: 15px;
    border-radius: 20px;
    bottom: 16px;

    &.large {
      bottom: 40px;
      height: 40px;
      right: auto;
      width: 120px;
    }

    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0)
      );
      -webkit-animation: shimmer 1s infinite;
      animation: shimmer 1s infinite;
      content: "";
    }
  }

  .userContainer {
    padding: 10px 20px 10px 10px;
    background-color: #ffffff;
    opacity: 1;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    border-radius: 10px;
    display: inline-flex;
    width: auto;

    .foil {
      border-radius: 10px;
      opacity: 0.5;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: #03030355;
      background-image: repeating-radial-gradient(
          circle at 0 0,
          transparent 0,
          #03030355 5px
        ),
        repeating-linear-gradient(#03030355, #fff);
      opacity: 0.15;
    }
  }

  header {
    font-size: 14px;
    opacity: 0.5;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: -5px;
  }

  .totalTime {
    display: block;
    margin-top: 10px;
    font-size: 36px;
  }

  .userInfo {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    strong {
      font-size: 18px;
    }

    span {
      text-transform: uppercase;
      font-size: 12px;
    }
  }
}

.topCategories {
  justify-content: center;
  margin: 0;
  cursor: pointer;
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  height: 100%;

  .categoryIcon {
    width: 30px;
    height: 30px;
    background-size: cover;
    display: block;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    border-radius: 100%;
    border: 3px solid white;
    box-sizing: content-box;
    background-color: $light-grey;
    margin-right: 5px;
  }

  li {
    flex: 1;
    background-color: $light-grey;
    padding: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid white;

    &:last-child {
      border-bottom: none;
    }
  }
}

.tilt-card {
  position: absolute;
  top: 0;
  z-index: 5;
  min-width: 500px;

  &.no-categories {
    min-width: 300px;
  }
}

.card-back {
  display: none;
  transform: rotateY(180deg);

  .stripe {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: black;
    padding: 3px 20px;

    .date-header {
      flex: 1;
      color: white;
      opacity: 1;
    }
  }

  .back-header {
    border-bottom: 1px solid $light-grey;
    padding: 15px 0;
    display: flex;
    align-items: center;

    strong {
      font-size: 24px;
    }
  }
}

.allCategories {
  min-width: 500px;
  flex-wrap: wrap;
  flex-direction: row;
  height: auto !important;
}

.content-row {
  width: 100%;
  opacity: 0;
  margin-bottom: 35px;
  animation: fadeInUp 0.8s forwards;

  header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    opacity: 1 !important;

    .count {
      flex: 1;
      display: flex;
      align-items: center;
      color: black;
    }

    .icon {
      width: 40px;
      height: 40px;
      opacity: 1;
      margin-right: 5px;
      margin-left: 0;

      &.calendar {
        background-image: url("../../../img/calendar-color.svg");
      }

      &.external-pd {
        background-image: url("../../../img/external-pd-color.svg");
      }

      &.laptop.color {
        background-image: url("../../../img/laptop-color.svg");
      }

      &.rubric-report.color {
        background-image: url("../../../img/pencil-ruler-color.svg");
      }

      &.mandated.color {
        background-image: url("../../../img/mandatory.svg");
      }
    }

    strong {
      padding: 0 5px;
      font-size: 36px;
    }

    span {
      opacity: 0.6;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 12px;
    }
  }

  .alice-carousel__next-btn-item {
    right: -15px;
    border-radius: 0 8px 8px 0;
    background-image: url("../../../img/caret-right.svg");
  }

  .alice-carousel__prev-btn-item {
    left: -15px;
    border-radius: 8px 0 0 8px;
    background-image: url("../../../img/caret-left.svg");
  }

  .alice-carousel__dots {
    margin: 5px 0 -15px !important;
    pointer-events: none;

    .alice-carousel__dots-item {
      background-color: #888;
      opacity: 0.3;

      &.__active {
        opacity: 1;
        background-color: #888 !important;
      }
    }
  }

  .alice-carousel__prev-btn,
  .alice-carousel__next-btn {
    padding: 0 !important;
  }

  .alice-carousel__next-btn-item,
  .alice-carousel__prev-btn-item {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
    position: absolute;
    top: -15px;
    bottom: -15px;
    background-color: white;
    width: 35px;
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: center;
    transition: 0.3s;
    transform: scale(1);

    &:hover {
      transform: scale(1.01);
    }

    &.__inactive {
      display: none;
    }

    span {
      display: none;
    }

    &:after {
      display: block;
      content: "";
    }
  }
}

.content-row {
  max-width: 1600px;

  .alice-carousel__stage-item {
    width: 100% !important;
  }

  .inner {
    display: flex;
    background-color: white;
    border-radius: 8px;
    padding: 15px;
    width: 100%;
  }

  .chunk {
    display: flex;
  }

  .inner-carousel {
    .loadingWrapper {
      position: absolute;
      top: 12px;
      right: 85px;
    }

    &.disablePrev .alice-carousel__prev-btn .alice-carousel__prev-btn-item {
      pointer-events: none;
      background-image: none;
      box-shadow: none;
    }

    &.disableNext .alice-carousel__next-btn .alice-carousel__next-btn-item {
      pointer-events: none;
      background-image: none;
      box-shadow: none;
    }

    .item {
      padding: 10px;
      transition: 0.3s;
      transform: scale(1);
      cursor: pointer;

      &:hover {
        transform: scale(1.05);
      }

      &.event-item {
        width: 33%;

        @media (min-width: 1600px) {
          width: 25%;
        }
      }

      &.badge-item {
        width: 33%;
        display: flex;
        align-items: center;

        &:hover {
          transform: scale(1);
        }

        @media (min-width: 1600px) {
          width: 25%;
        }

        @media (max-width: 1275px) {
          width: 50%;
        }

        .certificate-link {
          .MuiButton-label {
            span {
              display: none;
            }
          }
        }

        .badgeImage {
          flex-shrink: 0;
          width: 70px;
          height: 70px;
          border-radius: 100%;
          background-size: cover;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
          margin-right: 10px;
        }
      }

      &.learning-path-item {
        width: 33%;
        margin-bottom: 5px;
        padding-bottom: 15px;
        position: relative;

        @media (min-width: 1600px) {
          width: 20%;
        }

        @media (max-width: 1600px) {
          width: 25%;
        }

        @media (max-width: 1275px) {
          width: 33%;
        }

        .icon.certificate {
          background-image: url("../../../img/certificate-blue.svg");
        }

        footer {
          display: flex;
          justify-content: center;
          margin-top: 10px;
        }

        .capsule-wrapper {
          position: absolute;
          bottom: -10px;
          left: 0;
          right: 0;
          top: auto;
        }
      }

      &.user-rubric-item {
        width: calc(20%);
        margin-bottom: 5px;
        padding-bottom: 15px;
        position: relative;

        @media (min-width: 1600px) {
          width: 20%;
        }

        @media (max-width: 1600px) {
          width: calc(25% - 25px) !important;
        }

        @media (max-width: 1275px) {
          width: calc(33% - 25px) !important;
        }

        .timestamp {
          font-weight: bold;
          opacity: 0.6;
          font-size: 14px;
          text-align: center;
          margin-top: 30px;
        }

        .rubric-background {
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
          border-radius: 12px;
          height: 120px;
          margin-bottom: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-image: url("../../../img/pencil-ruler.svg");
          background-size: 80px;
          background-repeat: no-repeat;
          background-position: center;

          .title {
            color: white;
            text-shadow: 0 1px 4px rgba(0, 0, 0, 0.77);
            font-size: 18px;
            position: relative;
            font-weight: bold;
            z-index: 2;
            text-align: center;
            padding: 0 10px;
          }

          .score-container {
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: -18px;
            left: 0;
            right: 0;
          }

          .icon.clipboard {
            width: 15px;
            height: 15px;
            background-image: url("../../../img/clipboard.svg");
            margin-right: 7px;
          }

          .rubric-score {
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px 8px;
            min-width: 50px;
            background-color: black;
            border-radius: 35px;
            font-size: 18px;
            font-weight: bold;
          }
        }

        @media (max-width: 1600px) {
          width: calc(25% - 25px);
        }

        @media (max-width: 1275px) {
          width: calc(33% - 25px);
        }
      }

      .lp-background {
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        margin-bottom: 5px;
        overflow: hidden;
        position: relative;
        padding: 10px;
        background-position: center;
        background-size: cover;

        .gradient {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(0, 0, 0, 1) 100%
          );
        }

        .title {
          color: white;
          text-shadow: 0 1px 4px rgba(0, 0, 0, 0.77);
          font-size: 18px;
          position: relative;
          z-index: 2;
        }
      }

      &.course-item {
        display: flex;
        align-items: center;
        margin: 0;
        background-color: white;
        border-radius: 10px;

        &:hover {
          transform: scale(1);
          background-color: $light-grey;
        }

        @media (min-width: 1600px) {
          width: 33%;
        }

        @media (max-width: 1600px) {
          width: 50%;
        }

        @media (max-width: 1000px) {
          width: 100%;
        }

        .capsule {
          margin-left: 10px;
        }

        strong {
          font-size: 14px;
          flex: 1;
        }

        .category-icon {
          border-radius: 100%;
          width: 35px;
          height: 35px;
          display: block;
          flex-shrink: 0;
          margin-right: 10px;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
          border: 5px solid white;
          box-sizing: content-box;
        }
      }
    }

    .capsule-wrapper {
      position: absolute;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      top: 95px;
    }
  }

  .item {
    position: relative;
  }

  .event-background {
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
    flex: 1;
    height: 100px;
    background-size: 55px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../img/calendar-white.svg");

    &.external {
      background-size: cover;
      background-size: 70%;
      background-image: url("../../../img/external-bg.svg");
    }
  }
}

.backpack-buddy {
  background-image: url("../../../img/backpack.svg");
  width: 100px;
  height: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto;
  margin-top: 50px;
  position: relative;

  .star {
    background-image: url("../../../img/shooting-star.svg");
    width: 65px;
    height: 40px;
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    animation: dive1 0.8s infinite;
    animation-delay: 0.2s;

    z-index: -1;

    transform: rotate(20deg);

    &:nth-child(2) {
      animation: dive2 0.8s infinite;
      transform: rotate(-50deg) scaleX(-100%);
      animation-delay: 0.4s;
    }

    &:nth-child(3) {
      top: -25px;
      left: 95px;
      animation-delay: 0.6s;
      animation: dive3 0.8s infinite;
      transform: rotate(-20deg) scaleX(-100%);
    }

    @keyframes dive1 {
      from {
        top: -45px;
        left: -45px;
      }

      to {
        top: 30px;
        left: 30px;
      }
    }

    @keyframes dive2 {
      from {
        top: -45px;
        left: 46px;
      }

      to {
        top: 30px;
        left: 30px;
      }
    }

    @keyframes dive3 {
      from {
        top: -25px;
        left: 95px;
      }

      to {
        top: 30px;
        left: 30px;
      }
    }
  }
}

.certificate-menu {
  .certificate.icon {
    background-image: url("../../../img/certificate-black.svg");
  }
}

.certificate-menu {
  .MuiPopover-paper {
    position: relative;
    width: 215px;
    border-radius: 6px;
    overflow: visible;
    background-color: white;
    margin-left: -78px;
    margin-top: 0px;

    ul {
      padding: 0;
    }

    li {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 15px;
      font-family: "Nunito";

      &:focus {
        background-color: rgba(0, 0, 0, 0);
      }

      a {
        display: flex;
        align-items: center;
        width: 100%;
        color: black;
      }

      &:last-child {
        border-bottom: none;
      }

      .icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;

        &.print {
          background-image: url("../../../img/printer.svg");
        }
      }
    }

    &:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid white;
      position: absolute;
      top: -8px;
      right: 50%;
      margin-right: -8px;
    }
  }
}

.conferenceModal {
  .dialog {
    min-width: 600px !important;
    max-width: 600px !important;
    min-height: 350px;
    padding: 0px;

    .total {
      font-size: 12px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.7);

      .credit {
        color: black;
        font-weight: normal;
        margin-left: 5px;
        background-color: $green;
        padding: 3px 10px;
      }
    }

    .inner {
      max-height: 500px;
      overflow: auto;
    }

    .chip {
      flex-shrink: 0;
      border-radius: 15px;
      position: relative;
      top: 1px;
      margin-right: 10px;
      background-color: $dark-blue;
      width: 15px !important;
      height: 15px !important;
      padding: 0px;
    }

    .datetime {
      margin-left: 45px;
      margin-top: -15px;
      padding-bottom: 10px;
      font-size: 12px;
      display: flex;
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        a {
          padding: 15px 20px;
          border-top: 1px solid $light-grey;
          display: flex;
          align-items: center;
          background-color: white;
          transition: 0.4s;
          cursor: pointer;
          text-decoration: none;

          &:hover {
            background-color: $light-grey;
          }

          color: black;
        }

        .timestamp {
          font-size: 14px;
          opacity: 0.75;
        }

        .title {
          flex: 1;
        }

        .chip {
          background-color: $green;
        }

        &:last-child {
          border-bottom: 1px solid $light-grey;
        }
      }
    }

    .credit {
      font-size: 12px;
      margin-left: 15px;
      border-radius: 20px;
    }

    .capsule {
      font-weight: normal;
    }

    h2 {
      font-size: 18px;
      padding: 20px;
      margin: 0px;
      display: flex;
      align-items: center;

      .flexGrow {
        display: flex;
        align-items: center;
        text-align: left;
        flex: 1;
        flex-shrink: 0;
      }

      .timestamp {
        font-size: 14px;
        font-weight: normal;
        opacity: 0.75;
      }

      .chip {
        background-color: $dark-blue;
      }
    }
  }
}
