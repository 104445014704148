@import "../../styles/variables.scss";
.switchLoader {
  position: absolute;
  strong {
    color: black;
    text-transform: none;
  }
}
#orgSelectPopover {
  z-index: 1002 !important;
  .MuiPaper-root {
    border-radius: 0 0 6px 6px;
    box-shadow: none;
    margin-top: 26px;
  }
  ul {
    padding-bottom: 0;
    li {
      padding-top: 10px;
      padding-bottom: 10px;
      align-items: center;
      display: flex;
      color: white;
      justify-content: flex-start;
      font-family: "Nunito", sans-serif !important;
      padding: 10px 16px;

      &:not(.Mui-selected):hover {
        background-color: rgba(255, 255, 255, 0.2) !important;
      }
      &.Mui-selected {
        color: black;
        font-weight: bold;
      }
      .icon {
        margin-right: 10px;
        width: 30px;
        height: 30px;
        border-radius: 30px;
        border: 3px solid white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
      }
      .icon.organization {
        background-image: url("../../img/district.svg");
      }
      .icon.partner {
        background-color: lighten($blue, 20%);
        background-image: url("../../img/support.svg");
      }
    }
  }
}
