.hq-backpack {
  #backpack-sidebar {
    #date-range-container {
      label {
        margin-left: 0 !important;
      }
    }

    .filterOptions li {
      height: 22px;
    }
  }

  .tilt-card {
    .topCategories {
      overflow: hidden;
    }
  }

  .backButtonContainer {
    position: fixed;
    top: 50px;
    left: 0;
    width: 250px;
    z-index: 1;
    border-bottom: 1px solid #f0f0f0;

    .button {
      text-align: left;
      justify-content: flex-start;
      border-radius: 0;
      width: 100%;
      height: 60px;
      font-size: 18px;
      font-weight: normal;
      text-transform: none;

      &:hover,
      &:active {
        background-color: rgba(0, 0, 0, 0.03);
      }

      .icon {
        width: 15px;
        height: 15px;
        background-image: url("../../img/back-arrow.svg");
      }
    }
  }

  .page.backpack-layout {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 1600px;
    margin-left: 60px;
    padding: 320px 35px 50px 220px;
    &.rubricsOnly {
      padding: 215px 35px 50px 220px;
    }
    .tilt-card {
      top: 85px;
    }

    .content-row {
      .inner {
        max-width: 1600px;
        width: auto;
      }

      header .count {
        color: black !important;
      }
    }

    #overview-wrapper.flipped .allCategories li {
      width: auto;
    }

    .alice-carousel__next-btn-item {
      width: 25px !important;
    }

    .alice-carousel__prev-btn-item {
      width: 25px !important;
    }

    .inner-carousel {
      .item.badge-item {
        width: calc(33% - 25px);
      }

      @media (max-width: 1600px) {
        .item.course-item {
          width: calc(50% - 25px);
        }
      }

      @media (min-width: 1600px) {
        .item.course-item {
          width: calc(33% - 25px);
        }
      }

      @media (max-width: 1000px) {
        .item.course-item {
          width: calc(100% - 30px);
        }

        .item.badge-item {
          width: calc(50% - 25px);
        }
      }

      .learning-path-item {
        width: calc(33% - 25px);

        @media (min-width: 1600px) {
          width: calc(20% - 25px);
        }

        @media (max-width: 1600px) {
          width: calc(25% - 25px);
        }

        @media (max-width: 1275px) {
          width: calc(33% - 25px);
        }
      }
    }

    // header {
    //   font-size: 12px;
    //   text-transform: uppercase;
    //   font-weight: bold;
    // }

    // &.print {
    //   max-width: calc(100% + 220px);
    //   margin: 0 0 0 -220px;
    //   padding-top: 0;
    //   margin-top: -55px;
    // }
  }
}
