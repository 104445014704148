@import "./variables.scss";

.success-navbar {
  background-color: white;
  border-radius: 100%;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  display: flex;
  cursor: pointer;
  transform: scale(1);
  transition: 0.2s;
  animation: fadeIn 1s forwards;
  margin-bottom: -35px;
  position: relative;
  top: -17.5px;

  &:hover {
    transform: scale(1.1);
  }

  .percent {
    color: black;
    font-size: 14px;
    font-weight: bold;
    position: relative;
    left: 1px;

    &:after {
      content: "%";
      font-size: 10px;
      font-weight: normal;
      position: relative;
      top: -1.5px;
    }
  }

  .CircularProgressbar-path {
    transition: 2s;
    stroke: $green !important;
  }

  .CircularProgressbar .CircularProgressbar-trail {
    stroke: white;
  }
}

#checklist-popover {
  .MuiPaper-root {
    margin-top: 10px;
    margin-left: 0px;
    width: 400px;
    padding: 0;
    border-radius: 12px;
    overflow: hidden;
  }
}

.success-checklist-container {
  background-color: white;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 28%);
  border-radius: 14px 0 0px 14px;
  overflow: hidden;

  h2 {
    margin: 0;
    padding: 10px;
    background-color: $green;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;

    .icon {
      width: 20px;
      height: 20px;
    }
  }

  footer {
    padding: 20px;

    .progressBarWrapper {
      .progressBar .progressBarInner {
        height: 20px;
      }
    }
  }

  ul {
    margin: 10px 0 0 0;
    padding: 0;
    list-style-type: none;

    li {
      padding: 10px 20px;
      display: flex;
      align-items: center;
      //      font-weight: bold;

      .icon.left {
        margin-right: 10px;
        width: 25px;
        height: 25px;
      }

      .icon.event {
        background-image: url("../img/event-sidebar.svg");
      }

      .icon.group {
        background-image: url("../img/school-black.svg");
      }

      .icon.users {
        background-image: url("../img/users-avatar.svg");
      }

      .label {
        flex: 1;
      }

      .icon.check {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        background-image: url("../img/i-check.svg");

        &.blank {
          background-image: url("../img/i-check-empty.svg");
        }
      }
    }
  }
}
