@import "../../styles/variables.scss";

.rubric-layout {
  padding-top: 70px;
  padding-bottom: 100px;
  max-width: 2000px;
  margin: 0 auto;

  .rubric-container {
    margin: 35px auto;
    min-height: 200px;

    &.isSubmitting {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  #topBar {
    background-color: white;
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 10px;
    animation: slideDown 1s forwards;
    z-index: 5;
    display: flex;
    align-items: center;

    .loadingWrapper.fullPage {
      height: 100vh;

      .panel {
        text-align: center;
      }
    }

    .icon.back {
      background-image: url("../../img/back-arrow.svg");
      width: 20px;
      height: 20px;
      margin: 0px;
    }

    .scoreTotals {
      span {
        text-transform: uppercase;
        font-weight: bold;
        opacity: 0.5;
        font-size: 14px;
        margin-right: 10px;
      }

      strong {
        background-color: $green;
        padding: 5px;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        color: black;
        font-weight: normal;
        min-width: 40px;
      }
    }

    .userInfo {
      display: flex;
      flex-direction: column;
      margin-right: 35px;

      .userName {
        display: block;
        font-size: 14px;
      }

      .userPicture {
        margin: 0;
        height: 125px;
        width: 125px;
        margin-right: 5px;
        border: 5px solid white;
        margin-bottom: 20px;
      }

      .groupName {
        opacity: 0.6;
        text-transform: uppercase;
        font-size: 10px;
        margin-top: -1px;
        display: block;
      }
    }
  }

  .buttonContainer {
    position: fixed;
    left: 0px;
    right: 0;
    bottom: 40px;
    display: flex;
    justify-content: center;
    animation: bounceInUp 0.6s forwards;

    .icon.submit {
      background-image: url("../../img/submit.svg");
    }
  }

  .title {
    font-size: 16px;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;

    .icon {
      opacity: 0.6;
      margin-right: 5px;
      position: relative;
      top: -1px;
      width: 15px;
      height: 15px;
      background-image: url("../../img/check-list.svg");
    }
  }

  #domain-list {
    list-style: none;
    margin: 0;

    .domain-item {
      margin-bottom: 20px;
      background-color: white;
      border-radius: 12px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);

      .flexRow.domain-name {
        padding: 20px;
        align-items: center;
        border-radius: 12px 12px 0 0;
        background-color: lighten($blue, 20%);
        border-bottom: 1px solid $light-grey;
      }

      .description {
        margin-top: 0;
        padding: 20px;
        margin-bottom: 0;
        border-bottom: 1px solid $light-grey;
        font-size: 18px;
      }

      header {
        text-transform: uppercase;
        font-size: 14px;
        background-color: white;
        padding: 5px 20px;
        border-radius: 20px;
        font-weight: bold;

        &.criteria-name {
          border-left: 10px solid $light-grey;
          background-color: $light-grey;
          border-radius: 0;
        }
      }

      h3 {
        margin: 0;
        flex: 1;
        font-size: 24px;
        text-transform: none;
        color: black;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        max-width: none;
        .icon.venn {
          width: 35px;
          height: 35px;
          margin-right: 10px;
          background-image: url("../../img/venn-diagram.svg");
        }
      }
    }
  }
}

.criteria-description {
  p {
    padding: 20px;
    margin: 0;
    font-style: italic;
    font-size: 16px !important;
    animation: fadeIn 0.5s forwards;
  }
}

.criteria-list {
  padding: 0;
  margin: 0;
  list-style-type: none;

  .icon.list {
    width: 20px;
    height: 20px;
    background-image: url("../../img/shopping-list.svg");
    margin-right: 10px;
  }

  .criteria-toolbar {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: -5px;

    .button {
      margin: 0 5px;
      color: black !important;
      position: relative;

      &:hover {
        .popover {
          opacity: 1;
        }
      }
    }

    .popover {
      transition: opacity 0.3s;
      opacity: 0;
      position: absolute;
      background-color: white;
      border-radius: 20px;
      box-shadow: 1px 4px 5px 0 rgba(0, 0, 0, 0.4);
      width: 110px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 35px;
      text-transform: none;
    }

    .icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
      margin-right: 0;

      &.info {
        background-image: url("../../img/info.svg");
      }

      &.stop {
        position: relative;
        top: -1px;
        width: 25px;
        height: 25px;
        background-image: url("../../img/n-a.svg");
      }

      &.comment {
        background-image: url("../../img/chat.svg");
      }
    }

    &.isNA {
      .na-button,
      .na-button button:focus,
      .na-button button:active {
        background-color: black !important;
        background: black !important;

        .icon {
          background-image: url("../../img/n-a-white.svg");
        }
      }
    }

    .na-button {
      border-radius: 20px;
      margin: 0 5px;

      .button {
        margin: 0 !important;
      }
    }
  }

  header {
    display: flex;
    align-items: center;
    padding: 10px !important;
  }

  li {
    p {
      font-size: 12px;
    }
  }
}

.criteria-comments {
  margin-left: 25px;
  padding-bottom: 15px;
  align-items: center;

  .icon.comment {
    position: relative;
    top: 8px;
    background-image: url("../../img/chat.svg");
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  .MuiFormControl-root {
    width: 50%;
  }
}

.criteria-grid {
  @media (min-width: 1150px) {
    align-items: flex-start !important;
  }
  padding: 10px;
  cursor: pointer;

  &.not-applicable {
    overflow: hidden;
    height: 0;
    opacity: 0.2;
    pointer-events: none;
  }

  .criteria-item {
    flex: 1;
    margin: 10px 5px 0 5px;
    padding: 10px 10px 0 10px;
    transition: 0.4s;
    background-color: transparent;
    border-radius: 12px;
    border: 2px solid transparent;

    header {
      transition: 0.4s;
      padding: 15px !important;
      justify-content: center;
      border-radius: 0px !important;
      background-color: $light-grey !important;
      background-color: transparent !important;
      border-bottom: 1px solid black;
    }

    &.active {
      border: 2px solid black;
    }

    &:hover,
    &.active {
      background-color: lighten($green, 40%);

      header {
        background-color: transparent !important;
      }
    }

    p {
      font-size: 14px;
    }
  }
}

.event-live-view {
  .page.rubric-layout {
    padding: 0;
    max-width: none;
    align-items: flex-start !important;
    @media (max-width: 1150px) {
      .flexRow.criteria-grid {
        flex-direction: column;
        align-items: stretch !important;
      }
    }
  }
}

#user-rubric-modal,
.event-live-view {
  .MuiStack-root.criteria-grid {
    margin-bottom: 10px;
    flex-direction: row;
  }
  @media (max-width: 1100px) {
    .MuiStack-root.criteria-grid {
      flex-direction: column;
      margin-bottom: 0px;
    }
  }
}
