@import "variables.scss";

.modal {
  .MuiDialog-paper {
    padding: 20px;

    footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 25px;

      a {
        text-decoration: none;
      }

      .button {
        margin-left: 20px;
      }
    }
  }
}

.jump-to-org-modal {
  .MuiPaper-root {
    min-width: 600px;
    min-height: 400px;
  }
}

.modal.add-new-room {
  .locationName {
    text-transform: uppercase;
    opacity: 0.6;
    font-size: 12px;
    margin-left: 35px;
    margin-top: -10px;
  }
}

.modal.course-share {
  .MuiDialog-paper {
    width: 650px !important;
    max-width: none;
  }

  .tos {
    font-size: 12px;
    position: absolute;
    left: 20px;
    right: 0;
    margin-top: -20px;

    .checkbox {
      margin-top: 15px;
    }

    .helperText {
      padding-top: 5px;
      font-size: 12px;
      max-width: 325px;
    }
  }

  .loadingWrapper {
    margin-top: 25px;
    justify-content: center;
  }

  &.isSubmitting {
    .inner {
      height: 200px;
      opacity: 0;
    }
  }

  .categorySelectFormControl {
    min-width: 250px;

    .MuiInputBase-root {
      padding-right: 10px;
    }
  }

  .banner {
    margin: 10px 0 20px;
    font-weight: bold;
    font-size: 12px;

    .inner {
      padding: 5px 10px;
      display: inline-block;
      border-radius: 6px;
      background-color: $green;

      &.warning {
        background-color: #f3b462;
      }
    }
  }

  h2 {
    .icon {
      width: 40px;
      height: 40px;
      background-image: url("../img/community-share.svg");
    }
  }

  p {
    margin: 10px 0;
  }

  .flexRow {
    position: relative;
    margin-bottom: 20px;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .helperText {
    position: absolute;
    left: 31px;
    opacity: 0.65;
    line-height: 16px;
    top: 32px;
    font-size: 14px;
  }

  .MuiFormControlLabel-root {
    margin-right: 0;
  }

  footer {
    margin-top: 75px;
    padding: 0;
  }
}

.modal.certificate-modal {
  .MuiDialog-paper {
    max-width: 500px !important;
  }

  .certificateLearningPath {
    min-height: 465px;
    @media (max-width: 700px) {
      margin: 0 auto;
      min-height: 370px;
      height: 370px !important;
      width: 400px !important;

      div.inner {
        min-height: 330px !important;
        height: 330px !important;
        width: 360px !important;
      }
    }
    .flexRow.categories {
      margin-left: 5px;

      img {
        border: 2px solid white;
        box-sizing: content-box;
      }

      .category-icon {
        width: 20px !important;
        height: 20px !important;
        @media (max-width: 700px) {
          width: 15px !important;
          height: 15px !important;
        }
      }
    }

    overflow: hidden;

    h2 {
      align-items: flex-start;
    }
  }
}

@keyframes copySlide {
  0% {
    opacity: 0;
    left: -120px;
  }

  50% {
    opacity: 1;
    left: 0;
  }

  100% {
    opacity: 1;
    left: 0;
  }
}

.copy-animation {
  display: flex;
  overflow: hidden;
  justify-content: center;
  margin: 20px 0;

  &.green {
    .course-card {
      background-image: url("../img/course-card-animate-green.svg");
    }
  }

  .course-card {
    display: block;
    width: 80px;
    height: 80px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../img/course-card-animate.svg");
    position: relative;
    z-index: 3;

    &.copy {
      background-image: url("../img/course-card-animate.svg");
      z-index: 1;
      margin-left: 40px;
      animation: copySlide 2s infinite;
    }

    &.copy.calendar {
      background-image: url("../img/calendar-grey.svg");
    }
  }
}

#roles-popover {
  margin-left: -25px;
  margin-top: 5px;
  top: 40px !important;
  width: auto;

  .MuiPopover-paper {
    overflow: visible;
    position: fixed;
    right: 55px;
    left: auto !important;
    top: 60px !important;
    width: auto !important;

    &:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid white;
      position: absolute;
      top: -8px;
      margin-right: -8px;
      right: 50%;
    }
  }

  .icon {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    background-color: lighten($blue, 20%);
    border-radius: 100%;

    &.group {
      background-image: url("../img/onboarding-school.svg");
    }

    &.district {
      background-image: url("../img/onboarding-district.svg");
    }
  }

  ul {
    list-style-type: none;
    padding: 5px 0;
    margin: 0;

    li {
      display: flex;
      align-items: center;
      padding: 10px;
      margin: 0;
    }
  }
}

.modal.newEvent {
  .MuiPaper-root.MuiDialog-paper {
    width: 80vw;
    height: 75vh;
    min-width: 80vw;
    min-height: 75vh;
    max-width: 80vw;
    max-height: 75vh;
    background-color: $light-grey;

    .event-single {
      overflow: auto;
      margin-right: -35px;
    }

    .sidebar-tabs {
      padding: 0;
    }

    .event-content {
      margin-top: 10px;
      margin-left: 30px;
    }

    .sidebar-event {
      color: white;
      position: absolute;
      padding-top: 0;
      left: -20px;
      top: 40px;
      width: 60px;
      padding-right: 0;

      .icon {
        margin-right: 50px;
      }

      ul {
        overflow: hidden;
        padding: 0 20px;
      }

      .divider {
        display: none;
      }
    }

    .subnav {
      position: absolute;
      top: 0;
    }
  }
}

.user-select-modal {
  .MuiTabs-root {
    height: 35px;
    min-height: unset;
    flex-shrink: 0;
    .MuiTabs-indicator {
      display: none;
    }
    .MuiTabs-flexContainer {
      gap: 20px;
    }
    .MuiTab-root {
      border-radius: 17.5px;
      background-color: $light-grey;
      padding-inline: 20px;
      transition: 0.3s;
      height: 35px;
      min-height: unset;
      min-width: 120px;
      font-weight: bold;
      font-size: 12px;
      &:hover {
        background-color: darken($light-grey, 5%);
      }
      &.Mui-selected {
        background-color: $blue;
        color: white;
      }
    }
  }

  .attendee-select {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    .Mui-selected {
      color: white !important;
      font-weight: bold;
      background-color: $blue !important;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .event-select-list {
    width: 100%;
    .icon.reviewer-group {
      background-image: url("../img/reviewer-group.svg");
      border-radius: 0;
      box-shadow: none;
    }

    li {
      border-bottom: 1px solid $light-grey;
      margin: 0;
      padding-left: 20px;

      &:first-child {
        border-top: 1px solid $light-grey;
      }

      &:hover {
        background-color: $light-grey;
      }
    }
  }
}

.token-modal {
  .MuiFormControl-root {
    // width: 100%;
    padding-top: 5px;

    .MuiFormHelperText-root {
      // opacity: 0;
      transition: 0.4s;
      text-align: center;
    }

    &.showHelperText {
      .MuiFormHelperText-root {
        opacity: 1;
      }
    }
    input {
      cursor: pointer;
      margin: 0 !important;
      text-align: center;
      width: 100%;
    }
  }
  .icon.close {
    position: absolute;
    right: 10px;
    top: 35px;
  }
}
