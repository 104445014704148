@import "variables.scss";

.courseSelect {
  padding-bottom: 200px;

  .filterRow {
    .MuiFormLabel-root {
      background-color: $light-grey;
    }
  }

  ul {
    padding: 0;
  }

  .mm-only-disclaimer {
    margin-top: -20px;
    font-size: 14px;
    bottom: 50px;
    padding: 5px 0;

    font-style: italic;
  }

  &.hasFetched .loadingWrapper {
    pointer-events: none;

    &:not(.fullPage) {
      top: auto;
      bottom: 100px;
    }
  }

  .MuiFormControl-root {
    margin-right: 35px;
    flex: 1;

    &:last-child {
      margin-right: 0px;
    }
  }
}

.course-list-item {
  .category-icon {
    width: 35px;
    height: 35px;
  }

  .estimatedTime {
    position: absolute;
    top: 50%;
    transform: translateY(-15px) scale(1);
    right: 45px;
  }
}

.course-list-item {
  list-style-type: none;
  padding: 10px 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
  background-color: white;
  position: relative;
  border-left: 0px solid #f0f0f0;
  transition: 0.4s;
  opacity: 0;
  animation: fadeInUp 0.3s forwards;
  user-select: none;
  border-bottom: none;

  .category-icon {
    border: 3px solid white;
  }

  .visibilityList {
    display: flex;
    margin: -15px 0 0 -5px;
    padding: 0 0 10px 0;
    list-style-type: none;
    flex-wrap: wrap;

    li {
      display: flex;
      margin-top: 15px;
      align-items: center;

      strong {
        margin-right: 15px;
        display: flex;
        font-size: 12px;
      }
    }

    .icon {
      margin-right: 10px;
      background-color: $light-grey;
      padding: 5px;
      width: 15px;
      height: 15px;
      background-size: 30px;
      border-radius: 25px;
      box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.3);

      &.group {
        background-image: url("../img/onboarding-school.svg");
      }
    }
  }

  .icon.indicator {
    transition: 0.4s;
    position: absolute;
    top: 50%;
    transform: scale(1);
    right: 20px;
    width: 15px;
    height: 15px;
    margin-top: -8px;
  }

  &.added {
    border-left: 5px solid $green;
  }

  .course-details {
    margin-right: 110px;
  }

  &:hover {
    background-color: $lightest-grey;
    cursor: pointer;

    .icon.indicator {
      transform: scale(1.5);
    }
  }

  .excludedText {
    padding: 10px 20px;
    margin: 20px -20px -20px;
    background-color: #f0f0f0;
  }

  &:last-child {
    border-bottom: none;
    border-radius: 0 0 12px 12px;
  }

  &:first-child {
    border-radius: 12px 12px 0 0;
  }

  &.single {
    border-radius: 12px;
  }

  header {
    display: flex;
    align-items: center;
  }

  h2 {
    margin-top: 10px;

    @media (max-width: $screen-sm-max) {
      font-size: 16px;
    }
  }

  p {
    @media (max-width: $screen-md-max) {
      margin-top: 8px;
    }

    @media (max-width: $screen-sm-max) {
      font-size: 10px;
      line-height: 12px;
    }
  }

  .icon.star {
    background-image: url("../img/star-black.svg");
    width: 15px;
    margin-right: 5px;
  }

  .category-icon-wrapper {
    .category-icon {
      height: 20px;
      width: 20px;

      @media (max-width: $screen-sm-max) {
        height: 15px;
        width: 15px;
      }
    }
  }
}
