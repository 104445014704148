@import "variables.scss";

.hexPicker {
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: center;
  .hash {
    display: block;
    position: absolute;
    margin-right: 50px;
  }
  input {
    padding: 5px 8px;
    margin-left: 5px;
    padding-left: 20px;
    width: 55px;
    border-radius: 18px;
    border: none;
    font-family: "Nunito", sans-serif;
  }
}

.badgeStudioContainer {
  justify-content: "center";
  align-items: "center";

  .react-colorful {
    width: 300px;
  }

  .react-colorful__saturation {
    border-radius: 10px;
  }

  .react-colorful__pointer {
    border: 4px solid #fff;
  }

  .react-colorful__last-control {
    height: 20px;
  }

  .badgeName {
    width: 90%;
    margin-left: 0px !important;
    padding-right: 25px;
  }

  .badgeNameContainer {
    position: relative;
    top: -40px;
  }

  .MuiDialog-container {
    width: 100%;
    height: 100%;
    margin: 0;
    margin-right: 0px;

    .MuiPaper-root {
      margin: 0 !important;
      max-width: 100vw;
      width: 100vw;
      max-height: 120vh;
      padding: 0px;
      position: fixed;
      top: 100px;
      left: 0;
      right: 0;
      box-shadow: none;
      bottom: 0;
      border-radius: 0px;
    }

    .inputSelect.collection {
      min-width: 175px;
    }

    .inner {
      opacity: 1;
      transition: 0.6s;

      &.blur {
        opacity: 0.2;
        pointer-events: none;
      }
    }

    .spinner {
      position: absolute;
      top: 45%;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;

      strong {
        position: relative;
        top: 40px;
        z-index: 10;
        margin-left: -15px;
      }
    }

    .spinner-inner div {
      width: 100px;
      height: 100px;
      position: absolute;
    }

    .colorsContainer {
      z-index: 10;
      position: relative;
    }

    .tabBar {
      list-style-type: none;
      margin: 0;
      padding: 20px 0;
      margin-left: 25px;
      display: flex;
      flex-wrap: wrap;

      li {
        display: inline-block;
      }
    }

    .button {
      border-radius: 50px;
      background-color: $light-grey;
      padding: 10px 20px;
      margin-right: 15px;
      margin-bottom: 15px;
      flex-shrink: 0;

      &.active {
        background-color: #24dc91;

        &:hover {
          background-color: #24dc91;
        }
      }

      &:hover {
        background-color: darken($light-grey, 10%);
      }

      span {
        color: black;
        font-weight: bold;
        text-transform: none;
      }
    }

    .exceededText {
      animation: fadeOutUp 0.4s forwards;

      &.active {
        opacity: 1;
        animation: fadeInDown 0.4s forwards;
      }
    }

    .fileUpload {
      position: relative;

      input {
        height: 40px;
        top: 0;
        right: 0;
        opacity: 0;
        cursor: pointer;
        left: 0;
        position: absolute;
      }
    }

    .picker {
      position: absolute;
      top: 100px;
      left: 300px;
      z-index: 3;
      opacity: 0;
      transition: 0.3s;
      pointer-events: none;
      box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.14);
      background: black;
      border-radius: 20px;
      footer {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      &.active {
        pointer-events: all;
        opacity: 1;
        left: 360px;
      }
    }

    .studioContainer {
      padding: 20px;
      display: flex;
      flex: 1;

      .column {
        padding-top: 0px;
        position: relative;
        z-index: 3;
      }

      .column.right {
        height: 80vh;
        margin-left: 350px;

        .header-container {
          margin-bottom: 20px;
        }
      }
    }

    .selectedImageContainer {
      position: absolute;
      width: 160px;
      height: 160px;
      border-radius: 180px;
      pointer-events: none;
      z-index: 2;
      top: 70px;
      left: 71.5px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      .selectedImage {
        width: 100%;
      }
    }

    .badgeContainer {
      padding: 20px;
      padding-top: 60px;
      position: absolute;
      left: 25px;
      overflow-y: auto;
      padding-bottom: 100px;
      height: 80%;

      &::-webkit-scrollbar {
        display: none;
      }

      .content {
        max-width: 320px;

        .button {
          width: 100%;
          color: black;
          text-transform: none;
          font-weight: bold;
        }

        .icon.palette {
          position: relative;
          top: 4px;
          background-image: url("../img/paint-board-and-brush.svg");
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }

        .icon.diskette {
          background-image: url("../img/diskette.svg");
        }

        .icon.download {
          background-image: url("../img/downloading.svg");
        }
      }

      .badge-top-ring {
        z-index: 4;
        left: 40px;
        top: 30px;
        position: absolute;
        pointer-events: none;
      }

      .badge-star {
        z-index: 6;
        left: 120px;
        top: 21px;
        position: absolute;
      }

      .badge-bottom-layer {
        position: absolute;
        z-index: 1;
        top: 0;
      }

      #outerCircle,
      #star {
        cursor: pointer;
        transition: 0.4s;
        opacity: 1;

        &:hover {
          opacity: 0.6;
        }
      }
    }

    .studio-header {
      font-size: 28px;
      font-weight: bold;
      display: flex;
      padding: 20px;
      background-color: #24dc91;

      .icon.close {
        width: 45px;
        height: 45px;
        background-image: url("../img/x-large.svg");
        background-size: 90%;
        margin-right: 10px;
        cursor: pointer;
        transition: 0.1s;
        opacity: 0.8;

        &:hover {
          opacity: 1;
          background-size: 1 00%;
        }
      }

      .icon.chair {
        background-image: url("../img/director-chair-color.svg");
        width: 40px;
        height: 40px;
        background-size: contain;
        display: inline-block;
        margin-right: 10px;
      }
    }

    .MuiTextField-root {
      margin-left: 25px;
    }

    .image-grid {
      overflow-y: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;

        li {
          width: 80px;
          height: 80px;
          margin: 10px;
          padding: 25px;
          background-color: transparent;
          transition: 0.4s;
          border-radius: 12px;
          cursor: pointer;

          &:hover {
            background-color: $light-grey;
          }

          &.active {
            background-color: #24dc91;
          }

          img {
            max-width: 100%;
          }
        }
      }
    }
  }

  &.fullScreen {
    .studioContainer .column.right {
      height: 100vh;
    }

    .MuiPaper-root {
      top: 0;
    }
  }
}
