@import "../../../styles/variables.scss";

@keyframes delay {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media print {
  .capsuleWrapper {
    background-color: black !important;
    print-color-adjust: exact;
  }
}

.mandated-certificate-circle {
  background-color: black;
  color: white;
  display: inline-flex;
  border-radius: 100%;
  height: 80px;
  width: 80px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 8px solid $green;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
  position: absolute;
  right: 20px;
  bottom: 20px;
  strong {
    font-size: 22px;
    display: block;
    top: -5px;
    left: -2px;
    position: relative;
    &:after {
      content: "%";
      display: block;
      position: absolute;
      font-weight: normal;
      font-size: 10px;
      top: 7.5px;
      right: -10px;
    }
  }
  span {
    font-size: 8px;
    left: 0;
    right: 0;
    bottom: 15px;
    text-align: center;
    text-transform: uppercase;
    position: absolute;
  }
}

.certificateWrapper {
  // position: fixed;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  // display: flex;
  // background-color: #efefef;
  // flex-direction: column;

  &.print {
    background-color: #fff;
    .certificateWrapper {
      padding: 20px;
    }
    .learningPathImage {
      width: 1100px;
      height: 850px;
    }
    .inner {
      border-radius: 12px;
      overflow: hidden;
    }
  }

  .logo {
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
  }
}

.MuiDialog-paper {
  footer {
    display: flex;
    justify-content: center;
    padding: 20px 10px;

    &.certificateFooter {
      display: flex;
      justify-content: center;
      padding: 20px 10px;

      .button {
        margin: 0 15px;
        color: black;

        a {
          display: flex;
          align-items: center;
          color: black;
        }
      }
    }
  }

  .certificateWrapper {
    padding-top: 0;
    position: relative;
    background-color: rgba(0, 0, 0, 0) !important;

    .category-icon {
      width: 20px;
      display: inline-block;
      height: 20px;
      background-size: contain;
      background-repeat: no-repeat;
      border-radius: 100%;
      margin: 5px 5px 0 0;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
    }

    .inner {
      z-index: 4;
      position: relative;
    }
  }
}

.certificate-modal {
  .MuiDialog-paperWidthSm {
    overflow: auto !important;
    border-radius: 13px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .certificateLearningPath .inner {
    width: 460px !important;
    height: 425px !important;
    padding: 20px !important;
    display: flex;
    flex-direction: column;
    align-items: center;

    .categories {
      justify-content: center;
      .time {
        display: none !important;
      }

      .category {
        margin-right: 0px;
      }
    }

    .totalEstimatedTime {
      bottom: 20px;
      right: 20px;

      header {
        font-size: 12px;
      }

      .icon {
        top: 5px;
        margin-right: 5px;
      }

      strong {
        font-size: 18px;
      }

      em {
        display: none;
      }
    }

    h2 {
      max-width: 300px;
      font-size: 24px;
      margin-top: 0px;
      margin-bottom: 0px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-items: center;
      text-align: center;
      height: auto;

      span {
        margin: 5px 0;
        line-height: 14px;
        font-size: 12px;
      }
    }

    .title {
      font-size: 32px;
      margin-top: 0px;
      line-height: 30px;
    }

    .categories {
      max-width: 300px;
      margin: 0;
      margin-left: 0;
      justify-content: center;
      img {
        margin: 0 10px 10px 0;
        width: 20px;
        height: 20px;
        right: 0;
        border: 2px solid white;
      }
    }

    .logo {
      height: 25px;
      width: auto;
    }
  }
}

.learningPathImage {
  margin: 0 auto;
  position: absolute;
  left: 0%;
  top: 0;
  opacity: 0.15;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: 1;
}

.learningPathImageWrapper {
  position: absolute;
  border-radius: 100%;
  overflow: hidden;
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  right: -120px;

  img {
    width: auto;
    height: 300px;
  }
}

.certificateWrapper {
  .loadingWrapper {
    flex-direction: column;

    span {
      margin-left: 0px;
      top: 10px;
    }
  }
}

.bg-badge {
  width: 500px;
  height: 500px;
  z-index: 1;
  position: absolute;

  &.bg-event {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  // background-color: silver;
  // background-image:
  //   radial-gradient(circle at 100% 150%, silver 24%, white 24%, white 28%, silver 28%, silver 36%, white 36%, white 40%, transparent 40%, transparent),
  //   radial-gradient(circle at 0 150%, silver 24%, white 24%, white 28%, silver 28%, silver 36%, white 36%, white 40%, transparent 40%, transparent),
  //   radial-gradient(circle at 50% 100%, white 10%, silver 10%, silver 23%, white 23%, white 30%, silver 30%, silver 43%, white 43%, white 50%, silver 50%, silver 63%, white 63%, white 71%, transparent 71%, transparent),
  //   radial-gradient(circle at 100% 50%, white 5%, silver 5%, silver 15%, white 15%, white 20%, silver 20%, silver 29%, white 29%, white 34%, silver 34%, silver 44%, white 44%, white 49%, transparent 49%, transparent),
  //   radial-gradient(circle at 0 50%, white 5%, silver 5%, silver 15%, white 15%, white 20%, silver 20%, silver 29%, white 29%, white 34%, silver 34%, silver 44%, white 44%, white 49%, transparent 49%, transparent);
  // background-size: 100px 50px;
}

.certificateBadge {
  width: 500px;
  height: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .printBadge {
    display: none;
  }

  @media print {
    .badgeImage {
      display: none;
    }

    .badgeImage.printBadge {
      display: block;
    }
  }

  h2 {
    margin-top: 0px;
    z-index: 2;
    font-size: 28px;
    text-align: center;
    font-weight: 700;
    padding: 35px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    strong {
      display: block;
      font-weight: 500;
      font-size: 14px;
      text-transform: uppercase;
    }
  }

  footer {
    display: flex;
    z-index: 2;
    padding: 35px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      font-weight: 700;
      font-size: 28px;
      text-align: center;
      max-width: 400px;
      line-height: 32px;
    }

    .timestamp {
      text-transform: uppercase;
      margin-top: 5px;
      margin-bottom: 15px;
    }
  }

  .logo {
    height: 25px;
    width: auto;
  }
}

.badgeImageWrapper {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;

  .capsuleWrapper {
    opacity: 0;
    animation: fadeIn 1s forwards;
    animation-delay: 2s;
    position: absolute;
    display: flex;
    justify-content: center;
    margin-bottom: -150px;
    background-color: black;
    border-radius: 25px;
    align-items: center;
    color: white;
    padding: 5px 20px;

    .capsule {
      padding: 0px;
    }

    .categoryInfo {
      display: flex;
      align-items: center;
      margin-left: 20px;

      .category-icon {
        margin: 0 5px 0 0;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        border: 2px solid white;
        background-size: cover !important;
      }
    }
  }

  .badgeImage,
  .eventImage {
    animation: tada 2s forwards;
    width: 150px;
    height: 150px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 100%;
  }

  .eventImage {
    width: 350px;
    height: 200px;
    margin-top: -50px;
    border-radius: 14px;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.35);

    &.empty {
      background-image: url("../../../img/calendar-white.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 80px;
    }
  }
}

.shareLink {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  position: relative;
  padding-top: 20px;
  height: 200px;

  .loadingWrapper {
    left: -30px;
    margin-top: 10px;

    .square {
      border-color: white;
    }
  }

  .shareUrl {
    padding: 0 20px 20px;
  }

  .MuiFormControl-root {
    width: 100%;
    padding-top: 5px;

    .MuiFormHelperText-root {
      opacity: 0;
      transition: 0.4s;
      text-align: center;
    }

    &.showHelperText {
      .MuiFormHelperText-root {
        opacity: 1;
      }
    }
  }

  .flexRow {
    padding-bottom: 30px;
    justify-content: center;

    .button {
      margin: 0 5px;

      .icon {
        position: relative;
        top: -1px;
        margin-right: 5px;
        border-radius: 4px;
        overflow: hidden;
      }
    }
  }

  input {
    text-align: center;
    width: 100%;
    cursor: pointer;
    margin: 0 !important;
  }
}

.certificateEvent {
  .title {
    font-size: 24px !important;
  }
}

.MuiDialog-scrollPaper {
  .certificateFooter {
    margin-top: -12px;
    z-index: 1;
    background: white;
  }

  .learningPathImage {
    opacity: 0.1;
    background-size: cover;
    background-position: center;
  }
}
