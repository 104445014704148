@import "variables";

#sidebar {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 250px;
  padding: 70px 20px 20px;
  z-index: 2;
  transition: 0.4s;
  overflow-x: hidden;
  margin-right: 120px;

  button {
    font-family: "Nunito" !important;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: $screen-sm-max) {
    padding: 105px 20px 20px;
  }

  ul {
    margin: 0;
  }

  .itemLabel {
    position: absolute;
    left: 45px;
    width: 300px;
  }
}

#sidebar.sectioned {
  padding: 50px 0 0 0;

  .MuiAccordion-root {
    box-shadow: none;
    border-bottom: 1px solid darken($light-grey, 10%);
    border-radius: 0;
    cursor: pointer;
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }

  h3 {
    margin: 0;
    font-weight: bold;
    font-size: 18px;
    padding: 20px;
    color: rgba(0, 0, 0, 0.87);
    text-transform: none;
  }

  .icon {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  ul {
    padding-bottom: 10px;
    list-style-type: none;
    padding-left: 0;
    border-bottom: 0;

    li {
      transition: 0.4s;

      button {
        border-radius: 0;
        height: 55px;
        display: flex;
        align-items: center;
        width: 100%;
        margin: 0;
        justify-content: flex-start;
        padding: 15px 20px;
        text-transform: none !important;
        font-weight: bold !important;
        text-align: left;

        &:not(.active):hover {
          background-color: rgba(0, 0, 0, 0.1) !important;
        }

        .MuiButton-label {
          font-weight: bold;
          font-size: 14px;
          text-align: left !important;
          text-transform: none;
        }
      }
    }
  }
}
