@import "./variables.scss";

.category-breadcrumbs {
  display: flex;
  align-items: center;
  padding-top: 5px;
  flex-wrap: wrap;

  .caret {
    background-image: url("../img/caret-right.svg");
    width: 10px;
    height: 10px;
    display: inline-block;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 2px;
    position: relative;
    top: -3px;
  }

  .parent {
    background-color: darken($light-grey, 4%);
    border-radius: 20px;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    flex-shrink: 0;
    margin-bottom: 5px;
    padding: 4px 8px;
  }
}

#categorySelect-popup {
  li {
    padding: 0;

    > div {
      padding: 10px 15px;
      position: relative;

      .category-icon-wrapper {
        width: 20px;
        height: 20px;
        position: relative;
        top: -7px;
        margin-right: 5px;

        .category-icon {
          width: 20px !important;
          height: 20px !important;
        }
      }
    }
  }
}

.top-level {
  background-color: white;
  z-index: 5;
  width: 100%;

  &:hover {
    background-color: $light-grey;
  }
}

.second-level,
.third-level {
  position: relative;
}

.second-level {
  margin-left: 15px;
  padding-left: 15px;
}

.third-level {
  margin-left: 30px;
  padding-left: 5px;
}

.menu-category-icon {
  position: relative;
  top: 2px;

  .category-icon {
    border: 3px solid white;
  }
}

.parentCategory-select {
  margin-top: 25px;
}

[aria-labelledby="label-parent-category"] {
  .MuiListItem-button {
    height: 50px;
  }
}
