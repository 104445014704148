@import "../../../styles/variables.scss";

#user-rubric-modal {
  .MuiPaper-root {
    min-width: 90vw;
    margin: 0px;
    overflow: scroll;
    top: 25px;
    border-radius: 12px 12px;
    max-height: 80vh;
  }
}
