@import "variables.scss";

#date-range-container {
  label {
    margin-left: -15px;
  }
}

.page.reports {
  padding-top: 130px !important;
  padding-bottom: 150px;

  &.teacherAccess,
  &.compliance {
    a {
      padding: 20px;
      display: flex;
      align-items: center;

      strong {
        display: block;
      }
    }
  }

  .attempt-popover {
    position: absolute;
    right: 10px;
    top: -2px;
    z-index: 5;
    transition: 0.4s;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.35);
    border-radius: 20px;
    background-color: $green;
    padding: 3px 10px;
    font-weight: "normal";
    font-size: 12px;
    opacity: 0;
  }
  .compliance-completed-date {
    position: relative;
    &:hover {
      .attempt-popover {
        right: 30px;
        opacity: 1;
      }
    }
  }
  &.compliance,
  &.analytics {
    .user-label-user-image-container {
      &.user-has-image {
        border: 3.5px solid white;
        margin-left: 5px;
        margin-right: 12px;
        width: 43px;
        height: 43px;
      }
      > div,
      > div > div {
        width: 50px !important;
        height: 50px !important;
      }
    }

    .user-label-user-name {
      &.multi-group {
        margin-bottom: -10px;
      }
    }
  }

  .icon {
    opacity: 1;
    flex-shrink: 0;
  }

  .total .capsule {
    padding: 0px 15px;
    margin-right: -10px;
    height: 30px;
    background-color: $green;
    color: black;

    .icon.clock {
      background-image: url("../img/clock-black.svg");
    }
  }

  .capsule {
    font-weight: normal;
    padding: 5px 15px;

    .icon.clock {
      width: 15px;
      height: 15px;
    }
  }

  .loadingWrapper {
    position: relative;
    animation: fadeInUp 1s forwards;
    top: 50px;
    margin-left: 0 !important;
  }

  .icon.learningPath {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    background-image: url("../img/learning-path.svg");
  }

  .partnerInfo {
    display: flex;
    align-items: center;
    padding: 20px;

    strong {
      font-size: 18px;
    }

    .icon.district {
      margin-right: 15px;
      border-radius: 40px;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
      background-image: url("../img/district.svg");
    }
  }

  &.teacherAccess {
    th {
      .flexRow {
        justify-content: flex-start;
        display: inline-flex;

        span {
          margin-right: 5px;
          top: 0;
        }
      }
    }

    td {
      span {
        font-size: 12px;
      }
    }

    table {
      max-width: none;
    }
  }

  #sidebar {
    padding-top: 70px;

    .MuiInputBase-inputAdornedEnd {
      padding-left: 0px !important;
    }

    .MuiFormControl-root {
      margin-bottom: 20px;
    }
  }
  &.partner-report {
    .MuiAccordionSummary-content {
      .icon.caret {
        width: 10px;
        margin-left: 10px;
        height: 10px;
        opacity: 0.7;
        background-image: url("../img/caret.svg");
        transform: rotate(0deg);
        transition: 0.3s;
      }
      margin: 0;
      align-items: center;
    }
    .Mui-expanded {
      .icon.caret {
        transform: rotate(-180deg);
      }
    }
    .MuiTableCell-root {
      user-select: none;
      display: flex;
      width: 100%;
      strong {
        display: flex;
        align-items: center;
      }
      .icon.school {
        background-image: url("../img/school.svg");
        border: 3px solid white;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
        width: 25px;
        height: 25px;
        border-radius: 50px;
        margin-right: 15px;
      }
      .MuiPaper-root {
        margin: 0;
        border-radius: 0px !important;
        box-shadow: none !important;
        &:hover {
          background-color: rgba(0, 0, 0, 0.02);
        }
      }
      .MuiAccordionSummary-root {
        background-color: transparent;
      }
    }
    .MuiTableHead-root {
      max-width: 1020px;
    }
    .MuiTableRow-head {
      display: flex;
      th:first-child {
        flex: 1;
      }
    }

    th {
      display: table-cell !important;
      width: auto !important;
    }
  }
  .MuiTableCell-root {
    user-select: none;
  }

  .inputSearch {
    .icon {
      position: absolute;
      margin-top: -5px;
    }

    input {
      min-width: 90%;
      max-width: 100%;
    }
  }

  th {
    .flexRow {
      justify-content: flex-end;
      line-height: 14px;
      padding-right: 15px;
    }

    .icon.sort {
      margin-left: -10px;
    }

    .total {
      margin-left: 10px;
      font-size: 28px;
      text-transform: none;
      font-weight: 700;
      color: $blue;
    }
  }

  .items-completed {
    font-size: 28px;
    font-weight: 700;
    color: $blue;

    .laptop {
      background-image: url("../img/laptop.svg");
      width: 25px;
      height: 25px;
      margin-left: 5px;
    }

    .capsule {
      font-weight: normal;
    }
  }

  .icon.check {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    background-image: url("../img/i-check.svg");
  }

  .icon.time {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }

  .icon.badge {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    background-image: url("../img/i-badge.svg");
  }
}

.page.compliance {
  .quizScore {
    text-transform: uppercase;
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    padding: 0 10px;
    margin-top: 5px;
    align-items: center;

    strong {
      font-size: 24px;
      color: $green;
      position: relative;
      top: -2px;
      margin-left: 5px;
    }
  }

  .helperText {
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 400px;
    margin-top: 100px;

    .icon {
      width: 35px;
      height: 35px;
      margin-bottom: 20px;
      &.mandated {
        background-image: url("../img/mandatory.svg");
      }
    }
  }

  tr th:last-child {
    width: 400px;
  }

  th .flexRow {
    span {
      margin-right: 5px;
      margin-left: 0;
    }

    .icon {
      top: 0px;
    }

    justify-content: flex-start !important;
  }

  .MuiTableCell-alignRight {
    padding-right: 15px !important;

    strong {
      justify-content: flex-end;
      display: flex;
      align-items: center;

      .icon.inbox {
        background-image: url("../img/inbox.svg");
      }
      .icon.overdue {
        background-image: url("../img/clock-overdue.svg");
      }
      .icon {
        margin-left: 10px;
        height: 20px;
        width: 25px;
        flex-shrink: 0;
        position: relative;
        top: -1px;
      }
    }
  }

  .badgeEarned {
    position: relative;

    .badgeImage {
      background-size: cover;
      width: 50px;
      height: 50px;
      margin-left: 100px;
      border-radius: 100%;
    }

    .icon.check {
      position: absolute;
      z-index: 3;
      right: -15px;
      opacity: 1;
      top: -3px;
    }
  }

  .visibility {
    text-transform: initial;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 5px;
    cursor: pointer;
    position: relative;

    .icon.eye {
      margin-right: 5px;
      background-image: url("../img/eye.svg");
      width: 20px;
      height: 20px;
    }

    &:hover {
      .content {
        opacity: 1;
        position: absolute;
        z-index: 2;
      }
    }

    .content {
      opacity: 0;
      transition: opacity 0.3s;
      top: 20px;
      right: -20px;
      position: absolute;
      padding: 10px 20px;
      pointer-events: none;

      .inner {
        background-color: white;
        min-width: 200px;
        padding: 10px 20px;
        border-radius: 4px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
        font-weight: normal;
        text-align: left;

        header {
          text-transform: uppercase;
          opacity: 0.5;
          margin: 10px 0 0;
          font-weight: bold;
          font-size: 12px;

          &:first-child {
            margin: 0;
          }
        }

        ul {
          margin: 0;
          padding: 0;
          list-style-type: none;

          li {
            font-weight: bold;
          }
        }
      }
    }
  }

  .entityName {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 18px;
    text-transform: none;

    &.deadline {
      margin-top: -20px;
      opacity: 0.6;
      font-size: 14px;
    }

    .category-icon {
      height: 30px !important;
      width: 30px !important;
    }
    .icon.mandated-training {
      background-image: url("../img/mandatory.svg");
      width: 25px;
      height: 25px;
      margin-right: 5px;
    }
    .entityImage {
      margin-right: 10px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
      border-radius: 100%;
      background-size: cover;
      background-position: center;
      height: 30px;
      width: 30px;
    }

    tbody {
      .icon {
        opacity: 1;
      }
    }
  }

  .userWrapper {
    display: flex;
    flex-direction: column;
  }

  tbody tr td:nth-child(3) {
    width: 200px;
  }

  tbody tr:hover {
    background-color: white;
  }

  tbody tr.active td:first-child {
    position: relative;

    &:before {
      width: 6px;
      content: "";
      display: block;
      background-color: $green;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    }
  }

  tbody tr.active:first-child {
    td:first-child:before {
      border-radius: 12px 0 0 0;
    }
  }

  .progressBarWrapper {
    max-width: 200px;

    .track {
      margin-bottom: -6px;
      background-color: #84d2e9;
      background-image: url("../img/progress-bg.svg");
      height: 6px;
      background-size: 490px;
      border-radius: 30px;
      background-position: center;
    }
  }

  tr.inactive td {
    border-left: 0px !important;
  }
}

.submissionButton {
  .MuiButtonBase-root {
    padding: 10px;
    width: auto;
    min-width: 0;
    background-color: rgba(0, 0, 0, 0);
    box-shadow: none;

    &:focus,
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
      box-shadow: none;
    }
  }

  .icon.feedback {
    background-image: url("../img/feedback.svg");
    width: 20px;
    height: 20px;
    margin: 0 !important;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.complianceProgress {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  span {
    font-size: 14px !important;
    color: $dark-grey;
  }
}

.challengePopover {
  .MuiPopover-paper {
    overflow: visible;

    .inner {
      max-width: 450px !important;
      border-radius: 5px;
      min-width: 250px;

      p {
        overflow-wrap: break-word;
        word-wrap: break-word;
      }

      &:before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid white;
        position: absolute;
        margin-top: -7px;
        margin-left: 12px;
      }
    }

    .answerHeader {
      padding: 15px 15px 0 15px;
      font-weight: bold;
    }

    .submissionLink {
      transition: 0.3s;
      margin-top: 10px;
      height: 300px;
      display: block;
      position: relative;
      border-radius: 0 0 4px 4px;
      overflow: hidden;

      &:after {
        position: absolute;
        content: "";
        display: block;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-image: url("../img/preview.svg");
        background-size: 40px;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0;
        transition: 0.3s;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.9);

        &:after {
          opacity: 1;
        }

        img {
          opacity: 0.5;
        }
      }
    }

    .submissionImage {
      height: 300px;
      width: auto;
      transition: opacity 0.3s;
      position: relative;
    }

    p {
      padding: 15px;
      margin: 0;

      br {
        display: none;
      }
    }
  }
}

.dateTabs {
  margin-bottom: 25px;

  button {
    align-items: flex-start;
  }

  .MuiTabs-flexContainer {
    flex-direction: column;
  }

  .MuiTabs-indicator {
    display: none;
  }

  .Mui-selected {
    font-weight: bold;
    background-color: $green;
    border-radius: 6px;
  }

  .MuiTab-wrapper {
    align-items: flex-start;
  }
}

.MuiChartsLegend-series {
  border-radius: 100%;
  overflow: hidden;
  rect {
    rx: 25px;
    ry: 25px;
  }
}

[role="tooltip"] {
  .MuiChartsTooltip-cell {
    padding-bottom: 5px !important;
    padding-top: 5px !important;
  }

  .MuiChartsTooltip-labelCell {
    min-width: 75px;
    span,
    p {
      font-size: 14px !important;
      padding-right: 15px;
    }
  }
  .MuiChartsTooltip-mark {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }

  .MuiChartsTooltip-markCell {
    padding: 10px;
    width: 20px;
  }

  .MuiChartsTooltip-row {
    border: 2px solid pink;
  }
  .MuiChartsTooltip-table {
    padding: 5px;
    border-radius: 4px;
  }

  .MuiChartsTooltip-valueCell {
    span,
    p {
      padding: 5px;
      background-color: $green;
      border-radius: 8px;
      font-size: 18px;
      font-weight: bold !important;
    }
  }
}
