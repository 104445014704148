.icon {
  display: inline-block;
  background-size: contain;

  &.clock {
    background-image: url("../img/clock.svg");
  }
}

.timeCapsule {
  background-color: black;
  color: white;
  font-weight: normal;
  display: flex;
  align-items: center;
  padding: 3px 10px;
  border-radius: 20px;
  font-size: 14px;
}

.button-subgroups {
  z-index: 2;
  margin-left: 5px;

  .button.small {
    position: relative;
    z-index: 3;
    padding: 5px 10px;
  }
}

.subgroups-popover {
  background-color: white;
  position: absolute;
  opacity: 0;
  width: 300px;
  transition: 0.4s;
  pointer-events: none;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
  z-index: 10;
  top: 45px;
  left: 0;

  &.hover {
    opacity: 1;
    top: 55px;
    pointer-events: none;
  }

  ul {
    margin: 0;
    padding: 0;
    overflow: hidden;
    border-radius: 12px;
    li {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #f0f0f0;
      padding: 10px 15px;
      background-color: white;
      &:last-child {
        border-bottom: none;
      }
      .icon {
        width: 25px;
        position: relative;
        top: -2px;
        border: 2px solid white;
        border-radius: 100%;
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
        height: 25px;
        background-image: url("../img/school.svg");
        margin-right: 5px;
      }
    }

    list-style: none;
  }
}

.maintenance.icon {
  animation: rotate 6s linear infinite;
}
