@import "./variables.scss";

.page.organization-single {
  margin-left: 300px;
  padding-top: 125px;

  .inner {
    transition: 0.4s;
    opacity: 1;

    &.saving {
      opacity: 0.4 !important;
    }
  }

  .icon.school-building-white {
    background-image: url("../img//school-building-white.svg");
  }

  .subnav {
    padding: 0px 15px;
  }

  .categoryList {
    .Mui-expanded {
      margin: 0px;
    }

    .MuiAccordion-root:before {
      display: none;
    }

    li {
      position: relative;
    }
  }

  .topCategoryCheckbox {
    position: absolute;
    z-index: 5;
    right: 20px;
    top: 20px;
  }

  #sidebar {
    padding: 0;
    margin-top: 50px;
    width: 300px;

    h2 {
      display: flex;
      align-items: center;
      height: auto;
      margin: 0;
      background-color: $dark-blue;
      color: white;
      line-height: 22px;
      padding: 25px 15px;
    }

    h3 {
      color: black;
      text-transform: none;
      margin: 0px;
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 600;
    }

    .sidebar-section {
      padding: 20px 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .address {
        span {
          font-size: 16px;
          display: block;
        }
      }

      &.renewal {
        padding-bottom: 10px;

        span {
          text-transform: uppercase;
          opacity: 0.6;
          font-size: 12px;
        }

        strong {
          display: block;
          margin-bottom: 10px;
        }
      }

      &.licenses {
        li {
          padding: 10px 0;

          span {
            flex: 1;
          }

          strong {
            border-radius: 4px;
            padding: 2px 10px;
            font-size: 18px;

            &.total {
              background-color: $green;
            }
          }
        }
      }

      .icon {
        width: 35px;
        height: 35px;
        margin-right: 8px;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
      }

      li {
        padding: 10px 5px;
        display: flex;
        align-items: center;
      }
    }
  }
}

.icon.partnership {
  background-image: url("../img/deal.svg");
}

.icon.mm-hidden {
  background-image: url("../img/hidden-eye.svg");
}

.icon.no-email {
  background-image: url("../img/spam-alert.svg");
}

.icon.hq-enabled {
  background-image: url("../img/settings.svg");
}

.icon.trial {
  background-image: url("../img/test.svg");
}

.page.organizations-list {
  max-width: 868px;
  padding-top: 190px;
  margin: 0 auto;

  .form-header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .header-inner {
      display: flex;
      align-items: center;
      width: 868px;
      padding: 100px 0 20px 0;
      background-color: $light-grey;
      flex-direction: column;
      justify-content: center;

      .inputSearch {
        width: auto !important;
      }

      .flexRow {
        width: 100%;

        h2 {
          flex: 1;
        }

        width: 100%;
      }
    }

    .labels {
      margin-top: 20px;
      display: flex;
      width: 100%;
      text-transform: uppercase;
      opacity: 0.7;
      font-weight: bold;
      font-size: 12px;

      span:first-child {
        flex: 1;
        padding-left: 15px;
      }

      span {
        padding-right: 15px;
      }
    }

    label {
      top: -13px;
    }

    .MuiInputBase-root {
      margin-top: 0px;
    }

    h2 {
      flex: 1;
      margin: 0;
    }
  }

  .title {
    flex: 1;
  }

  .status-filter {
    width: 120px;
    margin-left: 20px;

    .MuiFormControl-root {
      width: 120px;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li a {
      background-color: white;
      display: flex;
      padding: 25px 25px;
      margin-bottom: 1px;
      transition: 0.3s;
      color: black;
      align-items: center;

      .icon {
        height: 30px;
        width: 30px;
        margin-right: 15px;
      }

      &:hover {
        background-color: lighten($light-grey, 3%);
      }
    }
  }
}

.org-settings {
  .MuiInputLabel-shrink {
    background-color: white;
    padding: 0 5px;
  }
  .uploadImageWrapper {
    margin-top: 0;
    max-width: 300px;
    .fileUpload {
      width: 345px;
      height: 150px;
      .tooltip {
        width: 345px;
      }
    }
  }
}
